@font-face {
	font-family: 'thegem-icons';
	src:url('/static/wp-thegem/fonts/thegem-icons.eot');
	src:url('/static/wp-thegem/fonts/thegem-icons.eot?#iefix') format('embedded-opentype'),
		url('/static/wp-thegem/fonts/thegem-icons.woff') format('woff'),
		url('/static/wp-thegem/fonts/thegem-icons.ttf') format('truetype'),
		url('/static/wp-thegem/fonts/thegem-icons.svg#thegem-icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'thegem-socials';
	src:url('/static/wp-thegem/fonts/thegem-socials.eot');
	src:url('/static/wp-thegem/fonts/thegem-socials.eot?#iefix') format('embedded-opentype'),
		url('/static/wp-thegem/fonts/thegem-socials.ttf') format('truetype'),
		url('/static/wp-thegem/fonts/thegem-socials.woff') format('woff'),
		url('/static/wp-thegem/fonts/thegem-socials.svg#thegem-socials') format('svg');
	font-weight: normal;
	font-style: normal;
}


/* BASIC */
body {
	background-attachment: fixed;
}
/*
p {
	margin: 0 0 1.1em;
}
*/

a {
	-o-transition: color 0.3s, background-color 0.3s;
	-webkit-transition: color 0.3s, background-color 0.3s;
	transition: color 0.3s, background-color 0.3s;
}

::placeholder {
	opacity: 1;
}

.row {
	margin-bottom: 1.1em;
}
.row:last-of-type {
	margin-bottom: 0;
}
button {
	outline: none;
}
@media (max-width: 1199px) {
	.row {
		margin-top: -3em;
	}
	.row > * {
		margin-top: 3em;
	}
}

h1:first-child {
	margin-top: -10px;
}
h1,
.title-h1 {
	margin: 1.4em 0;
	text-transform: uppercase;
	letter-spacing: 0.05em;
}
h2,
.title-h2 {
	text-transform: uppercase;
	margin: 1.2em 0;
	letter-spacing: 0.05em;
}
h3,
.title-h3 {
	text-transform: uppercase;
	margin: 1.13em 0;
	letter-spacing: 0.05em;
}
h4,
.title-h4 {
	text-transform: uppercase;
	margin: 1em 0;
	letter-spacing: 0.05em;
}
h5,
.title-h5 {
	text-transform: uppercase;
	margin: 0.5em 0;
	letter-spacing: 0.05em;
}
h6,
.title-h6 {
	text-transform: uppercase;
	margin: 1.19em 0;
	letter-spacing: 0.05em;
}
.title-xlarge {
	margin: 1.4em 0;
	text-transform: uppercase;
	letter-spacing: 0.05em;
}

h1 .light,
h2 .light,
h3 .light,
h4 .light,
h5 .light,
h6 .light,
.title-h1 .light,
.title-h2 .light,
.title-h3 .light,
.title-h4 .light,
.title-h5 .light,
.title-h6 .light,
.title-xlarge .light {
	text-transform: uppercase;
	font-size: inherit;
	line-height: inherit;
}

.letter-spacing {
	letter-spacing: 0.05em;
}
.page-title-block .title-xlarge,
.page-title-block h1,
.page-title-block h2,
.page-title-block h3,
.page-title-block h4,
.page-title-block h5,
.page-title-block h6,
.page-title-block .title-h1,
.page-title-block .title-h2,
.page-title-block .title-h3,
.page-title-block .title-h4,
.page-title-block .title-h5,
.page-title-block .title-h6 {
	margin: 0;
}


.small-body {
	font-size: 85%;
	line-height: inherit;
	display: block;
	line-height: 23px;
}


.centered-box {
	text-align: center;
}

.gem-dummy {
	display: inline-block;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}

.grayscale,
.grayscale-image img {
	filter: url('https://cms.concrad.com/wp-content/themes/thegem/css/grayscale.svg#greyscale');
	-webkit-filter: grayscale(100%);
	-moz-filter: grayscale(100%);
	-ms-filter: grayscale(100%);
	-o-filter: grayscale(100%);
	filter: grayscale(100%);
}
.gscale img {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	-webkit-transition: 0.3s linear;
	transition: 0.3s linear;
}
.gscale:hover img {
	-webkit-filter: grayscale(0);
	filter: grayscale(0);
}
.grayscale-hover:hover,
.grayscale-image a:hover img,
.grayscale-image .hover img {
	-webkit-filter: none;
	-moz-filter: none;
	-ms-filter: none;
	-o-filter: none;
	filter: none;
}
.disable-grayscale .grayscale-hover,
.disable-grayscale .grayscale-image a img,
.disable-grayscale .grayscale-image  img {
	-webkit-filter: none;
	-moz-filter: none;
	-ms-filter: none;
	-o-filter: none;
	filter: none;
}
.inline-row {
	font-size: 0;
	margin-bottom: -42px;
}
.inline-row-center {
	text-align: center;
}
.inline-column {
	float: none;
	display: inline-block;
	text-align: left;
	vertical-align: top;
	margin-bottom: 42px;
}
.inline-inside {
	font-size: 0;
	line-height: 0;
}
.z-index {
	z-index: 1000;
	position: relative;
}
/* STYLED LISTS */

ul.styled,
ol.styled,
ul.product_list_widget {
	margin: 0;
	padding: 0;
	list-style: none;
}

/* CONTAINERS MARGIN */

.fullwidth-block-inner > :last-child,
.fullwidth-block-inner > .container > :last-child,
.centered-box > :last-child,
.gem-textbox-content > :last-child,
.gem-icon-with-text-text > :last-child,
.gem-icon-with-text-content > :last-child,
.gem-alert-box-content > :last-child,
.gem-map-with-text-content > :last-child,
.gem-map-with-text-content > .container > :last-child,
blockquote > :last-child,
.comment-text > :last-child,
.entry-content.post-content > :last-child {
	margin-bottom: 0;
}

.entry-content.post-content > .wpb_row:last-child,
.gem-textbox-content > .wpb_row:last-child {
	margin-bottom: 0;
}

.fullwidth-block-inner > :first-child,
.fullwidth-block-inner > .container > :first-child,
.centered-box > :first-child,
.gem-textbox-content > :first-child,
.gem-icon-with-text-text > :first-child,
.gem-icon-with-text-content > :first-child,
.gem-map-with-text-content > :first-child,
.gem-map-with-text-content > .container > :first-child,
.gem_tab > :first-child,
blockquote > :first-child,
.comment-text > :first-child,
.wpb_wrapper > :first-child {
	margin-top: 0;
}


/* NAVIGATION */

/* Prev & Next */
.gem-prev,
.gem-next,
.ls-thegem a.ls-nav-prev,
.ls-thegem a.ls-nav-next {
	position: absolute;
	width: 40px;
	top: 0;
	bottom: 0;
	font-size: 0;
	line-height: 0;
	text-indent: 100%;
	overflow: hidden;
	display: block;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.gem-prev:focus,
.gem-next:focus,
.ls-thegem a.ls-nav-prev:focus,
.ls-thegem a.ls-nav-next:focus {
	outline: 0 none;
}
.nivo-directionNav .gem-prev,
.nivo-directionNav .gem-next,
.ls-thegem a.ls-nav-prev,
.ls-thegem a.ls-nav-next,
.related-products-carousel.fullwidth-block .gem-prev,
.related-products-carousel.fullwidth-block .gem-next {
	width: 10%;
	z-index: 10;
}
.gem-prev,
.ls-thegem a.ls-nav-prev {
	left: 0;
}
.gem-next,
.ls-thegem a.ls-nav-next {
	right: 0;
}
.gem-prev:after,
.gem-next:after,
.ls-thegem a.ls-nav-prev:after,
.ls-thegem a.ls-nav-next:after {
	position: absolute;
	font-family: 'thegem-icons';
	top: 50%;
	margin-top: -20px;
	left:50%;
	margin-left: -20px;
	font-size: 24px;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	text-indent: 0;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.gem-prev:after,
.ls-thegem a.ls-nav-prev:after {
	content: '\E636';
}
.gem-next:after,
.ls-thegem a.ls-nav-next:after {
	content: '\E634';
}
.gem-prev.disabled,
.gem-next.disabled {
	visibility: hidden;
}

/* PAGINATION */

.gem-pagination {
	text-align: left;
	margin-top: 75px;
}
.gem-pagination.centered-box {
	text-align: center;
}
.gem-pagination-links {
	margin: 0 -8px;
}
.gem-pagination a,
.gem-pagination .current {
	position: relative;
	display: inline-block;
	margin: 0 8px;
	width: 30px;
	height: 30px;
	line-height: 26px;
	text-align: center;
	vertical-align: top;
	text-decoration: none;
	margin-bottom: 16px;
	font-size: 14px;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.gem-pagination .prev,
.gem-pagination .next {
	font-size: 0;
}
.gem-pagination .prev:before,
.gem-pagination .next:before {
	font-family: 'thegem-icons';
	font-weight: normal;
	font-size: 24px;
}
.gem-pagination .prev:before {
	content: '\E636';
}
.gem-pagination .next:before {
	content: '\E634';
}

/* Mini-pagination */

.gem-mini-pagination {
	text-align: center;
	padding-top: 15px;
}
.gem-mini-pagination a {
	display: inline-block;
	width: 17px;
	height: 17px;
	font-size: 0;
	line-height: 0;
	text-indent: 100%;
	overflow: hidden;
	margin: 0 7px;
	border-radius: 17px;
	-moz-border-radius: 17px;
	-webkit-border-radius: 17px;
}
.gem-mini-pagination a:focus {
	outline: 0 none;
}

.vc_pagination.vc_pagination-style-thegem.vc_pagination-shape-circle .vc_pagination-trigger {
	width: 16px;
	height: 16px;
	background-color: #b6c6c9;
	border-radius: 50%;
}
.vc_pagination.vc_pagination-style-thegem.vc_pagination-shape-circle .vc_pagination-trigger:hover {
	background-color: #b6c6c9;
}
.vc_pagination.vc_pagination-style-thegem.vc_pagination-shape-circle .vc_active .vc_pagination-trigger {
	background-color: #00bcd4;
}
.vc_pagination.vc_pagination-style-thegem.vc_pagination-shape-square .vc_pagination-trigger {
	width: 16px;
	height: 16px;
	background-color: #3c3950;
	border-radius: 3px;
}
.vc_pagination.vc_pagination-style-thegem.vc_pagination-shape-square .vc_pagination-trigger:hover {
	background-color: #3c3950;
}
.vc_pagination.vc_pagination-style-thegem.vc_pagination-shape-square .vc_active .vc_pagination-trigger {
	background-color: #00bcd4;
}


/* ICONS */

.gem-print-icon {
	display: inline-block;
	font-family: 'thegem-user-icons';
	font-feature-settings: normal;
	font-kerning: auto;
	font-language-override: normal;
	font-size: inherit;
	font-size-adjust: none;
	font-stretch: normal;
	font-style: normal;
	font-synthesis: weight style;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	text-rendering: auto;
	text-align: center;
	vertical-align: middle;
}
.gem-print-icon.gem-icon-pack-elegant {
	font-family: 'ElegantIcons';
	font-size: 16px;
}
.gem-print-icon.gem-icon-pack-material {
	font-family: 'MaterialDesignIcons';
	font-size: 24px;
}
.gem-print-icon.gem-icon-pack-fontawesome {
	font-family: 'FontAwesome';
	font-size: 14px;
}
.gem-print-icon.gem-icon-pack-userpack {
	font-family: 'UserPack';
	font-size: 16px;
}
.gem-print-icon.gem-icon-pack-thegem-icons::before {
	font-family: 'thegem-icons';
	font-size: 16px;
	vertical-align: top;
}
.gem-print-icon.gem-icon-pack-thegem-icons.gem-icon-share::before {
	content: '\E60D';
}
.gem-print-icon.gem-icon-pack-thegem-icons.gem-icon-prev::before {
	content: '\E636';
	font-size: 24px;
}
.gem-print-icon.gem-icon-pack-thegem-icons.gem-icon-next::before {
	content: '\E634';
	font-size: 24px;
}
.gem-print-icon.gem-icon-pack-thegem-icons.gem-icon-hamburger::before {
	content: '\E620';
}
.gem-icon {
	position: relative;
	font-weight: normal;
	font-family: 'ElegantIcons';
	font-size: 24px;
	border-radius: 50%;
	display: inline-block;
	vertical-align: middle;
	border-style: solid;
	border-width: 3px;
}
.gem-icon.gem-icon-pack-material {
	font-family: 'MaterialDesignIcons';
}
.gem-icon.gem-icon-pack-fontawesome {
	font-family: 'FontAwesome';
}
.gem-icon.gem-icon-pack-userpack {
	font-family: 'UserPack';
}
.gem-icon-centered {
	display: block;
	margin: 0 auto;
}
.gem-icon-inner {
	position: relative;
	height: 100%;
	border-radius: 50%;
	width: 46px;
	height: 46px;
	line-height: 46px;
	margin: -1px;
}
.gem-icon-size-small {
	font-size: 24px;
	border-style: solid;
	border-width: 3px;
	height: 50px;
}
.gem-icon-size-small .gem-icon-inner {
	width: 46px;
	height: 46px;
	line-height: 46px;
}
.gem-icon.gem-simple-icon {
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	width: 24px;
	height: 24px;
	line-height: 24px;
	border: 0 none;
}
.gem-icon.gem-simple-icon .gem-icon-inner {
	width: 100%;
	height: 100%;
	line-height: inherit;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
}
.gem-icon-shape-square,
.gem-simple-icon {
	border-radius: 3px;
}
.gem-icon-shape-square .gem-icon-inner {
	border-radius: 0;
}
.gem-icon-size-medium {
	font-size: 48px;
	border-width: 4px;
	height: 80px;
}
.gem-icon-size-medium .gem-icon-inner {
	width: 74px;
	height: 74px;
	line-height: 74px;
}
.gem-icon-size-medium.gem-simple-icon {
	width: 48px;
	height: 48px;
	line-height: 48px;
}
.gem-icon-size-medium.gem-icon-shape-square,
.gem-icon-size-medium.gem-simple-icon {
	border-radius: 3px;
}
.gem-icon-size-large {
	font-size: 96px;
	border-width: 7px;
	height: 160px;
}
.gem-icon-size-large .gem-icon-inner {
	width: 148px;
	height: 148px;
	line-height: 148px;
}
.gem-icon-size-large.gem-simple-icon {
	width: 96px;
	height: 96px;
	line-height: 96px;
}
.gem-icon-size-large.gem-icon-shape-square,
.gem-icon-size-large.gem-simple-icon {
	border-radius: 6px;
}
.gem-icon-size-xlarge {
	font-size: 144px;
	border-width: 13px;
	height: 240px;
}
.gem-icon-size-xlarge .gem-icon-inner {
	width: 216px;
	height: 216px;
	line-height: 216px;
}
.gem-icon-size-xlarge.gem-simple-icon {
	width: 144px;
	height: 144px;
	line-height: 144px;
}
.gem-icon-size-xlarge.gem-icon-shape-square,
.gem-icon-size-xlarge.gem-simple-icon {
	border-radius:9px;
}
.gem-icon .gem-icon-half-1,
.gem-icon .gem-icon-half-2 {
	float: left;
	width: 100%;
	height: 100%;
	overflow: hidden;
	text-align: center;
}
.gem-icon .gem-icon-half-1 {
	margin-left: -50%;
}
.gem-icon .gem-icon-half-2 {
	margin-right: -50%;
}
.gem-icon.angle-45deg-l .gem-icon-half-1,
.gem-icon.angle-45deg-l .gem-icon-half-2 {
	transform: skew(45deg, 0);
	-o-transform: skew(45deg, 0);
	-ms-transform: skew(45deg, 0);
	-moz-transform: skew(45deg, 0);
	-webkit-transform: skew(45deg, 0);
}
.gem-icon.angle-45deg-r .gem-icon-half-1,
.gem-icon.angle-45deg-r .gem-icon-half-2 {
	transform: skew(-45deg, 0);
	-o-transform: skew(-45deg, 0);
	-ms-transform: skew(-45deg, 0);
	-moz-transform: skew(-45deg, 0);
	-webkit-transform: skew(-45deg, 0);
}
.gem-icon .back-angle {
	display: block;
	position: relative;
	width: 200%;
	height: 100%;
}
.gem-icon .gem-icon-half-2 .back-angle {
	left: -100%;
}
.gem-icon.angle-45deg-l .back-angle {
	transform: skew(-45deg, 0);
	-o-transform: skew(-45deg, 0);
	-ms-transform: skew(-45deg, 0);
	-moz-transform: skew(-45deg, 0);
	-webkit-transform: skew(-45deg, 0);
}
.gem-icon.angle-45deg-r .back-angle {
	transform: skew(45deg, 0);
	-o-transform: skew(45deg, 0);
	-ms-transform: skew(45deg, 0);
	-moz-transform: skew(45deg, 0);
	-webkit-transform: skew(45deg, 0);
}

/* BUTTON */

.gem-button,
input[type='submit'] {
	position: relative;
	display: inline-block;
	text-align: center;
	text-transform: uppercase;
	white-space: nowrap;
	border: 0 none;
	vertical-align: middle;
	height: 40px;
	line-height: 40px;
	padding: 0 17px;
	font-size: 14px;
	margin: 25px;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	border-radius: 3px;
}
.gem-button.gem-button-no-uppercase {
	text-transform: none;
}
.gem-button:hover {
	text-decoration: none;
}
.gem-button-position-inline {
	display: inline-block;
	vertical-align: top;
}
.gem-button-position-left {
	float: left;
}
.gem-button-position-right {
	float: right;
}
.gem-button-position-center {
	text-align: center;
	display: block;
}
.gem-button-position-fullwidth .gem-button {
	width: 100%;
	padding-left: 5px;
	padding-right: 5px;
	text-align: center;
}
.gem-button-size-tiny,
input.gem-button-size-tiny {
	height: 30px;
	line-height: 30px;
	padding: 0 14px;
	font-size: 14px;
	margin: 20px;
}
.gem-button-size-small,
input.gem-button-size-small {
	height: 40px;
	line-height: 40px;
	padding: 0 17px;
	font-size: 14px;
	margin: 25px;
}
.gem-button-size-medium,
input.gem-button-size-medium {
	height: 50px;
	line-height: 50px;
	padding: 0 25px;
	font-size: 19px;
	margin: 30px;
}
.gem-button-size-large,
input.gem-button-size-large {
	height: 80px;
	line-height: 80px;
	padding: 0 37px;
	font-size: 24px;
	margin: 40px;
}
.gem-button-size-giant,
input.gem-button-size-giant {
	height: 100px;
	line-height: 100px;
	padding: 0 53px;
	font-size: 36px;
	margin: 50px;
}
.gem-button.gem-button-empty {
	padding-left: 0;
	padding-right: 0;
	width: 40px;
}
.gem-button.gem-button-empty.gem-button-size-tiny {
	width: 30px;
}
.gem-button.gem-button-empty.gem-button-size-small {
	width: 40px;
}
.gem-button.gem-button-empty.gem-button-size-medium {
	width: 50px;
}
.gem-button.gem-button-empty.gem-button-size-large {
	width: 80px;
}
.gem-button.gem-button-empty.gem-button-size-giant {
	width: 100px;
}
.gem-button.gem-button-style-outline {
	border-width: 2px;
	line-height: 36px;
	border-style: solid;
	background: transparent;
}
.gem-button.gem-button-style-outline {
	background: transparent;
}
.gem-button-style-outline.gem-button-border-1 {
	border-width: 1px;
	line-height: 38px;
}
.gem-button-style-outline.gem-button-border-3 {
	border-width: 3px;
	line-height: 34px;
}
.gem-button-style-outline.gem-button-border-4 {
	border-width: 4px;
	line-height: 32px;
}
.gem-button-style-outline.gem-button-border-5 {
	border-width: 5px;
	line-height: 30px;
}
.gem-button-style-outline.gem-button-border-6 {
	border-width: 6px;
	line-height: 28px;
}
.gem-button-size-tiny.gem-button-border-1 {
	line-height: 28px;
}
.gem-button-size-tiny.gem-button-style-outline {
	line-height: 26px;
}
.gem-button-size-tiny.gem-button-border-3 {
	line-height: 24px;
}
.gem-button-size-tiny.gem-button-border-4 {
	line-height: 22px;
}
.gem-button-size-tiny.gem-button-border-5 {
	line-height: 20px;
}
.gem-button-size-tiny.gem-button-border-6 {
	line-height: 18px;
}
.gem-button-size-medium.gem-button-border-1 {
	line-height: 48px;
}
.gem-button-size-medium.gem-button-style-outline {
	line-height: 46px;
}
.gem-button-size-medium.gem-button-border-3 {
	line-height: 44px;
}
.gem-button-size-medium.gem-button-border-4 {
	line-height: 42px;
}
.gem-button-size-medium.gem-button-border-5 {
	line-height: 40px;
}
.gem-button-size-medium.gem-button-border-6 {
	line-height: 38px;
}
.gem-button-size-large.gem-button-border-1 {
	line-height: 78px;
}
.gem-button-size-large.gem-button-style-outline {
	line-height: 76px;
}
.gem-button-size-large.gem-button-border-3 {
	line-height: 74px;
}
.gem-button-size-large.gem-button-border-4 {
	line-height: 72px;
}
.gem-button-size-large.gem-button-border-5 {
	line-height: 70px;
}
.gem-button-size-large.gem-button-border-6 {
	line-height: 68px;
}
.gem-button-size-giant.gem-button-border-1 {
	line-height: 98px;
}
.gem-button-size-giant.gem-button-style-outline {
	line-height: 96px;
}
.gem-button-size-giant.gem-button-border-3 {
	line-height: 94px;
}
.gem-button-size-giant.gem-button-border-4 {
	line-height: 92px;
}
.gem-button-size-giant.gem-button-border-5 {
	line-height: 90px;
}
.gem-button-size-giant.gem-button-border-6 {
	line-height: 88px;
}
.gem-button-position-inline .gem-button {
	margin-left: 0;
}
.gem-button-position-left .gem-button {
	margin-left: 0;
	margin-top: 0;
}
.gem-button-position-right .gem-button {
	margin-right: 0;
	margin-top: 0;
}
.gem-button-position-center .gem-button,
.gem-button-position-fullwidth .gem-button {
	margin-left: 0;
	margin-right: 0;
}
.gem-button-size-large .gem-print-icon.gem-icon-pack-elegant,
.gem-button-size-giant .gem-print-icon.gem-icon-pack-elegant {
	font-size: 32px;
}
.gem-button-size-large .gem-print-icon.gem-icon-pack-material,
.gem-button-size-giant .gem-print-icon.gem-icon-pack-material {
	font-size: 48px;
}
.gem-button-size-large .gem-print-icon.gem-icon-pack-fontawesome,
.gem-button-size-giant .gem-print-icon.gem-icon-pack-fontawesome {
	font-size: 28px;
}
.gem-button-size-large .gem-print-icon.gem-icon-pack-userpack,
.gem-button-size-giant .gem-print-icon.gem-icon-pack-userpack {
	font-size: 32px;
}
.gem-button i.gem-print-icon {
	line-height: inherit;
	vertical-align: top;
	height: 100%;
	line-height: inherit;
}
.gem-button-icon-position-left .gem-print-icon {
	margin-right: 6px;
}
.gem-button-icon-position-left.gem-button-size-tiny .gem-print-icon {
	margin-right: 6px;
}
.gem-button-icon-position-left.gem-button-size-medium .gem-print-icon {
	margin-right: 9px;
}
.gem-button-icon-position-left.gem-button-size-large .gem-print-icon {
	margin-right: 12px;
}
.gem-button-icon-position-left.gem-button-size-giant .gem-print-icon {
	margin-right: 16px;
}
.gem-button-icon-position-right .gem-print-icon {
	margin-left: 6px;
}
.gem-button-icon-position-right.gem-button-size-tiny .gem-print-icon {
	margin-left: 6px;
}
.gem-button-icon-position-right.gem-button-size-medium .gem-print-icon {
	margin-left: 9px;
}
.gem-button-icon-position-right.gem-button-size-large .gem-print-icon {
	margin-left: 12px;
}
.gem-button-icon-position-right.gem-button-size-giant .gem-print-icon {
	margin-left: 16px;
}
.blog-load-more {
	position: relative;
}
.blog-load-more .gem-button {
	margin: 0;
}
.portfolio-load-more .gem-button,
.gem-product-load-more .gem-button {
	margin-right: 0;
}
@media (max-width: 767px) {
	.gem-button-size-large {
		height: 50px;
		line-height: 50px;
		padding: 0 25px;
		font-size: 19px;
		margin: 30px;
	}
	.gem-button.gem-button-empty.gem-button-size-large {
		width: 50px;
	}
	.gem-button-size-large.gem-button-border-1 {
		line-height: 48px;
	}
	.gem-button-size-large.gem-button-style-outline {
		line-height: 46px;
	}
	.gem-button-size-large.gem-button-border-3 {
		line-height: 44px;
	}
	.gem-button-size-large.gem-button-border-4 {
		line-height: 42px;
	}
	.gem-button-size-large.gem-button-border-5 {
		line-height: 40px;
	}
	.gem-button-size-large.gem-button-border-6 {
		line-height: 38px;
	}
	.gem-button-icon-position-left.gem-button-size-large .gem-print-icon {
		margin-right: 9px;
	}
	.gem-button-icon-position-right.gem-button-size-large .gem-print-icon {
		margin-left: 9px;
	}

	.gem-button-size-giant {
		height: 80px;
		line-height: 80px;
		padding: 0 37px;
		font-size: 24px;
		margin: 40px;
	}
	.gem-button.gem-button-empty.gem-button-size-giant {
		width: 80px;
	}
	.gem-button-size-giant.gem-button-border-1 {
		line-height: 78px;
	}
	.gem-button-size-giant.gem-button-style-outline {
		line-height: 76px;
	}
	.gem-button-size-giant.gem-button-border-3 {
		line-height: 74px;
	}
	.gem-button-size-giant.gem-button-border-4 {
		line-height: 72px;
	}
	.gem-button-size-giant.gem-button-border-5 {
		line-height: 70px;
	}
	.gem-button-size-giant.gem-button-border-6 {
		line-height: 68px;
	}
	.gem-button-icon-position-left.gem-button-size-giant .gem-print-icon {
		margin-right: 12px;
	}
	.gem-button-icon-position-right.gem-button-size-giant .gem-print-icon {
		margin-left: 12px;
	}
}

/* SOCIALS */

a.socials-item {
	display: inline-block;
	vertical-align: top;
	text-align: center;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	margin: 0 5px;
	line-height: 16px;
}
.socials-item-icon {
	display: inline-block;
	font-family: 'thegem-socials';
	font-weight: normal;
	width: 1em;
	height: 1em;
	line-height: 1;
	font-size: 16px;
	text-align: center;
	vertical-align: top;
	font-feature-settings: normal;
	font-kerning: auto;
	font-language-override: normal;
	font-size-adjust: none;
	font-stretch: normal;
	font-style: normal;
	font-synthesis: weight style;
	font-variant: normal;
	font-weight: normal;
	text-rendering: auto;
}
a .socials-item-icon {
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.socials-item-icon.facebook::before {
	content: '\E600';
}
.socials-colored a .socials-item-icon.facebook,
.socials-colored-hover a:hover .socials-item-icon.facebook {
	color: #4e64b5;
}
.socials-item-icon.twitter::before {
	content: '\E601';
}
.socials-colored a .socials-item-icon.twitter,
.socials-colored-hover a:hover .socials-item-icon.twitter {
	color: #00aced;
}
.socials-item-icon.pinterest::before {
	content: '\E602';
}
.socials-colored a .socials-item-icon.pinterest,
.socials-colored-hover a:hover .socials-item-icon.pinterest {
	color: #ff5757;
}
.socials-item-icon.google-plus::before,
.socials-item-icon.googleplus::before {
	content: '\E603';
}
.socials-colored a .socials-item-icon.google-plus,
.socials-colored a .socials-item-icon.googleplus,
.socials-colored-hover a:hover .socials-item-icon.google-plus,
.socials-colored-hover a:hover .socials-item-icon.googleplus {
	color: #f44336;
}
.socials-item-icon.tumblr::before {
	content: '\E604';
}
.socials-colored a .socials-item-icon.tumblr,
.socials-colored-hover a:hover .socials-item-icon.tumblr {
	color: #456988;
}
.socials-item-icon.stumbleupon::before {
	content: '\E605';
}
.socials-colored a .socials-item-icon.stumbleupon,
.socials-colored-hover a:hover .socials-item-icon.stumbleupon {
	color: #46485c;
}
.socials-item-icon.wordpress::before {
	content: '\E606';
}
.socials-colored a .socials-item-icon.wordpress,
.socials-colored-hover a:hover .socials-item-icon.wordpress {
	color: #0087be;
}
.socials-item-icon.instagram::before {
	content: '\E607';
}
.socials-colored a .socials-item-icon.instagram,
.socials-colored-hover a:hover .socials-item-icon.instagram {
	color: #9a8f62;
}
.socials-item-icon.dribbble::before {
	content: '\E608';
}
.socials-colored a .socials-item-icon.dribbble,
.socials-colored-hover a:hover .socials-item-icon.dribbble {
	color: #ec4a89;
}
.socials-item-icon.vimeo::before {
	content: '\E609';
}
.socials-colored a .socials-item-icon.vimeo,
.socials-colored-hover a:hover .socials-item-icon.vimeo {
	color: #1db3e3;
}
.socials-item-icon.linkedin::before {
	content: '\E60A';
}
.socials-colored a .socials-item-icon.linkedin,
.socials-colored-hover a:hover .socials-item-icon.linkedin {
	color: #0288d1;
}
.socials-item-icon.rss::before {
	content: '\E60B';
}
.socials-colored a .socials-item-icon.rss,
.socials-colored-hover a:hover .socials-item-icon.rss {
	color: #ef8321;
}
.socials-item-icon.deviantart::before {
	content: '\E60C';
}
.socials-colored a .socials-item-icon.deviantart,
.socials-colored-hover a:hover .socials-item-icon.deviantart {
	color: #00d13b;
}
.socials-item-icon.share::before {
	content: '\E60D';
}
.socials-colored a .socials-item-icon.share,
.socials-colored-hover a:hover .socials-item-icon.share {
	color: #ff694c;
}
.socials-item-icon.myspace::before {
	content: '\E60E';
}
.socials-colored a .socials-item-icon.myspace,
.socials-colored-hover a:hover .socials-item-icon.myspace {
	color: #003399;
}
.socials-item-icon.skype::before {
	content: '\E60F';
}
.socials-colored a .socials-item-icon.skype,
.socials-colored-hover a:hover .socials-item-icon.skype {
	color: #00aff0;
}
.socials-item-icon.youtube::before {
	content: '\E610';
}
.socials-colored a .socials-item-icon.youtube,
.socials-colored-hover a:hover .socials-item-icon.youtube {
	color: #c82929;
}
.socials-item-icon.picassa::before {
	content: '\E611';
}
.socials-colored a .socials-item-icon.picassa,
.socials-colored-hover a:hover .socials-item-icon.picassa {
	color: #9966cc;
}
.socials-item-icon.google-drive::before,
.socials-item-icon.googledrive::before {
	content: '\E612';
}
.socials-colored a .socials-item-icon.google-drive,
.socials-colored-hover a:hover .socials-item-icon.google-drive,
.socials-colored a .socials-item-icon.googledrive,
.socials-colored-hover a:hover .socials-item-icon.googledrive {
	color: #2db672;
}
.socials-item-icon.flickr::before {
	content: '\E613';
}
.socials-colored a .socials-item-icon.flickr,
.socials-colored-hover a:hover .socials-item-icon.flickr {
	color: #ff0084;
}
.socials-item-icon.blogger::before {
	content: '\E614';
}
.socials-colored a .socials-item-icon.blogger,
.socials-colored-hover a:hover .socials-item-icon.blogger {
	color: #f06a35;
}
.socials-item-icon.spotify::before {
	content: '\E615';
}
.socials-colored a .socials-item-icon.spotify,
.socials-colored-hover a:hover .socials-item-icon.spotify {
	color: #1ed760;
}
.socials-item-icon.delicious::before {
	content: '\E616';
}
.socials-colored a .socials-item-icon.delicious,
.socials-colored-hover a:hover .socials-item-icon.delicious {
	color: #229bf7;
}
.socials-item-icon.telegram::before {
	content: '\E645';
}
.socials-colored a .socials-item-icon.telegram,
.socials-colored-hover a:hover .socials-item-icon.telegram {
	color: #2ca5e0;
}
.socials-item-icon.vk::before {
	content: '\E648';
}
.socials-colored a .socials-item-icon.vk,
.socials-colored-hover a:hover .socials-item-icon.vk {
	color: #5181b8;
}
.socials-item-icon.whatsapp::before {
	content: '\E64B';
}
.socials-colored a .socials-item-icon.whatsapp,
.socials-colored-hover a:hover .socials-item-icon.whatsapp {
	color: #30b220;
}
.socials-item-icon.viber::before {
	content: '\E64E';
}
.socials-colored a .socials-item-icon.viber,
.socials-colored-hover a:hover .socials-item-icon.viber {
	color: #7a4e9d;
}
.socials-item-icon.ok::before {
	content: '\E651';
}
.socials-colored a .socials-item-icon.ok,
.socials-colored-hover a:hover .socials-item-icon.ok {
	color: #df8733;
}
.socials-item-icon.reddit::before {
	content: '\E654';
}
.socials-colored a .socials-item-icon.reddit,
.socials-colored-hover a:hover .socials-item-icon.reddit {
	color: #ff4500;
}
.socials-item-icon.slack::before {
	content: '\E657';
}
.socials-colored a .socials-item-icon.slack,
.socials-colored-hover a:hover .socials-item-icon.slack {
	color: #63c1a0;
}
.socials-item-icon.askfm::before {
	content: '\E65A';
}
.socials-colored a .socials-item-icon.askfm,
.socials-colored-hover a:hover .socials-item-icon.askfm {
	color: #ff6648;
}
.socials-item-icon.meetup::before {
	content: '\E65D';
}
.socials-colored a .socials-item-icon.meetup,
.socials-colored-hover a:hover .socials-item-icon.meetup {
	color: #e25063;
}
.socials-item-icon.weibo::before {
	content: '\E660';
}
.socials-colored a .socials-item-icon.weibo,
.socials-colored-hover a:hover .socials-item-icon.weibo {
	color: #d52b2a;
}
.socials-item-icon.qzone::before {
	content: '\E663';
}
.socials-colored a .socials-item-icon.qzone,
.socials-colored-hover a:hover .socials-item-icon.qzone {
	color: #fece00;
}

.social-item-rounded.socials-item-icon.facebook::before,
.socials-rounded .socials-item-icon.facebook::before {
	content: '\E617';
}
.social-item-rounded.socials-item-icon.twitter::before,
.socials-rounded .socials-item-icon.twitter::before {
	content: '\E618';
}
.social-item-rounded.socials-item-icon.pinterest::before,
.socials-rounded .socials-item-icon.pinterest::before {
	content: '\E619';
}
.social-item-rounded.socials-item-icon.google-plus::before,
.social-item-rounded.socials-item-icon.googleplus::before,
.socials-rounded .socials-item-icon.google-plus::before,
.socials-rounded .socials-item-icon.googleplus::before {
	content: '\E61A';
}
.social-item-rounded.socials-item-icon.tumblr::before,
.socials-rounded .socials-item-icon.tumblr::before {
	content: '\E61B';
}
.social-item-rounded.socials-item-icon.stumbleupon::before,
.socials-rounded .socials-item-icon.stumbleupon::before {
	content: '\E61C';
}
.social-item-rounded.socials-item-icon.wordpress::before,
.socials-rounded .socials-item-icon.wordpress::before {
	content: '\E61D';
}
.social-item-rounded.socials-item-icon.instagram::before,
.socials-rounded .socials-item-icon.instagram::before {
	content: '\E61E';
}
.social-item-rounded.socials-item-icon.dribbble::before,
.socials-rounded .socials-item-icon.dribbble::before {
	content: '\E61F';
}
.social-item-rounded.socials-item-icon.vimeo::before,
.socials-rounded .socials-item-icon.vimeo::before {
	content: '\E620';
}
.social-item-rounded.socials-item-icon.linkedin::before,
.socials-rounded .socials-item-icon.linkedin::before {
	content: '\E621';
}
.social-item-rounded.socials-item-icon.rss::before,
.socials-rounded .socials-item-icon.rss::before {
	content: '\E622';
}
.social-item-rounded.socials-item-icon.deviantart::before,
.socials-rounded .socials-item-icon.deviantart::before {
	content: '\E623';
}
.social-item-rounded.socials-item-icon.share::before,
.socials-rounded .socials-item-icon.share::before {
	content: '\E624';
}
.social-item-rounded.socials-item-icon.myspace::before,
.socials-rounded .socials-item-icon.myspace::before {
	content: '\E625';
}
.social-item-rounded.socials-item-icon.skype::before,
.socials-rounded .socials-item-icon.skype::before {
	content: '\E626';
}
.social-item-rounded.socials-item-icon.youtube::before,
.socials-rounded .socials-item-icon.youtube::before {
	content: '\E627';
}
.social-item-rounded.socials-item-icon.picassa::before,
.socials-rounded .socials-item-icon.picassa::before {
	content: '\E628';
}
.social-item-rounded.socials-item-icon.google-drive::before,
.social-item-rounded.socials-item-icon.googledrive::before,
.socials-rounded .socials-item-icon.google-drive::before,
.socials-rounded .socials-item-icon.googledrive::before {
	content: '\E629';
}
.social-item-rounded.socials-item-icon.flickr::before,
.socials-rounded .socials-item-icon.flickr::before {
	content: '\E62A';
}
.social-item-rounded.socials-item-icon.blogger::before,
.socials-rounded .socials-item-icon.blogger::before {
	content: '\E62B';
}
.social-item-rounded.socials-item-icon.spotify::before,
.socials-rounded .socials-item-icon.spotify::before {
	content: '\E62C';
}
.social-item-rounded.socials-item-icon.delicious::before,
.socials-rounded .socials-item-icon.delicious::before {
	content: '\E62D';
}
.social-item-rounded.socials-item-icon.telegram::before,
.socials-rounded .socials-item-icon.telegram::before {
	content: '\E646';
}
.social-item-rounded.socials-item-icon.vk::before,
.socials-rounded .socials-item-icon.vk::before {
	content: '\E649';
}
.social-item-rounded.socials-item-icon.whatsapp::before,
.socials-rounded .socials-item-icon.whatsapp::before {
	content: '\E64C';
}
.social-item-rounded.socials-item-icon.viber::before,
.socials-rounded .socials-item-icon.viber::before {
	content: '\E64F';
}
.social-item-rounded.socials-item-icon.ok::before,
.socials-rounded .socials-item-icon.ok::before {
	content: '\E652';
}
.social-item-rounded.socials-item-icon.reddit::before,
.socials-rounded .socials-item-icon.reddit::before {
	content: '\E655';
}
.social-item-rounded.socials-item-icon.slack::before,
.socials-rounded .socials-item-icon.slack::before {
	content: '\E658';
}
.social-item-rounded.socials-item-icon.askfm::before,
.socials-rounded .socials-item-icon.askfm::before {
	content: '\E65B';
}
.social-item-rounded.socials-item-icon.meetup::before,
.socials-rounded .socials-item-icon.meetup::before {
	content: '\E65E';
}
.social-item-rounded.socials-item-icon.weibo::before,
.socials-rounded .socials-item-icon.weibo::before {
	content: '\E661';
}
.social-item-rounded.socials-item-icon.qzone::before,
.socials-rounded .socials-item-icon.qzone::before {
	content: '\E664';
}

.social-item-square.socials-item-icon.facebook::before,
.socials-square .socials-item-icon.facebook::before {
	content: '\E62E';
}
.social-item-square.socials-item-icon.twitter::before,
.socials-square .socials-item-icon.twitter::before {
	content: '\E62F';
}
.social-item-square.socials-item-icon.pinterest::before,
.socials-square .socials-item-icon.pinterest::before {
	content: '\E630';
}
.social-item-square.socials-item-icon.google-plus::before,
.social-item-square.socials-item-icon.googleplus::before,
.socials-square .socials-item-icon.google-plus::before,
.socials-square .socials-item-icon.googleplus::before {
	content: '\E631';
}
.social-item-square.socials-item-icon.tumblr::before,
.socials-square .socials-item-icon.tumblr::before {
	content: '\E632';
}
.social-item-square.socials-item-icon.stumbleupon::before,
.socials-square .socials-item-icon.stumbleupon::before {
	content: '\E633';
}
.social-item-square.socials-item-icon.wordpress::before,
.socials-square .socials-item-icon.wordpress::before {
	content: '\E634';
}
.social-item-square.socials-item-icon.instagram::before,
.socials-square .socials-item-icon.instagram::before {
	content: '\E635';
}
.social-item-square.socials-item-icon.dribbble::before,
.socials-square .socials-item-icon.dribbble::before {
	content: '\E636';
}
.social-item-square.socials-item-icon.vimeo::before,
.socials-square .socials-item-icon.vimeo::before {
	content: '\E637';
}
.social-item-square.socials-item-icon.linkedin::before,
.socials-square .socials-item-icon.linkedin::before {
	content: '\E638';
}
.social-item-square.socials-item-icon.rss::before,
.socials-square .socials-item-icon.rss::before {
	content: '\E639';
}
.social-item-square.socials-item-icon.deviantart::before,
.socials-square .socials-item-icon.deviantart::before {
	content: '\E63A';
}
.social-item-square.socials-item-icon.share::before,
.socials-square .socials-item-icon.share::before {
	content: '\E63B';
}
.social-item-square.socials-item-icon.myspace::before,
.socials-square .socials-item-icon.myspace::before {
	content: '\E63C';
}
.social-item-square.socials-item-icon.skype::before,
.socials-square .socials-item-icon.skype::before {
	content: '\E63D';
}
.social-item-square.socials-item-icon.youtube::before,
.socials-square .socials-item-icon.youtube::before {
	content: '\E63E';
}
.social-item-square.socials-item-icon.picassa::before,
.socials-square .socials-item-icon.picassa::before {
	content: '\E63F';
}
.social-item-square.socials-item-icon.google-drive::before,
.social-item-square.socials-item-icon.googledrive::before,
.socials-square .socials-item-icon.google-drive::before,
.socials-square .socials-item-icon.googledrive::before {
	content: '\E640';
}
.social-item-square.socials-item-icon.flickr::before,
.socials-square .socials-item-icon.flickr::before {
	content: '\E641';
}
.social-item-square.socials-item-icon.blogger::before,
.socials-square .socials-item-icon.blogger::before {
	content: '\E642';
}
.social-item-square.socials-item-icon.spotify::before,
.socials-square .socials-item-icon.spotify::before {
	content: '\E643';
}
.social-item-square.socials-item-icon.delicious::before,
.socials-square .socials-item-icon.delicious::before {
	content: '\E644';
}
.social-item-square.socials-item-icon.telegram::before,
.socials-square .socials-item-icon.telegram::before {
	content: '\E647';
}
.social-item-square.socials-item-icon.vk::before,
.socials-square .socials-item-icon.vk::before {
	content: '\E64A';
}
.social-item-square.socials-item-icon.whatsapp::before,
.socials-square .socials-item-icon.whatsapp::before {
	content: '\E64D';
}
.social-item-square.socials-item-icon.viber::before,
.socials-square .socials-item-icon.viber::before {
	content: '\E650';
}
.social-item-square.socials-item-icon.ok::before,
.socials-square .socials-item-icon.ok::before {
	content: '\E653';
}
.social-item-square.socials-item-icon.reddit::before,
.socials-square .socials-item-icon.reddit::before {
	content: '\E656';
}
.social-item-square.socials-item-icon.slack::before,
.socials-square .socials-item-icon.slack::before {
	content: '\E659';
}
.social-item-square.socials-item-icon.askfm::before,
.socials-square .socials-item-icon.askfm::before {
	content: '\E65C';
}
.social-item-square.socials-item-icon.meetup::before,
.socials-square .socials-item-icon.meetup::before {
	content: '\E65F';
}
.social-item-square.socials-item-icon.weibo::before,
.socials-square .socials-item-icon.weibo::before {
	content: '\E662';
}
.social-item-square.socials-item-icon.qzone::before,
.socials-square .socials-item-icon.qzone::before {
	content: '\E665';
}

.socials-list {
	margin-left: -10px;
	margin-right: -10px;
}
.socials-list .socials-item {
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
}
.socials-list.socials-alignment-right {
	text-align: right;
}
.socials-list.socials-alignment-center {
	text-align: center;
}

#footer-socials .socials:not(.socials-colored-hover) a:hover .socials-item-icon {
	color: #ffffff;
}



/* PRELOADER */

.preloader {
	position: relative;
	height: 200px;
}
.slideshow-preloader {
	height: 400px;
}
.loading {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2;
}
.preloader:after,
.loading:after {
	content: '';
	position: absolute;
	height: 90px;
	width: 90px;
	background: #ffffff url('https://cms.concrad.com/wp-content/themes/thegem/images/preloader-1.gif') no-repeat 50% 50%;
	top: 50%;
	left: 50%;
	margin-top: -45px;
	margin-left: -45px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}
.preloader + * {
	padding: 0 !important;
	margin: 0 !important;
	overflow: hidden !important;
	opacity: 0;
}
.preloader + *:not(iframe) {
	height: 0 !important;
}
.preloader + iframe {
	margin-top: -200px !important;
}
.loading + * {
	opacity: 0;
}


/* FORM ELEMENTS */

input[type="text"],
input[type="password"],
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="number"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="month"],
input[type="week"] {
	padding: 0 10px;
	max-width: 100%;
	vertical-align: middle;
}
textarea {
	padding: 10px;
	max-width: 100%;
	vertical-align: middle;
}
/* Combobox */
.dropdown_product_cat .select2-container {
	border-radius: 3px;
}
.combobox-wrapper,
#page .select2-container .select2-selection--single {
	position: relative;
	width: 100%;
	height: 38px;
	display: inline-block;
	vertical-align: middle;
	min-width: 140px;
	padding-right: 36px;
	border-radius: 3px;
}
#page .select2-container .select2-selection--single {
	display: block;
	width: auto;
	padding-right: 0;
	outline: none;
	box-shadow: none;
}
#page .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple,
#page .select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
#page .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple,
#page .select2-container--default.select2-container--open.select2-container--above .select2-selection--single {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.combobox-wrapper .combobox-text,
#page .select2-container--default .select2-selection--single .select2-selection__rendered {
	display: block;
	padding: 0 10px;
	line-height: 36px;
	overflow: hidden;
	text-align: left;
	white-space: nowrap;
}
#page .select2-container--default .select2-selection--single .select2-selection__rendered {
	padding-right: 36px;
	outline: none;
	box-shadow: none;
}
.woocommerce .combobox-wrapper .combobox-text,
#page .woocommerce .select2-container--default .select2-selection--single .select2-selection__rendered {
	padding-left: 18px;
}
.combobox-wrapper .combobox-button,
#page .select2-container--default .select2-selection--single .select2-selection__arrow {
	width: 36px;
	height: 36px;
	position: absolute;
	right: 0px;
	top: 0px;
	cursor: pointer;
}
#page .select2-container--default .select2-selection--single .select2-selection__arrow b {
	display: none;
}
.combobox-wrapper .combobox-button:before,
#page .select2-container--default .select2-selection--single .select2-selection__arrow:before {
	content: '\E635';
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -12px;
	margin-top: -12px;
	font-family: 'thegem-icons';
	font-size: 24px;
	width: 24px;
	height: 24px;
	line-height: 24px;
	font-weight: normal;
}
.combobox-wrapper select {
	width: 100%;
	cursor: pointer;
}

/* Checkbox */
.checkbox-sign,
.radio-sign {
	display: inline-block;
	width: 30px;
	height: 30px;
	cursor: pointer;
	position: relative;
	vertical-align: middle;
}
.radio-sign {
	width: 28px;
	height: 28px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}
#shipping_method .radio-sign {
	width: 12px;
	height: 12px;
}
.checkbox-sign.checked:before,
.radio-sign.checked:before {
	position: absolute;
	left: 50%;
	top: 50%;
}
.checkbox-sign.checked:before {
	content: '\E646';
	font-size: 24px;
	font-weight: normal;
	font-family: 'thegem-icons';
	text-align: center;
	width: 14px;
	height: 14px;
	line-height: 14px;
	margin-top: -7px;
	margin-left: -11px;
}
.radio-sign.checked:before {
	content: '';
	font-size: 10px;
	width: 16px;
	height: 16px;
	line-height: 10px;
	margin-top: -8px;
	margin-left: -8px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}
#shipping_method .radio-sign:before {
	width: 6px;
	height: 6px;
	margin-top: -3px;
	margin-left: -3px;
}

/* LAYOUT */

#page {
	position: relative;
	overflow: hidden;
}
#page.layout-boxed {
	max-width: 1260px;
	margin: 0 auto;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.11);
}

.page-title-block {
	text-align: center;
	background-position: 50% 0;
	background-size: cover;
	padding: 80px 0;
	position: relative;
}
.page-title-alignment-left {
	text-align: left;
}
.page-title-alignment-left .page-title-icon {
	float: right;
}
.page-title-alignment-right {
	text-align: right;
}
.page-title-alignment-right .page-title-icon {
	float: left;
}
.page-title-title h1,
.page-title-title h2 {
	margin: 0;
	line-height: 1;
}
.page-title-icon + .page-title-title {
	margin-top: 55px;
}
.page-title-alignment-right .page-title-icon + .page-title-title,
.page-title-alignment-left .page-title-icon + .page-title-title {
	margin-top: -7px;
}
.page-title-excerpt {
	margin-top: 18px;
	display: inline-block;
	width: 100%;
}
.page-title-block .breadcrumbs-container {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}
.page-title-block .breadcrumbs {
	display: inline-block;
}
.page-title-block .breadcrumbs > span {
	display: inline-block;
	vertical-align: top;
}

#main {
	position: relative;
	z-index: 5;
}

.container-fullwidth {
	padding: 0 40px;
}

/* TOP AREA */

.top-area {
	padding: 10px 0;
	border-bottom: 1px solid #494c64;
	position: relative;
}
.top-area-items {
	margin: 0 -35px;
}
@media (min-width: 1212px) {
	.top-area-alignment-justified .top-area-menu {
		float: right;
	}
}
.top-area-alignment-right .top-area-items {
	text-align: right;
}
.top-area-alignment-center .top-area-items {
	text-align: center;
}
@media (max-width: 1211px) {
	.top-area-items {
		text-align: center;
	}
}
.top-area-block {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	padding: 0 35px;
}
.top-area .top-area-items .top-area-block {
	height: auto;
	line-height: 30px;
}
.top-area-contacts:after,
.top-area-socials:after {
	content: '';
	position: absolute;
	height: 50px;
	margin-top: -25px;
	top: 50%;
	right: 0;
}
@media (max-width: 1211px) {
	.top-area-contacts:after,
	.top-area-socials:after {
		display: none;
	}
}
.top-area-style-2 .top-area-contacts:after,
.top-area-style-2 .top-area-socials:after {
	right: auto;
	left: 0;
}
.top-area-alignment-center .top-area-contacts:after,
.top-area-alignment-center .top-area-socials:after {
	display: none;
}
.top-area-alignment-center .top-area-contacts:after,
.top-area-alignment-center .top-area-socials:after {
	display: none;
}
.top-area-socials {
	margin: 0 -5px;
}
.top-area-socials .socials {
	margin-top: 7px;
}
.top-area-socials .socials-item {
	padding: 0 7px;
	margin: 0;
}
.top-area-socials .socials-item a {
	width: 20px;
	height: 20px;
}
.top-area-socials .socials-item a:after {
	line-height: 20px;
	font-size: 16px;
}
.top-area-button .gem-button {
	margin: 0;
	font-family: inherit;
	font-weight: inherit;
	font-style: inherit;
	vertical-align: top;
}
.top-area-button .gem-button:after {
	display: none;
}
.top-area .top-area-block.top-area-menu {
	font-size: 0;
}
.top-area-menu .nav-menu {
	margin: 0 -15px;
}
.top-area-menu .nav-menu > li {
	display: inline-block;
	padding: 0 15px;
	line-height: 30px;
}

.top-area-menu .nav-menu > li a {
	display: block;
	font-size: 14px;
}
.top-area-menu .nav-menu > li a:before {
	content: '\E601';
	font-family: 'thegem-icons';
	font-weight: normal;
	margin-right: 2px;
	font-size: 16px;
	vertical-align: top;
}
#top-area-menu,
.top-area-button {
	display: inline-block;
	vertical-align: top;
}
#top-area-menu + .top-area-button {
	margin-left: 40px;
}
#site-header.shrink.fixed .top-area-scroll-hide {
	display: none;
}

/* HEADER */

#page:not(.vertical-header) #site-header {
	position: relative;
	z-index: 5;
	-moz-box-shadow: 0 0 2px 0 rgba(56, 69, 84, 0.2);
	-webkit-box-shadow: 0 0 2px 0 rgba(56, 69, 84, 0.2);
	box-shadow: 0 0 2px 0 rgba(56, 69, 84, 0.2);
}
#page .site-header-wrapper-transparent #site-header:not(.shrink) {
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}
#page #site-header:not(.animated-header) {
	z-index: 7;
}
#page:not(.vertical-header) #site-header.animated-header {
	position: static;
}
#site-header .site-title {
	display: table-cell;
	vertical-align: middle;
	padding: 15px 0;
}
#site-header .header-layout-overlay .site-title {
	position: relative;
	z-index: 11;
}
#page.vertical-header #site-header .site-title {
	margin-bottom: 40px;
}
#site-header .primary-navigation,
#site-header #perspective-menu-buttons {
	display: table-cell;
	width: 99%;
	vertical-align: middle;
	text-align: right;
	position: relative;
	z-index: 10;
}
#site-header .logo-position-right .primary-navigation,
#site-header .logo-position-right #perspective-menu-buttons {
	text-align: left;
}
#site-header .logo-position-menu_center:not(.header-layout-fullwidth_hamburger):not(.header-layout-overlay) > .site-title {
	display: none;
}
#site-header .logo-position-menu_center:not(.header-layout-fullwidth_hamburger):not(.header-layout-overlay) #primary-menu.no-responsive {
	margin-left: 0;
	margin-right: 0;
}
#site-header .primary-navigation .menu-item-logo {
	padding: 0 50px !important;
}
#site-header .primary-navigation.responsive .menu-item-logo {
	display: none !important;
}
@media (max-width: 979px) {
	#site-header .primary-navigation .menu-item-logo {
		display: none;
	}
}
@media (max-width: 768px) {
	#site-header .logo-position-menu_center.header-main > .site-title {
		display: table-cell;
	}
}
@media (min-width: 980px) {
	#site-header .logo-position-menu_center:not(.header-layout-fullwidth_hamburger) #primary-navigation,
	#site-header .logo-position-menu_center:not(.header-layout-fullwidth_hamburger) #perspective-menu-buttons {
		display: block;
		width: auto;
		text-align: left;
	}
	#site-header .logo-position-menu_center.header-layout-fullwidth:not(.header-layout-fullwidth_hamburger) #primary-navigation,
	#site-header .logo-position-menu_center.header-layout-fullwidth:not(.header-layout-fullwidth_hamburger) #perspective-menu-buttons {
		text-align: center;
	}
}
.site-title .site-logo,
#site-header .primary-navigation .menu-item-logo .site-logo {
	margin: 0 auto;
	font-size: 0 !important;
	line-height: 0 !important;
}
.header-style-vertical .site-title .site-logo {
	margin: 0;
}
@media (min-width: 768px) {
	#site-header .logo-position-center .site-title,
	#site-header .logo-position-center .primary-navigation,
	#site-header .logo-position-center #perspective-menu-buttons {
		display: block;
		text-align: center;
	}
	#site-header .logo-position-center .site-title {
		padding-top: 40px;
		padding-bottom: 10px;
	}
}
#site-header .header-main {
	position: relative;
	z-index: 11;
}
#site-header .header-main-style-fullwidth {
	padding: 0 21px;
}
#page.vertical-header {
	padding-left: 45px;
}
#page.vertical-header #site-header-wrapper {
	position: fixed;
	left: 0;
	top: 0;
	width: 360px;
	min-height: 100%;
	box-shadow: 0 0 2px 0 rgba(56, 69, 84, 0.2);
	-moz-box-shadow: 0 0 2px 0 rgba(56, 69, 84, 0.2);
	-webkit-box-shadow: 0 0 2px 0 rgba(56, 69, 84, 0.2);
	padding: 55px 45px 0;
	margin-left: -315px;
	-o-transition: all 1s;
	-webkit-transition: all 1s;
	transition: all 1s;
	z-index: 1000;
}
body.admin-bar #page.vertical-header #site-header-wrapper {
	top: 32px;
}
#page.vertical-header #site-header-wrapper.vertical-active {
	margin-left: 0;
}
#page.vertical-header #site-header-wrapper .header-main {
	/*visibility: hidden;*/
	display: block;
}
#page.vertical-header #site-header-wrapper.vertical-active .header-main {
	/*visibility: visible;*/
}
@media (min-width: 1600px) {
	#page.vertical-header {
		padding-left: 360px;
	}
	#page.vertical-header #site-header-wrapper {
		margin-left: 0;
	}
	#page.vertical-header #site-header-wrapper .header-main {
		display: block;
		visibility: visible;
	}
}
#page.vertical-header #site-header .container,
#site-header .container-fullwidth {
	width: 100%;
}
#site-header .container-fullwidth {
	padding-left: 37px;
	padding-right: 37px;
}
@media (max-width: 767px) {
	#site-header .container-fullwidth {
		padding-left: 21px;
		padding-right: 21px;
	}
}
#page.vertical-header #site-header .site-title {
	display: block;
}
#page.vertical-header #site-header .primary-navigation,
#page.vertical-header #site-header #perspective-menu-buttons {
	display: block;
	width: auto;
}
#page.vertical-header .vertical-toggle {
	border: none;
	width: 27px;
	height: 23px;
	text-indent: 100%;
	font-size: 0;
	line-height: 0;
	overflow: hidden;
	position: absolute;
	top: 10px;
	right: 9px;
	cursor: pointer;
	outline: none;
	background: transparent;
	padding: 0;
	-o-transition: all 1s;
	-webkit-transition: all 1s;
	transition: all 1s;
}
@media (min-width: 1600px) {
	#page.vertical-header .vertical-toggle {
		display: none;
	}
}
#page.vertical-header .vertical-toggle .menu-line-1,
#page.vertical-header .vertical-toggle .menu-line-2,
#page.vertical-header .vertical-toggle .menu-line-3 {
	position: absolute;
	height: 3px;
	width: 100%;
	left: 0;
	-o-transition: all 1s;
	-webkit-transition: all 1s;
	transition: all 1s;
}
#page.vertical-header .vertical-toggle .menu-line-1 {
	top: 2px;
	transform: rotate(0);
}
#page.vertical-header #site-header-wrapper.vertical-active .vertical-toggle .menu-line-1 {
	transform: rotate(45deg);
	top: 10px;
}
#page.vertical-header .vertical-toggle .menu-line-2 {
	top: 10px;
	opacity: 1;
}
#page.vertical-header #site-header-wrapper.vertical-active .vertical-toggle .menu-line-2 {
	opacity: 0;
}
#page.vertical-header .vertical-toggle .menu-line-3 {
	top: 18px;
	transform: rotate(0);
}
#page.vertical-header #site-header-wrapper.vertical-active .vertical-toggle .menu-line-3 {
	top: 10px;
	transform: rotate(-45deg);
}

@media (max-width: 767px) {
	#page.vertical-header .vertical-toggle {
		display: none;
	}
	#page.vertical-header {
		padding-left: 0;
	}
	#page.vertical-header #site-header-wrapper {
		margin-left: 0;
	}
	#page.vertical-header #site-header-wrapper .header-main {
		display: table;
		visibility: visible;
	}
}


/* PRIMARY NAVIGATION */

#primary-menu,
#primary-menu + ul {
	text-align: left;
}
#primary-navigation .menu-toggle,
#perspective-menu-buttons .menu-toggle,
#perspective-menu-buttons .menu-toggle {
	display: none;
}
#primary-navigation #primary-menu.no-responsive li.dl-parent,
#primary-navigation #primary-menu.no-responsive li.dl-back {
	display: none;
}
#primary-menu.no-responsive {
	text-align: left;
	white-space: nowrap;
	display: inline-block;
	font-size: 0;
	margin: 0 -20px;
}
#page.vertical-header #primary-menu.no-responsive {
	margin: 0;
	display: block;
}
#primary-menu.no-responsive li {
	position: relative;
}
#primary-menu.no-responsive li > a {
	position: relative;
	display: block;
	text-decoration: none;
}
#primary-menu.no-responsive > li {
	display: inline-block;
	vertical-align: middle;
}
#page.vertical-header #primary-menu.no-responsive,
#page.vertical-header #primary-menu.no-responsive > li {
	display: block;
}
#page.vertical-header #primary-menu.no-responsive > li.menu-item-logo {
	display: none;
}
#page.vertical-header #primary-menu.no-responsive > li {
	padding-right: 66px;
	margin-right: -66px;
}
#primary-menu.no-responsive > li > a {
	padding: 35px 20px;
	text-transform: uppercase;
}
#page.vertical-header #primary-menu.no-responsive > li > a {
	padding: 12px 0 13px;
}
.header-style-2 #primary-menu.no-responsive > li > a:before {
	content: "\E601";
	font-family: "thegem-icons";
	font-size: 16px;
	font-weight: normal;
	vertical-align: top;
}
.header-style-3 #primary-menu.no-responsive > li,
.header-style-4 #primary-menu.no-responsive > li {
	padding: 33px 3px;
}
.header-style-3 #primary-menu.no-responsive > li.menu-item-current,
.header-style-3 #primary-menu.no-responsive > li.menu-item-active,
.header-style-4 #primary-menu.no-responsive > li.menu-item-current,
.header-style-4 #primary-menu.no-responsive > li.menu-item-active {
	padding: 31px 1px;
}
.header-style-3 #primary-menu.no-responsive > li > a,
.header-style-4 #primary-menu.no-responsive > li > a {
	padding: 0px 15px;
}
.header-style-1 #primary-menu.no-responsive > li.menu-item-current > a,
.header-style-1 #primary-menu.no-responsive > li.menu-item-active > a {
	padding-bottom: 31px;
	border-bottom: 4px solid #d7dee4;
}
#site-header:not(.shrink) .transparent-header-background .header-style-1 #primary-menu.no-responsive > li.menu-item-current > a,
#site-header:not(.shrink) .transparent-header-background .header-style-1 #primary-menu.no-responsive > li.menu-item-active > a {
	border-bottom-color: transparent;
	background-color: transparent;
}
.header-style-2 #primary-menu.no-responsive li li.menu-item-current > a,
.header-style-2 #primary-menu.no-responsive li li.menu-item-active > a,
.header-style-2 #primary-menu.no-responsive li li:hover > a,
.header-style-3 #primary-menu.no-responsive li li.menu-item-current > a,
.header-style-3 #primary-menu.no-responsive li li.menu-item-active > a,
.header-style-3 #primary-menu.no-responsive li li:hover > a {
	padding-left: 27px;
	border-left: 3px solid transparent;
}
#primary-menu.no-responsive > li ul {
	position: absolute;
	visibility: hidden;
	white-space: normal;
	width: 280px;
	transition: transform 0.1s linear 0.5s, opacity 0.1s linear 0.5s, visibility 0.1s linear 0.5s;
	-moz-transition: transform 0.1s linear 0.5s, opacity 0.1s linear 0.5s, visibility 0.1s linear 0.5s;
	-webkit-transition: transform 0.1s linear 0.5s, opacity 0.1s linear 0.5s, visibility 0.1s linear 0.5s;
	opacity: 0;
}
#primary-menu.no-responsive > li > ul:before {
	content: '';
	position: absolute;
	bottom: -3px;
	left: -3px;
	right: -3px;
	height: 3px;
}
#primary-menu.no-responsive > li > ul {
	transform: translateY(20px);
	-o-transform: translateY(20px);
	-ms-transform: translateY(20px);
	-moz-transform: translateY(20px);
	-webkit-transform: translateY(20px);
}
.header-main:not(.header-layout-overlay) #primary-menu.no-responsive:hover li:hover > ul,
#thegem-perspective .perspective-navigation #primary-menu.no-responsive:hover li:hover > ul {
	visibility: visible;
	transition: transform 0.3s linear, opacity 0.3s linear, visibility 0.3s linear;
	-moz-transition: transform 0.3s linear, opacity 0.3s linear, visibility 0.3s linear;
	-webkit-transition: transform 0.3s linear, opacity 0.3s linear, visibility 0.3s linear;
	opacity: 1;
	transform: translateY(0);
	-o-transform: translateY(0);
	-ms-transform: translateY(0);
	-moz-transform: translateY(0);
	-webkit-transform: translateY(0);
}
#primary-menu.no-responsive:hover > li ul {
	visibility: hidden;
	transition: transform 0.1s linear, opacity 0.1s linear, visibility 0.1s linear;
	-moz-transition: transform 0.1s linear, opacity 0.1s linear, visibility 0.1s linear;
	-webkit-transition: transform 0.1s linear, opacity 0.1s linear, visibility 0.1s linear;
	opacity: 0;
}
#primary-menu.no-responsive > li > ul {
	top: 100%;
	left: 0;
}
#page.vertical-header #primary-menu.no-responsive > li > ul {
	top: 0;
	left: 100%;
}
#primary-menu.no-responsive > li > ul.invert {
	left: auto;
	right: 0;
}
#primary-menu.no-responsive > li > ul a {
	padding: 15px 30px;
}
#primary-menu.no-responsive > li > ul ul {
	top: 0;
	left: 100%;
	transform: translateX(20px);
	-o-transform: translateX(20px);
	-ms-transform: translateX(20px);
	-moz-transform: translateX(20px);
	-webkit-transform: translateX(20px);
	z-index: 1;
}
#page .header-style-vertical #primary-menu.no-responsive > li ul.vertical-invert {
	top: auto;
}
#primary-menu.no-responsive > li > ul ul.invert {
	top: 0;
	left: auto;
	right: 100%;
	transform: translateX(-20px);
	-o-transform: translateX(-20px);
	-ms-transform: translateX(-20px);
	-moz-transform: translateX(-20px);
	-webkit-transform: translateX(-20px);
}
#primary-menu.no-responsive > li > ul li:hover > ul {
	transform: translateX(0);
	-o-transform: translateX(0);
	-ms-transform: translateX(0);
	-moz-transform: translateX(0);
	-webkit-transform: translateX(0);
}
#primary-menu.no-responsive > li li.menu-item-parent > a:after {
	position: absolute;
	width: 9px;
	height: 16px;
	right: 20px;
	top: 50%;
	margin-top: -8px;
	font-weight: normal;
	font-family: 'thegem-icons';
	content: '\E601';
	font-size: 16px;
	line-height: 16px;
}

#primary-menu.no-responsive .hamburger-widgets {
	white-space: normal;
	padding: 13px 15px;
}

.header-layout-vertical #primary-menu.no-responsive .hamburger-widgets {
	padding: 13px 0;
}

/* Mega Menu Styles*/
#primary-menu.no-responsive > li.megamenu-enable > ul:not(.megamenu-item-inited) {
	opacity: 0 !important;
	transform: translateY(20px) !important;
	-o-transform: translateY(20px) !important;
	-ms-transform: translateY(20px) !important;
	-moz-transform: translateY(20px) !important;
	-webkit-transform: translateY(20px) !important;
}
#primary-menu.no-responsive > li.megamenu-enable > ul > li > ul ul {
	display: none;
}
#primary-menu.no-responsive > li.megamenu-enable > ul {
	width: auto;
	white-space: nowrap;
	background-repeat: no-repeat;
	font-size: 0;
	line-height: 1;
}
#primary-menu.no-responsive > li.megamenu-enable > ul.megamenu-masonry.megamenu-masonry-inited > li {
	position: absolute;
}
#primary-menu.no-responsive li > ul.without-transition,
#primary-navigation.without-transition ul {
	transition: none !important;
	-moz-transition: none !important;
	-webkit-transition: none !important;
}
#primary-menu.no-responsive > li.megamenu-enable ul {
	width: auto;
}
#primary-menu.no-responsive > li.megamenu-enable > ul ul {
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	border-left: 0;
	border-right: 0;
}
#primary-menu.no-responsive > li.megamenu-enable > ul:not(.megamenu-masonry) > li.megamenu-no-right-border,
#primary-menu.no-responsive > li.megamenu-enable > ul:not(.megamenu-masonry) > li.megamenu-no-right-border span.megamenu-column-header {
	border-right: 0;
}
#primary-menu.no-responsive > li.megamenu-enable > ul > li {
	display: inline-block;
	vertical-align: top;
	border: 0;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li {
	margin: -1px 0 -1px -1px;
	border-top: 0 !important;
	border-bottom: 0 !important;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul > li {
	margin: 0 0 0 31px;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul > li.megamenu-first-element {
	margin-left: 0;
}
#primary-menu.no-responsive > li.megamenu-enable > ul > li.megamenu-new-row {
	display: block;
	font-size: 0;
	line-height: 1;
	height: 0;
	padding: 0 !important;
	margin: 0 0 1px 0;
}
#primary-menu.no-responsive > li.megamenu-enable > ul.megamenu-masonry > li.megamenu-new-row {
	margin-bottom: 0;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul > li.megamenu-new-row {
	margin: 0 0 7px 0;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default ul ul {
	border: 0;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default li,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default li.menu-item-current,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default li.menu-item-active {
	background: none;
	border: 0;
}
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header {
	display: block;
	border-top: 0;
	position: relative;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul > li span.megamenu-column-header {
	padding-top: 8px;
	padding-bottom: 12px;
	margin-bottom: 11px;
	border-top: 0;
	border-left: 0;
	border-right: 0;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul > li a {
	display: block;
	background: none;
	padding: 0;
	-o-transition: none;
	-webkit-transition: none;
	transition: none;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul li:hover > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default li.menu-item-current > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default li.menu-item-active > a {
	border: 0;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default li.menu-item-parent > a:after {
	display: none;
}
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a {
	text-transform: uppercase;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul > li span.megamenu-column-header a {
	padding: 0;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li span.megamenu-column-header a {
	display: block;
	padding-top: 13px;
	padding-bottom: 13px;
}
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a.mega-no-link {
	pointer-events: none;
}
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header.mega-not-show {
	display: none;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul > li span.megamenu-column-header a.megamenu-has-icon {
	padding-left: 26px;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li span.megamenu-column-header a.megamenu-has-icon {
	padding-left: 56px;
}
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a.megamenu-has-icon:before {
	position: absolute;
	left: 0;
	display: inline-block;
	font-weight: normal;
	font-family: 'FontAwesome';
	content: attr(data-icon);
	font-size: 14px;
	vertical-align: baseline;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li span.megamenu-column-header a.megamenu-has-icon:before {
	left: 30px;
}
#primary-menu.no-responsive > li.megamenu-enable > ul > li .mega-label {
	display: inline;
	font-size: 65%;
	line-height: 1;
	text-transform: uppercase;
	padding: 0 4px 0px 4px;
	margin: 0 0 0 10px;

}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li > a {
	padding-left: 55px;
}
.header-layout-fullwidth_hamburger.logo-position-right #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li.menu-item-current > a,
.header-layout-fullwidth_hamburger.logo-position-right #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li.menu-item-active > a,
.header-layout-fullwidth_hamburger.logo-position-right #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li:hover > a,
.header-style-2 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li.menu-item-current > a,
.header-style-2 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li.menu-item-active > a,
.header-style-2 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li:hover > a,
.header-style-3 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li.menu-item-current > a,
.header-style-3 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li.menu-item-active > a,
.header-style-3 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li:hover > a {
	padding-left: 52px;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul > li > ul li > a {
	position: relative;
	padding: 8px 12px 8px 47px;
	vertical-align: middle;
	margin-left: -20px;
}
#primary-menu.no-responsive > li.megamenu-enable > ul > li > ul > li > a:before {
	display: inline-block;
	font-weight: normal;
	font-family: 'FontAwesome';
	content: "\F105";
	font-size: 14px;
	vertical-align: baseline;
	width: 25px;
	text-align: left;
	margin-left: -13px;
	padding-left: 4px;
	position: absolute;
	left: 34px;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul > li > a:before {
	margin: 0;
	left: 30px;
}
.header-layout-fullwidth_hamburger.logo-position-right #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li.menu-item-current > a:before,
.header-layout-fullwidth_hamburger.logo-position-right #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li.menu-item-active > a:before,
.header-layout-fullwidth_hamburger.logo-position-right #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li:hover > a:before,
.header-style-2 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li.menu-item-current > a:before,
.header-style-2 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li.menu-item-active > a:before,
.header-style-2 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li:hover > a:before,
.header-style-3 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li.menu-item-current > a:before,
.header-style-3 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li.menu-item-active > a:before,
.header-style-3 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li:hover > a:before {
	left: 27px;
}
#primary-menu.no-responsive > li.megamenu-enable > ul > li > ul > li.megamenu-has-icon > a::before {
	content: attr(data-icon);
	padding: 0;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul > li > ul > li > a:before {
	margin-right: 0;
	font-size: 14px;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul > li > ul > li.megamenu-has-icon > a::before {
	font-family: 'FontAwesome';
	content: attr(data-icon);
}
#primary-menu.no-responsive > li.megamenu-enable > ul > li > ul {
	position: static;
	transform: translateX(0);
	-o-transform: translateX(0);
	-ms-transform: translateX(0);
	-moz-transform: translateX(0);
	-webkit-transform: translateX(0);
}
#primary-menu.no-responsive > li.megamenu-enable:hover > ul > li > ul {
	visibility: visible;
	opacity: 1;
}
#primary-navigation .hamburger-toggle {
	border: none;
	width: 36px;
	height: 30px;
	text-indent: 100%;
	font-size: 0;
	line-height: 0;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	outline: none;
	background: transparent;
	padding: 0;
	-o-transition: margin-right 1s, margin-left 1s, width .3s, height .2s, transform 1s;
	-webkit-transition: margin-right 1s, margin-left 1s, width .3s, height .2s, transform 1s;
	transition: margin-right 1s, margin-left 1s, width .3s, height .2s, transform 1s;
	-moz-transform: translateX(0);
	-webkit-transform: translateX(0);
	transform: translateX(0);
	margin-right: 0;
	margin-top: 35px;
	margin-bottom: 35px;
}
#primary-navigation .hamburger-toggle .menu-line-1,
#primary-navigation .hamburger-toggle .menu-line-2,
#primary-navigation .hamburger-toggle .menu-line-3 {
	position: absolute;
	height: 4px;
	width: 100%;
	left: 0;
	-webkit-transition: all 0.5s, top .3s, height .3s;
	transition: all 0.5s, top .3s, height .3s;
}
#primary-navigation .hamburger-toggle .menu-line-1 {
	top: 3px;
	transform: rotate(0);
}
#primary-navigation.hamburger-active .hamburger-toggle .menu-line-1 {
	transform: rotate(45deg);
	top: 13px;
}
#primary-navigation .hamburger-toggle .menu-line-2 {
	top: 13px;
	opacity: 1;
}
#primary-navigation.hamburger-active .hamburger-toggle .menu-line-2 {
	opacity: 0;
}
#primary-navigation .hamburger-toggle .menu-line-3 {
	top: 23px;
	transform: rotate(0);
}
#primary-navigation.hamburger-active .hamburger-toggle .menu-line-3 {
	top: 13px;
	transform: rotate(-45deg);
}

#primary-navigation .hamburger-group.hamburger-size-small .hamburger-toggle .menu-line-1,
#primary-navigation .hamburger-group.hamburger-size-small .hamburger-toggle .menu-line-2,
#primary-navigation .hamburger-group.hamburger-size-small .hamburger-toggle .menu-line-3 {
	height: 2px;
	width: 18px;
	left: 9px;
}
#primary-navigation .hamburger-group.hamburger-size-small .hamburger-toggle .menu-line-1 {
	top: 8px;
}
#primary-navigation.hamburger-active .hamburger-group.hamburger-size-small .hamburger-toggle .menu-line-1 {
	top: 13px;
}
#primary-navigation .hamburger-group.hamburger-size-small .hamburger-toggle .menu-line-2 {
	top: 13px;
}
#primary-navigation .hamburger-group.hamburger-size-small .hamburger-toggle .menu-line-3 {
	top: 18px;
}
#primary-navigation.hamburger-active .hamburger-group.hamburger-size-small .hamburger-toggle .menu-line-3 {
	top: 13px;
	transform: rotate(-45deg);
}

.header-layout-fullwidth_hamburger #primary-menu.no-responsive {
	position: fixed;
	top: 0;
	bottom: 0;
	width: 340px;
	-moz-transition: transform 1s;
	-webkit-transition: transform 1s;
	transition: transform 1s;
	background: #ffffff;
	padding: 100px 30px 0;
	z-index: 10;
}
.header-layout-fullwidth_hamburger #primary-menu.no-responsive {
	padding-left: 0;
}
.header-layout-fullwidth_hamburger.logo-position-right #primary-menu.no-responsive {
	padding-right: 0;
}
.header-layout-fullwidth_hamburger #primary-menu.no-responsive {
	right: 0;
	-moz-transform: translateX(340px);
	-webkit-transform: translateX(340px);
	transform: translateX(340px);
}
.header-layout-fullwidth_hamburger.logo-position-right #primary-menu.no-responsive {
	left: 0;
	-moz-transform: translateX(-340px);
	-webkit-transform: translateX(-340px);
	transform: translateX(-340px);
}
.header-layout-fullwidth_hamburger #primary-navigation.hamburger-active #primary-menu.no-responsive {
	right: 0;
	-moz-transform: translateX(0);
	-webkit-transform: translateX(0);
	transform: translateX(0);
}
.header-layout-fullwidth_hamburger.logo-position-right #primary-navigation.hamburger-active #primary-menu.no-responsive {
	left: 0;
	-moz-transform: translateX(0);
	-webkit-transform: translateX(0);
	transform: translateX(0);
}
.header-layout-fullwidth_hamburger #primary-navigation.hamburger-active .hamburger-toggle,
.header-layout-fullwidth_hamburger #primary-navigation.hamburger-active .hamburger-minicart {
	-moz-transform: translateX(-340px);
	-webkit-transform: translateX(-340px);
	transform: translateX(-340px);
}
.header-layout-fullwidth_hamburger.logo-position-right #primary-navigation.hamburger-active .hamburger-toggle,
.header-layout-fullwidth_hamburger.logo-position-right #primary-navigation.hamburger-active .hamburger-minicart {
	-moz-transform: translateX(340px);
	-webkit-transform: translateX(340px);
	transform: translateX(340px);
}
.header-layout-fullwidth_hamburger #primary-menu.no-responsive {
	margin: 0;
	display: block;
}
.header-layout-fullwidth_hamburger #primary-menu.no-responsive .menu-item-logo {
	display: none;
}
.header-layout-fullwidth_hamburger #primary-menu.no-responsive,
.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li {
	display: block;
}
.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li {
	padding: 0;
}
.header-layout-fullwidth_hamburger.logo-position-left #primary-menu.no-responsive > li {
	padding-left: 30px;
}
.header-layout-fullwidth_hamburger.logo-position-right #primary-menu.no-responsive > li {
	padding-left: 30px;
}
.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li > a {
	padding: 13px 15px;
}
.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li.menu-item-current,
.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li.menu-item-active {
	padding-top: 11px;
	padding-bottom: 11px;
}
.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li.menu-item-current > a,
.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li.menu-item-active > a,
#site-header.fixed.shrink .header-layout-fullwidth_hamburger #primary-menu.no-responsive > li.menu-item-current > a,
#site-header.fixed.shrink .header-layout-fullwidth_hamburger #primary-menu.no-responsive > li.menu-item-active > a {
	display: inline-block;
	padding-top: 0;
	padding-bottom: 0;
}
.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li > ul {
	top: 0;
	left: 100%;
}
.header-layout-fullwidth_hamburger.logo-position-left #primary-menu.no-responsive > li > ul,
.thegem-perspective.effect-moveleft.animate .perspective-navigation #primary-menu.no-responsive > li > ul {
	top: 0;
	right: 100%;
	left: auto;
}
.header-layout-fullwidth_hamburger.logo-position-left #primary-menu.no-responsive > li > ul ul,
.thegem-perspective.effect-moveleft.animate .perspective-navigation #primary-menu.no-responsive > li > ul ul {
	right: 100%;
	left: auto;
}
.header-layout-fullwidth_hamburger.logo-position-left #primary-menu.no-responsive > li li > a {
	padding-left: 45px;
}
.header-layout-fullwidth_hamburger.logo-position-left #primary-menu.no-responsive > li li.menu-item-parent > a:after {
	left: 20px;
	right: auto;
	content: '\E603';
}
.header-layout-fullwidth_hamburger.logo-position-right #primary-menu.no-responsive li li.menu-item-current > a,
.header-layout-fullwidth_hamburger.logo-position-right #primary-menu.no-responsive li li.menu-item-active > a,
.header-layout-fullwidth_hamburger.logo-position-right #primary-menu.no-responsive li li:hover > a {
	padding-left: 27px;
	border-left: 3px solid transparent;
}
.header-layout-fullwidth_hamburger.logo-position-left #primary-menu.no-responsive li li.menu-item-current > a,
.header-layout-fullwidth_hamburger.logo-position-left #primary-menu.no-responsive li li.menu-item-active > a,
.header-layout-fullwidth_hamburger.logo-position-left #primary-menu.no-responsive li li:hover > a {
	padding-right: 27px;
	border-right: 3px solid transparent;
}
.hamburger-overlay {
	visibility: hidden;
	opacity: 0;
	background-color: #212331;
	position: fixed;
	z-index: 6;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-o-transition: all 1s;
	-webkit-transition: all 1s;
	transition: all 1s;
}
.hamburger-overlay.active {
	visibility: visible;
	opacity: 0.8;
}
#site-header:not(.shrink) #primary-navigation.hamburger-active .hamburger-toggle > span,
#site-header:not(.shrink) .header-colors-light #primary-navigation.hamburger-active .hamburger-toggle > span {
	background-color: #ffffff;
}
#site-header:not(.shrink) #primary-navigation.hamburger-active .hamburger-minicart > a,
#site-header:not(.shrink) #primary-navigation.hamburger-active .hamburger-minicart:hover > a {
	color: #ffffff;
}

body.menu-scroll-locked {
	position: fixed;
	overflow: hidden;
	width: 100%;
	height: 100%;
}
body.menu-scroll-locked #wpadminbar {
	top: -46px;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu {
	min-height: 100%;
}

.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li.menu-item-language:not(.menu-item-has-children),
#thegem-perspective .perspective-navigation #primary-menu.no-responsive li.menu-item-language:not(.menu-item-has-children),
#page.vertical-header #primary-menu.no-responsive > li.menu-item-language:not(.menu-item-has-children),
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-language:not(.menu-item-has-children),
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li.menu-item-language:not(.menu-item-has-children),
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-language:not(.menu-item-has-children),
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-language:not(.menu-item-has-children),
.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu > li.menu-item-language:not(.menu-item-has-children),
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu > li.menu-item-language:not(.menu-item-has-children) {
	display: inline-block;
}

.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li.menu-item-language + li.menu-item-language,
#thegem-perspective .perspective-navigation #primary-menu.no-responsive li.menu-item-language + li.menu-item-language,
#page.vertical-header #primary-menu.no-responsive > li.menu-item-language + li.menu-item-language {
	padding-left: 0;
	margin-left: 0;
}

#page.vertical-header #primary-menu.no-responsive > li.menu-item-language:not(.menu-item-has-children),
#page.vertical-header #primary-menu.no-responsive > li.menu-item-cart {
	padding-right: 0;
	margin-right: 0;
}

.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li.menu-item-language.wpml-ls-menu-item:not(.menu-item-has-children) .wpml-ls-flag,
#thegem-perspective .perspective-navigation #primary-menu.no-responsive li.menu-item-language.wpml-ls-menu-item:not(.menu-item-has-children) .wpml-ls-flag,
#page.vertical-header #primary-menu.no-responsive > li.menu-item-language.wpml-ls-menu-item:not(.menu-item-has-children) .wpml-ls-flag,
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-language.wpml-ls-menu-item .wpml-ls-flag,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li.menu-item-language.wpml-ls-menu-item .wpml-ls-flag,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-language.wpml-ls-menu-item .wpml-ls-flag,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-language.wpml-ls-menu-item .wpml-ls-flag,
.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu > li.menu-item-language.wpml-ls-menu-item .wpml-ls-flag,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu > li.menu-item-language.wpml-ls-menu-item .wpml-ls-flag {
	vertical-align: middle;
}

.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li.menu-item-language:not(.menu-item-has-children) + li.menu-item-cart,
.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li.menu-item-language:not(.menu-item-has-children) + li.menu-item-search + li.menu-item-cart,
#thegem-perspective .perspective-navigation #primary-menu.no-responsive li.menu-item-language + li.menu-item-cart,
#thegem-perspective .perspective-navigation #primary-menu.no-responsive li.menu-item-language + li.menu-item-search + li.menu-item-cart,
#page.vertical-header #primary-menu.no-responsive > li.menu-item-language + li.menu-item-cart,
#page.vertical-header #primary-menu.no-responsive > li.menu-item-language + li.menu-item-search + li.menu-item-cart,
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-language + li.menu-item-cart,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li.menu-item-language + li.menu-item-cart,
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-language + li.menu-item-search + li.menu-item-cart,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li.menu-item-language + li.menu-item-search + li.menu-item-cart,

.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-language + li.menu-item-cart,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-language + li.menu-item-cart,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-language + li.menu-item-search + li.menu-item-cart,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-language + li.menu-item-search + li.menu-item-cart,

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu > li.menu-item-language + li.menu-item-cart,
.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu > li.menu-item-language + li.menu-item-search + li.menu-item-cart,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu > li.menu-item-language + li.menu-item-cart,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu > li.menu-item-language + li.menu-item-search + li.menu-item-cart {
	display: inline-block;
	padding-left: 0;
	margin-left: 0;
}
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-cart,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-cart,
.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu > li.menu-item-cart,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu > li.menu-item-cart {
	display: inline-block;
	padding-left: 0;
	margin-left: 0;
	vertical-align: top;
}

.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-language + li.menu-item-cart,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-language + li.menu-item-cart,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-language + li.menu-item-search + li.menu-item-cart,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-language + li.menu-item-search + li.menu-item-cart {
	vertical-align: top;
}

.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li.menu-item-cart > a,
#thegem-perspective .perspective-navigation #primary-menu.no-responsive li.menu-item-cart > a,
#page.vertical-header #primary-menu.no-responsive > li.menu-item-cart > a,
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-cart > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li.menu-item-cart > a,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-cart > a,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-cart > a,
.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu > li.menu-item-cart > a,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu > li.menu-item-cart > a {
	position: relative;
	display: inline-block;
}

#thegem-perspective .perspective-navigation #primary-menu.no-responsive li.menu-item-cart > a {
	padding-left: 0 !important;
}

.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li.menu-item-cart > a,

#thegem-perspective .perspective-navigation #primary-menu.no-responsive li.menu-item-language:not(.menu-item-has-children) + li.menu-item-language > a,
#thegem-perspective .perspective-navigation #primary-menu.no-responsive li.menu-item-language:not(.menu-item-has-children) + li.menu-item-cart > a,
#thegem-perspective .perspective-navigation #primary-menu.no-responsive li.menu-item-language:not(.menu-item-has-children) + li.menu-item-search + li.menu-item-cart > a,

#page.vertical-header #primary-menu.no-responsive > li.menu-item-language:not(.menu-item-has-children) + li.menu-item-language > a,
#page.vertical-header #primary-menu.no-responsive > li.menu-item-language:not(.menu-item-has-children) + li.menu-item-cart > a,
#page.vertical-header #primary-menu.no-responsive > li.menu-item-language:not(.menu-item-has-children) + li.menu-item-search + li.menu-item-cart > a,

.header-layout-overlay #primary-menu.no-responsive > li.menu-item-language + li.menu-item-language > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li.menu-item-language + li.menu-item-language > a,

.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-language:not(.menu-item-has-children) + li.menu-item-language > a,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-language:not(.menu-item-has-children) + li.menu-item-language > a,

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu > li.menu-item-language:not(.menu-item-has-children) + li.menu-item-language > a,
.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu > li.menu-item-language:not(.menu-item-has-children) + li.menu-item-cart > a,
.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu > li.menu-item-language:not(.menu-item-has-children) + li.menu-item-search + li.menu-item-cart > a,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu > li.menu-item-language:not(.menu-item-has-children) + li.menu-item-language > a,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu > li.menu-item-language:not(.menu-item-has-children) + li.menu-item-cart > a,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu > li.menu-item-language:not(.menu-item-has-children) + li.menu-item-search + li.menu-item-cart > a {
	padding-left: 15px !important;
}

#thegem-perspective .perspective-navigation #primary-menu.no-responsive li.menu-item-cart > a,
#page.vertical-header #primary-menu.no-responsive > li.menu-item-cart > a {
	padding-right: 15px;
}

#page .header-layout-overlay #primary-menu.no-responsive > li.menu-item-cart > a,
#page .page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li.menu-item-cart > a,
#page .mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-cart > a,
#page .page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-cart > a {
	padding-left: 15px !important;
	padding-right: 15px !important;
	margin-right: -3px !important;
}

#page .header-layout-overlay #primary-menu.no-responsive > li.menu-item-cart > a:before,
#page .page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li.menu-item-cart > a:before,
#page .mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-cart > a:before,
#page .page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-cart > a:before,
#page .mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu > li.menu-item-cart > a:before,
#page .mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu > li.menu-item-cart > a:before {
	margin-right: 0;
}

#page .mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-cart > a .minicart-item-count,
#page .page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-cart > a .minicart-item-count,
#page .mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu > li.menu-item-cart > a .minicart-item-count,
#page .mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu > li.menu-item-cart > a .minicart-item-count {
	left: auto;
	right: -15px;
}

#page .header-layout-overlay #primary-menu.no-responsive > li.menu-item-language:not(.menu-item-has-children) + li.menu-item-cart > a,
#page .page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li.menu-item-language:not(.menu-item-has-children) + li.menu-item-cart > a,
#page .mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-language:not(.menu-item-has-children) + li.menu-item-cart > a,
#page .page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-language:not(.menu-item-has-children) + li.menu-item-cart > a,
#page .mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-language:not(.menu-item-has-children) + li.menu-item-search + li.menu-item-cart > a,
#page .page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-language:not(.menu-item-has-children) + li.menu-item-search + li.menu-item-cart > a {
	margin-right: -25px !important;
}

#page .header-layout-overlay #primary-menu.no-responsive > li.menu-item-cart > a .minicart-item-count,
#page .page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li.menu-item-cart > a .minicart-item-count,
#page .mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-cart > a .minicart-item-count,
#page .page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-cart > a .minicart-item-count {
	font-size: 12px;
}

.header-layout-overlay #primary-menu.no-responsive > li.menu-item-language.wpml-ls-menu-item.menu-item-has-children a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li.menu-item-language.wpml-ls-menu-item.menu-item-has-children a {
	padding-right: 0 !important;
}

.header-layout-overlay #primary-menu.no-responsive > li.menu-item-language.wpml-ls-menu-item.menu-item-has-children a + .menu-item-parent-toggle:before,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li.menu-item-language.wpml-ls-menu-item.menu-item-has-children a  + .menu-item-parent-toggle:before {
	top: 2px !important;
}

.header-layout-overlay #primary-menu.no-responsive > li.menu-item-language.wpml-ls-menu-item.menu-item-has-children ul,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li.menu-item-language.wpml-ls-menu-item.menu-item-has-children ul,
#page .mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-language.wpml-ls-menu-item.menu-item-has-children ul,
#page .page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-language.wpml-ls-menu-item.menu-item-has-children ul {
	margin-top: 0;
	margin-bottom: 0;
}


.menu-overlay.active + #site-header.fixed.shrink {
	background: transparent;
}
#primary-navigation .overlay-toggle,
.page-additional-menu .page-additional-menu-close .overlay-toggle {
	border: none;
	width: 36px;
	height: 30px;
	text-indent: 100%;
	font-size: 0;
	line-height: 0;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	outline: none;
	background: transparent;
	padding: 0;
	-o-transition: width .3s, height .2s;
	-webkit-transition: width .3s, height .2s;
	transition: width .3s, height .2s;
	margin-right: 0;
	margin-top: 35px;
	margin-bottom: 35px;
	z-index: 2;
}
.page-additional-menu .page-additional-menu-close .overlay-toggle {
	margin: 0;
}
#primary-navigation .overlay-toggle .menu-line-1,
#primary-navigation .overlay-toggle .menu-line-2,
#primary-navigation .overlay-toggle .menu-line-3,
.page-additional-menu .page-additional-menu-close .overlay-toggle .menu-line-1,
.page-additional-menu .page-additional-menu-close .overlay-toggle .menu-line-2,
.page-additional-menu .page-additional-menu-close .overlay-toggle .menu-line-3 {
	position: absolute;
	height: 4px;
	width: 100%;
	left: 0;
	-o-transition: all 0.5s, top .3s, height .3s;
	-webkit-transition: all 0.5s, top .3s, height .3s;
	transition: all 0.5s, top .3s, height .3s;
}
.page-additional-menu .page-additional-menu-close .overlay-toggle .menu-line-1,
.page-additional-menu .page-additional-menu-close .overlay-toggle .menu-line-2,
.page-additional-menu .page-additional-menu-close .overlay-toggle .menu-line-3 {
	background-color: #ffffff;
}
#primary-navigation .overlay-toggle .menu-line-1,
#primary-navigation.overlay-active.close .overlay-toggle .menu-line-1 {
	top: 3px;
	transform: rotate(0);
}
#primary-navigation.overlay-active .overlay-toggle .menu-line-1,
.page-additional-menu .page-additional-menu-close .menu-line-1 {
	transform: rotate(45deg);
	top: 13px;
}
#primary-navigation .overlay-toggle .menu-line-2,
#primary-navigation.overlay-active.close .overlay-toggle .menu-line-2 {
	top: 13px;
	opacity: 1;
}
#primary-navigation.overlay-active .overlay-toggle .menu-line-2 {
	opacity: 0;
}
.page-additional-menu .page-additional-menu-close .menu-line-2 {
	opacity: 0;
}
#primary-navigation .overlay-toggle .menu-line-3,
#primary-navigation.overlay-active.close .overlay-toggle .menu-line-3 {
	top: 23px;
	transform: rotate(0);
}
#primary-navigation.overlay-active .overlay-toggle .menu-line-3,
.page-additional-menu .page-additional-menu-close .menu-line-3 {
	top: 13px;
	transform: rotate(-45deg);
}
#primary-navigation .overlay-toggle.toggle-size-small .menu-line-1,
#primary-navigation .overlay-toggle.toggle-size-small .menu-line-2,
#primary-navigation .overlay-toggle.toggle-size-small .menu-line-3 {
	height: 2px;
	width: 18px;
	left: 9px;
}
#primary-navigation .overlay-toggle.toggle-size-small .menu-line-1,
#primary-navigation.overlay-active.close .overlay-toggle.toggle-size-small .menu-line-1 {
	top: 8px;
}
#primary-navigation.overlay-active .overlay-toggle.toggle-size-small .menu-line-1 {
	top: 13px;
}
#primary-navigation .overlay-toggle.toggle-size-small .menu-line-2,
#primary-navigation.overlay-active.close .overlay-toggle.toggle-size-small .menu-line-2 {
	top: 13px;
}
#primary-navigation .overlay-toggle.toggle-size-small .menu-line-3,
#primary-navigation.overlay-active.close .overlay-toggle.toggle-size-small .menu-line-3 {
	top: 18px;
}
#primary-navigation.overlay-active .overlay-toggle .menu-line-3 {
	top: 13px;
}


.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu li.menu-item-search,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu li.menu-item-widgets,
.mobile-menu-layout-slide-horizontal #primary-navigation #primary-menu.no-responsive li.menu-item-widgets.mobile-only,
.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li.menu-item-search,
.mobile-menu-layout-slide-vertical #primary-navigation #primary-menu.no-responsive li.menu-item-widgets.mobile-only,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li.menu-item-search {
	display: none;
}
.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li.menu-item-search,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li.menu-item-search {
	display: inline-block;
	position: static;
}
.header-layout-overlay #primary-menu.no-responsive li.menu-item-search,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu li.menu-item-search {
	display: inline-block;
	position: static;
	vertical-align: top;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li.menu-item-language,
.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li.menu-item-cart,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li.menu-item-language,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li.menu-item-cart {
	border: 0;
	vertical-align: top;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu > li.menu-item-language:not(.menu-item-has-children) a,
.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu > li.menu-item-cart a,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu > li.menu-item-language:not(.menu-item-has-children) a,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu > li.menu-item-cart a {
	background-color: transparent;
	border: 0;
	padding-right: 15px;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu > li.menu-item-language a,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu > li.menu-item-language a,
.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu > li.menu-item-cart a,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu > li.menu-item-cart a {
	padding-top: 16px;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu > li.menu-item-language:not(.menu-item-has-children) a,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu > li.menu-item-language:not(.menu-item-has-children) a {
	padding-right: 0;
}

.header-layout-overlay #primary-navigation:not(.responsive) .overlay-menu-wrapper,
.mobile-menu-layout-overlay #primary-navigation.responsive .overlay-menu-wrapper,
.page-additional-menu.header-layout-overlay .primary-navigation:not(.responsive) .overlay-menu-wrapper,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .overlay-menu-wrapper {
	position: fixed;
	visibility: hidden;
	left: 0;
	top: 60px;
	bottom: auto;
	width: 100%;
	height: 0;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}
.header-layout-overlay #primary-navigation:not(.responsive) .overlay-menu-wrapper::-webkit-scrollbar,
.mobile-menu-layout-overlay #primary-navigation.responsive .overlay-menu-wrapper::-webkit-scrollbar,
.page-additional-menu.header-layout-overlay .primary-navigation:not(.responsive) .overlay-menu-wrapper::-webkit-scrollbar,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .overlay-menu-wrapper::-webkit-scrollbar {
	width: 2px;
}
body.admin-bar .header-layout-overlay #primary-navigation:not(.responsive) .overlay-menu-wrapper,
body.admin-bar .mobile-menu-layout-overlay #primary-navigation.responsive .overlay-menu-wrapper,
body.admin-bar .page-additional-menu.header-layout-overlay .primary-navigation:not(.responsive) .overlay-menu-wrapper,
body.admin-bar .page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .overlay-menu-wrapper {
	top: 90px;
}
.header-layout-overlay #primary-navigation:not(.responsive) .overlay-menu-wrapper.active,
.mobile-menu-layout-overlay #primary-navigation.responsive .overlay-menu-wrapper.active,
.page-additional-menu.header-layout-overlay .primary-navigation:not(.responsive) .overlay-menu-wrapper.active,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .overlay-menu-wrapper.active {
	visibility: visible;
	height: auto;
	bottom: 0;
}
.header-layout-overlay #primary-navigation:not(.responsive) .overlay-menu-table,
.mobile-menu-layout-overlay #primary-navigation.responsive .overlay-menu-table,
.page-additional-menu.header-layout-overlay .primary-navigation:not(.responsive) .overlay-menu-table,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .overlay-menu-table {
	display: table;
	width: 100%;
	height: 100%;
}
.header-layout-overlay :not(.responsive) .overlay-menu-row,
.mobile-menu-layout-overlay #primary-navigation.responsive .overlay-menu-row,
.page-additional-menu.header-layout-overlay :not(.responsive) .overlay-menu-row,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .overlay-menu-row {
	display: table-row;
}
.header-layout-overlay :not(.responsive) .overlay-menu-cell,
.mobile-menu-layout-overlay #primary-navigation.responsive .overlay-menu-cell,
.page-additional-menu.header-layout-overlay :not(.responsive) .overlay-menu-cell,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .overlay-menu-cell {
	display: table-cell;
	vertical-align: middle;
}
.header-layout-overlay #primary-menu.no-responsive,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu {
	position: relative;
	padding: 20px;
	text-align: center;
}
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu {
	background: transparent;
}
.header-layout-overlay #primary-menu.no-responsive,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu {
	width: 100%;
	opacity: 0.4;
	-webkit-transform-origin: 50% 0;
	transform-origin: 50% 0;
	-webkit-transform: translateY(-10%) rotateX(35deg);
	transform: translateY(-10%) rotateX(35deg);
	-webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
	transition: transform 0.5s, opacity 0.5s;
	margin: 0;
	display: block;
	visibility: hidden;
}
.header-layout-overlay #primary-navigation.overlay-active #primary-menu.no-responsive,
.mobile-menu-layout-overlay #primary-navigation.responsive.overlay-active #primary-menu,
.page-additional-menu.header-layout-overlay .primary-navigation.overlay-active .nav-menu.no-responsive,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive.overlay-active .nav-menu {
	visibility: visible;
	opacity: 1;
	-webkit-transform: rotateX(0);
	transform: rotateX(0);
}
.header-layout-overlay #primary-navigation.overlay-active.close #primary-menu.no-responsive,
.mobile-menu-layout-overlay #primary-navigation.responsive.overlay-active.close #primary-menu,
.page-additional-menu.header-layout-overlay .primary-navigation.overlay-active.close .nav-menu.no-responsive,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive.overlay-active.close .nav-menu {
	opacity: 0;
	-webkit-transform: translateY(10%) rotateX(0);
	transform: translateY(10%) rotateX(0);
}
.header-layout-overlay #primary-menu.no-responsive .menu-item-logo,
.header-layout-overlay #primary-navigation.responsive #primary-menu .menu-item-logo,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive .menu-item-logo,
.page-additional-menu.header-layout-overlay .primary-navigation.responsive .nav-menu .menu-item-logo {
	display: none;
}
.header-layout-overlay #primary-menu.no-responsive,
.header-layout-overlay #primary-menu.no-responsive > li,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li {
	display: block;
}
.header-layout-overlay #primary-menu.no-responsive > li,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li {
	padding: 0;
}
.header-layout-overlay.logo-position-left #primary-menu.no-responsive > li,
.mobile-menu-layout-overlay .logo-position-left #primary-navigation.responsive #primary-menu > li,
.page-additional-menu.header-layout-overlay.logo-position-left .nav-menu.no-responsive > li,
.page-additional-menu.mobile-menu-layout-overlay .logo-position-left .primary-navigation.responsive .nav-menu > li {
	padding-left: 0;
}
.header-layout-overlay.logo-position-right #primary-menu.no-responsive > li,
.mobile-menu-layout-overlay .logo-position-right #primary-navigation.responsive #primary-menu > li,
.page-additional-menu.header-layout-overlay.logo-position-right .nav-menu.no-responsive > li,
.page-additional-menu.mobile-menu-layout-overlay .logo-position-right .primary-navigation.responsive .nav-menu > li {
	padding-left: 0;
}
.header-layout-overlay #primary-menu.no-responsive > li > a,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li > a,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li > a {
	padding: 0 !important;
}
.header-layout-overlay #primary-menu.no-responsive > li > a {
	background-color: transparent !important;
	border: 0 none !important;
}
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-current,
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-active,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-current,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-active,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li.menu-item-current,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li.menu-item-active,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-current,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-active {
	padding: 0;
}
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-current > a,
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-active > a {
	padding-top: 0;
	padding-bottom: 0;
	border-bottom: 0;
}
.header-layout-overlay #primary-menu.no-responsive > li > a,
.header-layout-overlay #primary-menu.no-responsive > li li > a,
#site-header.fixed.shrink .header-layout-overlay #primary-menu.no-responsive > li > a,

.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-current > a,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-active > a,
#site-header.fixed.shrink.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-current > a,
#site-header.fixed.shrink.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-active > a,

.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li.menu-item-current > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li.menu-item-active > a,
#site-header.fixed.shrink .page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li.menu-item-current > a,
#site-header.fixed.shrink .page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li.menu-item-active > a,

.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-current > a,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-active > a,
#site-header.fixed.shrink.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-current > a,
#site-header.fixed.shrink.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li.menu-item-active > a {
	display: inline-block;
	padding-top: 0;
	padding-bottom: 0 !important;
}
.header-style-3.header-layout-overlay #primary-menu.no-responsive > li.menu-item-current > a,
.header-style-3.header-layout-overlay #primary-menu.no-responsive > li.menu-item-active > a,
.header-style-4.header-layout-overlay #primary-menu.no-responsive > li.menu-item-current > a,
.header-style-4.header-layout-overlay #primary-menu.no-responsive > li.menu-item-active > a,
.mobile-menu-layout-overlay .header-style-3 #primary-navigation.responsive #primary-menu > li.menu-item-current > a,
.mobile-menu-layout-overlay .header-style-3 #primary-navigation.responsive #primary-menu > li.menu-item-active > a,
.mobile-menu-layout-overlay .header-style-4 #primary-navigation.responsive #primary-menu > li.menu-item-current > a,
.mobile-menu-layout-overlay .header-style-4 #primary-navigation.responsive #primary-menu > li.menu-item-active > a {
	border: 0 none;
}
.header-layout-overlay #primary-menu.no-responsive > li > ul,
.header-layout-overlay #primary-menu.no-responsive > li > ul ul,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li > ul,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li > ul ul,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li > ul,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li > ul ul,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li > ul,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li > ul ul {
	position: relative;
	width: auto;
	top: auto;
	bottom: auto;
	left: auto;
	right: auto;
	margin: 0;
	-webkit-transform: none;
	transform: none;
	height: 0;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	box-sizing: border-box;
	text-transform: uppercase;
	overflow: hidden;
}
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive a,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu a {
	display: inline-block;
}
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li > a,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li > a {
	text-transform: uppercase;
}
.header-layout-overlay #primary-navigation:not(.overlay-active) #primary-menu.no-responsive ul,
.mobile-menu-layout-overlay #primary-navigation.responsive:not(.overlay-active) #primary-menu ul,
.page-additional-menu.header-layout-overlay .primary-navigation:not(.overlay-active) .nav-menu.no-responsive ul,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive:not(.overlay-active) .nav-menu ul {
	display: none;
}
.header-layout-overlay #primary-menu.no-responsive li.menu-overlay-item-open > ul,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu li.menu-overlay-item-open > ul,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive li.menu-overlay-item-open > ul,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu li.menu-overlay-item-open > ul {
	opacity: 1;
	height: auto;
	visibility: visible;
}
.header-layout-overlay #primary-menu.no-responsive li.menu-overlay-item-open > ul,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu li.menu-overlay-item-open > ul,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive li.menu-overlay-item-open > ul,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu li.menu-overlay-item-open > ul {
	margin-top: 40px;
}
.header-layout-overlay #primary-menu.no-responsive li.menu-overlay-item-open:not(:last-child) > ul,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu li.menu-overlay-item-open:not(:last-child) > ul,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive li.menu-overlay-item-open:not(:last-child) > ul,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu li.menu-overlay-item-open:not(:last-child) > ul {
	margin-bottom: 40px;
}
.header-layout-overlay #primary-menu.no-responsive > li li > a,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li li > a,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li li > a {
	padding: 0;
}
.header-layout-overlay #primary-navigation #primary-menu.no-responsive > li ul,
.header-layout-overlay #primary-navigation #primary-menu.no-responsive > li li,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu.responsive > li ul,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu.responsive > li li,
.page-additional-menu.header-layout-overlay .primary-navigation .nav-menu.no-responsive > li ul,
.page-additional-menu.header-layout-overlay .primary-navigation .nav-menu.no-responsive > li li,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu.responsive > li ul,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu.responsive > li li {
	border: 0 none;
}
.header-layout-overlay #primary-navigation #primary-menu.no-responsive > li li > a,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li li > a,
.page-additional-menu.header-layout-overlay .primary-navigation .nav-menu.no-responsive > li li > a,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li li > a {
	background-color: transparent;
	border: 0 none;
}
.header-layout-overlay #primary-menu.no-responsive li li.menu-item-current > a,
.header-layout-overlay #primary-menu.no-responsive li li.menu-item-active > a,
.header-layout-overlay #primary-menu.no-responsive li li:hover > a,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu li li.menu-item-current > a,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu li li.menu-item-active > a,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu li li:hover > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive li li.menu-item-current > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive li li.menu-item-active > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive li li:hover > a,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu li li.menu-item-current > a,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu li li.menu-item-active > a,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu li li:hover > a {
	border: 0 none;
}
.header-layout-overlay #primary-menu.no-responsive > li li > a:after,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li li > a:after,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li li > a:after,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu > li li > a:after {
	display: none;
}
.header-layout-overlay.logo-position-right #primary-menu.no-responsive li li.menu-item-current > a,
.header-layout-overlay.logo-position-right #primary-menu.no-responsive li li.menu-item-active > a,
.header-layout-overlay.logo-position-right #primary-menu.no-responsive li li:hover > a,
.mobile-menu-layout-overlay .logo-position-right #primary-navigation.responsive #primary-menu li li.menu-item-current > a,
.mobile-menu-layout-overlay .logo-position-right #primary-navigation.responsive #primary-menu li li.menu-item-active > a,
.mobile-menu-layout-overlay .logo-position-right #primary-navigation.responsive #primary-menu li li:hover > a,
.page-additional-menu.header-layout-overlay.logo-position-right .nav-menu.no-responsive li li.menu-item-current > a,
.page-additional-menu.header-layout-overlay.logo-position-right .nav-menu.no-responsive li li.menu-item-active > a,
.page-additional-menu.header-layout-overlay.logo-position-right .nav-menu.no-responsive li li:hover > a,
.page-additional-menu.mobile-menu-layout-overlay .logo-position-right .primary-navigation.responsive .nav-menu li li.menu-item-current > a,
.page-additional-menu.mobile-menu-layout-overlay .logo-position-right .primary-navigation.responsive .nav-menu li li.menu-item-active > a,
.page-additional-menu.mobile-menu-layout-overlay .logo-position-right .primary-navigation.responsive .nav-menu li li:hover > a {
	padding: 0;
}
.header-layout-overlay.logo-position-left #primary-menu.no-responsive li li.menu-item-current > a,
.header-layout-overlay.logo-position-left #primary-menu.no-responsive li li.menu-item-active > a,
.header-layout-overlay.logo-position-left #primary-menu.no-responsive li li:hover > a,
.mobile-menu-layout-overlay .logo-position-left #primary-navigation.responsive #primary-menu li li.menu-item-current > a,
.mobile-menu-layout-overlay .logo-position-left #primary-navigation.responsive #primary-menu li li.menu-item-active > a,
.mobile-menu-layout-overlay .logo-position-left #primary-navigation.responsive #primary-menu li li:hover > a,
.page-additional-menu.header-layout-overlay.logo-position-left .nav-menu.no-responsive li li.menu-item-current > a,
.page-additional-menu.header-layout-overlay.logo-position-left .nav-menu.no-responsive li li.menu-item-active > a,
.page-additional-menu.header-layout-overlay.logo-position-left .nav-menu.no-responsive li li:hover > a,
.page-additional-menu.mobile-menu-layout-overlay .logo-position-left .primary-navigation.responsive .nav-menu li li.menu-item-current > a,
.page-additional-menu.mobile-menu-layout-overlay .logo-position-left .primary-navigation.responsive .nav-menu li li.menu-item-active > a,
.page-additional-menu.mobile-menu-layout-overlay .logo-position-left .primary-navigation.responsive .nav-menu li li:hover > a {
	padding: 0;
}

.header-layout-overlay #primary-navigation #primary-menu.no-responsive li.menu-item-has-children > .menu-item-parent-toggle,
.page-additional-menu.header-layout-overlay #primary-navigation #primary-menu.no-responsive li.menu-item-has-children > .menu-item-parent-toggle {
	display: inline-block;
	vertical-align: top;
	position: relative;
}

.header-layout-overlay #primary-navigation #primary-menu.no-responsive li.menu-item-has-children > .menu-item-parent-toggle:before,
.page-additional-menu.header-layout-overlay #primary-navigation #primary-menu.no-responsive li.menu-item-has-children > .menu-item-parent-toggle:before {
	content: '\E635';
	display: inline-block;
	font-family: 'thegem-icons';
	cursor: pointer;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 49%;
	position: absolute;
	left: 5px;
	top: 0;
}

.header-layout-overlay #primary-navigation #primary-menu.no-responsive li.menu-overlay-item-open > .menu-item-parent-toggle:before,
.page-additional-menu.header-layout-overlay #primary-navigation #primary-menu.no-responsive li.menu-overlay-item-open > .menu-item-parent-toggle:before {
	-webkit-transform: rotate(-180deg);
	-ms-transform: rotate(-180deg);
	transform: rotate(-180deg);
}

.header-layout-overlay #primary-navigation .hamburger-minicart {
	-o-transition: opacity 0.5s;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}

.header-layout-overlay #primary-navigation.overlay-active:not(.close) .hamburger-minicart {
	opacity: 0;
}

.mobile-menu-layout-overlay .primary-navigation.responsive li.menu-item-parent > a:after,
.mobile-menu-layout-overlay .primary-navigation.responsive li.menu-item-parent > span > a:after {
	display: none;
}

.mobile-menu-layout-overlay .primary-navigation.responsive ul {
	border: 0;
}

.mobile-menu-layout-overlay #primary-navigation.responsive li a,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive li a {
	text-transform: uppercase;
}

.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu li,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu li {
	position: relative;
	width: auto !important;
}

.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu li.menu-item-has-children .menu-item-parent-toggle,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu li.menu-item-has-children .menu-item-parent-toggle {
	display: block;
	position: absolute;
	top: 0;
	right: -5px;
	text-align: center;
}

.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu li.menu-item-has-children .menu-item-parent-toggle:before,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu li.menu-item-has-children .menu-item-parent-toggle:before {
	content: '\E635';
	display: inline-block;
	font-family: 'thegem-icons';
	cursor: pointer;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	-webkit-transform-origin: 50% 50%;
	transform-origin: 51% 51%;
	margin-left: -1px;
	font-size: 24px;
	line-height: 48px;
}

.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu li.menu-overlay-item-open > .menu-item-parent-toggle:before,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu li.menu-overlay-item-open > .menu-item-parent-toggle:before {
	-webkit-transform: rotate(-180deg);
	-ms-transform: rotate(-180deg);
	transform: rotate(-180deg);
}

.menu-overlay {
	visibility: hidden;
	opacity: 0;
	background-color: #212331;
	position: fixed;
	z-index: 6;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}
.menu-overlay.active {
	visibility: visible;
	opacity: 0.96;
}

@media (max-width: 767px) {
	#site-header .header-main {
		position: relative;
		display: table;
		width: 100%;
		z-index: 11;
	}
	#primary-navigation .menu-toggle,
	#perspective-menu-buttons .menu-toggle {
		display: inline-block;
	}
	#perspective-menu-buttons .perspective-toggle {
		display: none;
	}
	#primary-navigation .hamburger-toggle,
	#primary-navigation .overlay-toggle {
		display: none;
	}
}
#site-header #primary-navigation.responsive {
	position: static;
}
#primary-navigation .menu-toggle,
#perspective-menu-buttons .menu-toggle,
#perspective-menu-buttons .perspective-toggle {
	border: none;
	width: 36px;
	height: 30px;
	text-indent: 100%;
	font-size: 0;
	line-height: 0;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	outline: none;
	background: transparent;
	padding: 0;
}
#perspective-menu-buttons .perspective-toggle {
	margin-top: 35px;
	margin-bottom: 35px;
	-o-transition: width .3s, height .2s;
	-webkit-transition: width .3s, height .2s;
	transition: width .3s, height .2s;
}
.mobile-menu-layout-overlay #primary-navigation.overlay-active .menu-toggle,
.mobile-menu-layout-overlay #perspective-menu-buttons.overlay-active .menu-toggle {
	z-index: 2;
}
#primary-navigation .menu-toggle .menu-line-1,
#primary-navigation .menu-toggle .menu-line-2,
#primary-navigation .menu-toggle .menu-line-3,
#perspective-menu-buttons .menu-toggle .menu-line-1,
#perspective-menu-buttons .menu-toggle .menu-line-2,
#perspective-menu-buttons .menu-toggle .menu-line-3,
#perspective-menu-buttons .perspective-toggle .menu-line-1,
#perspective-menu-buttons .perspective-toggle .menu-line-2,
#perspective-menu-buttons .perspective-toggle .menu-line-3 {
	position: absolute;
	height: 4px;
	width: 100%;
	left: 0;
	background: #3c3950;
	-webkit-transition: all 0.5s, top .3s, height .3s;
	transition: all 0.5s, top .3s, height .3s;
}
#site-header:not(.shrink) .header-colors-light #perspective-menu-buttons .menu-toggle .menu-line-1,
#site-header:not(.shrink) .header-colors-light #perspective-menu-buttons .menu-toggle .menu-line-2,
#site-header:not(.shrink) .header-colors-light #perspective-menu-buttons .menu-toggle .menu-line-3,
#site-header:not(.shrink) .header-colors-light #primary-navigation .menu-toggle > span {
	background: #ffffff;
}
#primary-navigation .menu-toggle .menu-line-1,
#perspective-menu-buttons .menu-toggle .menu-line-1,
#perspective-menu-buttons .perspective-toggle .menu-line-1 {
	top: 3px;
}
#primary-navigation .menu-toggle .menu-line-2,
#perspective-menu-buttons .menu-toggle .menu-line-2,
#perspective-menu-buttons .perspective-toggle .menu-line-2 {
	top: 13px;
}
#primary-navigation .menu-toggle .menu-line-3,
#perspective-menu-buttons .menu-toggle .menu-line-3,
#perspective-menu-buttons .perspective-toggle .menu-line-3 {
	top: 23px;
}

#primary-navigation .menu-toggle .menu-line-1,
#primary-navigation .menu-toggle .menu-line-2,
#primary-navigation .menu-toggle .menu-line-3,
#perspective-menu-buttons .menu-toggle .menu-line-1,
#perspective-menu-buttons .menu-toggle .menu-line-2,
#perspective-menu-buttons .menu-toggle .menu-line-3 {
	height: 2px;
	width: 18px;
	left: 9px;
}
#primary-navigation .menu-toggle .menu-line-1,
#perspective-menu-buttons .menu-toggle .menu-line-1 {
	top: 9px;
}
#primary-navigation .menu-toggle .menu-line-2,
#perspective-menu-buttons .menu-toggle .menu-line-2 {
	top: 14px;
}
#primary-navigation .menu-toggle .menu-line-3,
#perspective-menu-buttons .menu-toggle .menu-line-3 {
	top: 19px;
}
@media (max-width: 767px) {
	#perspective-menu-buttons .hamburger-minicart {
		display: none;
	}
}
.mobile-menu-layout-overlay .primary-navigation .menu-toggle,
.mobile-menu-layout-overlay .primary-navigation .menu-toggle .menu-line-1,
.mobile-menu-layout-overlay .primary-navigation .menu-toggle .menu-line-2,
.mobile-menu-layout-overlay .primary-navigation .menu-toggle .menu-line-3,
.mobile-menu-layout-overlay #perspective-menu-buttons .menu-toggle,
.mobile-menu-layout-overlay #perspective-menu-buttons .menu-toggle .menu-line-1,
.mobile-menu-layout-overlay #perspective-menu-buttons .menu-toggle .menu-line-2,
.mobile-menu-layout-overlay #perspective-menu-buttons .menu-toggle .menu-line-3 {
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}
.mobile-menu-layout-overlay #primary-navigation.overlay-active .menu-toggle .menu-line-1,
.mobile-menu-layout-overlay #perspective-menu-buttons.overlay-active .menu-toggle .menu-line-1 {
	transform: rotate(45deg);
	top: 13px;
}
.mobile-menu-layout-overlay #primary-navigation.overlay-active .menu-toggle .menu-line-2,
.mobile-menu-layout-overlay #perspective-menu-buttons.overlay-active .menu-toggle .menu-line-2 {
	opacity: 0;
}
.mobile-menu-layout-overlay #primary-navigation.overlay-active .menu-toggle .menu-line-3,
.mobile-menu-layout-overlay #perspective-menu-buttons.overlay-active .menu-toggle .menu-line-3 {
	top: 13px;
	transform: rotate(-45deg);
}
.mobile-menu-layout-overlay #primary-navigation.overlay-active.close .menu-toggle .menu-line-1,
.mobile-menu-layout-overlay #perspective-menu-buttons.overlay-active.close .menu-toggle .menu-line-1 {
	top: 3px;
	transform: rotate(0);
}
.mobile-menu-layout-overlay #primary-navigation.overlay-active.close .menu-toggle .menu-line-2,
.mobile-menu-layout-overlay #perspective-menu-buttons.overlay-active.close .menu-toggle .menu-line-2 {
	opacity: 1;
}
.mobile-menu-layout-overlay #primary-navigation.overlay-active.close .menu-toggle .menu-line-3,
.mobile-menu-layout-overlay #perspective-menu-buttons.overlay-active.close .menu-toggle .menu-line-3 {
	top: 23px;
	transform: rotate(0);
}
#perspective-menu-buttons .perspective-toggle.toggle-size-small .menu-line-1,
#perspective-menu-buttons .perspective-toggle.toggle-size-small .menu-line-2,
#perspective-menu-buttons .perspective-toggle.toggle-size-small .menu-line-3 {
	height: 2px;
	width: 18px;
	left: 9px;
}
#perspective-menu-buttons .perspective-toggle.toggle-size-small .menu-line-1 {
	top: 8px;
}
#perspective-menu-buttons .perspective-toggle.toggle-size-small .menu-line-2 {
	top: 13px;
}
#perspective-menu-buttons .perspective-toggle.toggle-size-small .menu-line-3 {
	top: 18px;
}
@media (max-width: 767px) {
	.mobile-menu-layout-overlay #primary-navigation.overlay-active.close .menu-toggle .menu-line-1,
	.mobile-menu-layout-overlay #perspective-menu-buttons.overlay-active.close .menu-toggle .menu-line-1 {
		top: 9px;
	}
	.mobile-menu-layout-overlay #primary-navigation.overlay-active.close .menu-toggle .menu-line-3,
	.mobile-menu-layout-overlay #perspective-menu-buttons.overlay-active.close .menu-toggle .menu-line-3 {
		top: 19px;
	}
}

/* Common styles of dl-menus */
#site-header .primary-navigation.responsive {
	padding: 15px 0;
	vertical-align: middle;
}
.mobile-menu-layout-default .primary-navigation.responsive ul,
.mobile-menu-layout-overlay .primary-navigation.responsive #primary-menu ul,
.page-additional-menu.mobile-menu-layout-overlay .primary-navigation.responsive .nav-menu ul {
	padding: 0 !important;
	background: none !important;
	list-style: none;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}
.mobile-menu-layout-default .primary-navigation.responsive li {
	position: relative;
	width: auto !important;
}
.mobile-menu-layout-default .primary-navigation.responsive li a {
	display: block;
	position: relative;
	padding: 16px 30px;
	outline: none;
}
.mobile-menu-layout-default .primary-navigation.responsive li .mega-label,
.mobile-menu-layout-slide-vertical .primary-navigation.responsive li .mega-label,
.mobile-menu-layout-slide-horizontal .primary-navigation.responsive li .mega-label {
	display: none;
}
.mobile-menu-layout-default .primary-navigation.responsive li.dl-back,
.mobile-menu-layout-default .primary-navigation.responsive li.dl-parent {
	text-align: right;
}
.mobile-menu-layout-default .primary-navigation.responsive li.dl-back > a,
.mobile-menu-layout-default .primary-navigation.responsive li.dl-parent > a {
	padding-left: 30px;
}
/*.primary-navigation li > a:not(:only-child):after,
.primary-navigation li > span:not(:only-child):after*/
.mobile-menu-layout-default .primary-navigation.responsive li.dl-back:after,
.mobile-menu-layout-default .primary-navigation.responsive li.menu-item-parent > a:after,
.mobile-menu-layout-default .primary-navigation.responsive li.menu-item-parent > span > a:after,
.mobile-menu-layout-default .primary-navigation.responsive li.dl-parent > a:after {
	position: absolute;
	width: 9px;
	height: 16px;
	right: 20px;
	top: 50%;
	margin-top: -8px;
	font-weight: normal;
	font-family: 'thegem-icons';
	content: '\E601';
	font-size: 16px;
	line-height: 16px;
}
.mobile-menu-layout-default .primary-navigation.responsive li.dl-back:after {
	right: auto;
	left: 20px;
	content: '\E603';
}
.mobile-menu-layout-default .primary-navigation.responsive li.dl-parent > a:after {
	right: auto;
	left: 20px;
}
.mobile-menu-layout-default .primary-navigation.responsive .dl-menu {
	margin: 0;
	position: absolute;
	left: 0;
	right: 0;
	opacity: 0;
	pointer-events: none;
	-webkit-transform: translateY(10px);
	transform: translateY(10px);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	text-align: left;
	top: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}
@media (max-width: 767px) {
	.mobile-menu-layout-default .primary-navigation .dl-menu {
		position: absolute;
		left: 0;
		right: 0;
		opacity: 0;
	}
	.mobile-menu-layout-overlay .header-main:not(.header-layout-overlay) #primary-navigation:not(.responsive) .overlay-menu-wrapper,
	.page-additional-menu.mobile-menu-layout-overlay:not(.header-layout-overlay) .primary-navigation:not(.responsive) .overlay-menu-wrapper {
		visibility: hidden;
		height: 0;
		width: 0;
	}
	.mobile-menu-layout-slide-horizontal #primary-navigation:not(.responsive) .mobile-menu-slide-wrapper,
	.mobile-menu-layout-slide-vertical #primary-navigation:not(.responsive) .mobile-menu-slide-wrapper {
		visibility: hidden;
		height: 0;
		width: 0;
	}
}
.mobile-menu-layout-default .primary-navigation.responsive .dl-menu.dl-menu-toggle {
	transition: all 0.3s ease;
}
.mobile-menu-layout-default .primary-navigation.responsive .dl-menu.dl-menuopen {
	opacity: 1;
	pointer-events: auto;
	-webkit-transform: translateY(0px);
	transform: translateY(0px);
}
/* Hide the inner submenus */
.mobile-menu-layout-default .primary-navigation.responsive li .dl-submenu {
	display: none;
}
/*
When a submenu is openend, we will hide all li siblings.
For that we give a class to the parent menu called "dl-subview".
We also hide the submenu link.
The opened submenu will get the class "dl-subviewopen".
All this is done for any sub-level being entered.
*/
.mobile-menu-layout-default .primary-navigation.responsive .dl-menu.dl-subview li,
.mobile-menu-layout-default .primary-navigation.responsive .dl-menu.dl-subview li.dl-subviewopen > a,
.mobile-menu-layout-default .primary-navigation.responsive .dl-menu.dl-subview li.dl-subview > a,
.mobile-menu-layout-default .primary-navigation.responsive .dl-menu.dl-subview li.dl-subviewopen > span,
.mobile-menu-layout-default .primary-navigation.responsive .dl-menu.dl-subview li.dl-subview > span {
	display: none;
}
.mobile-menu-layout-default .primary-navigation.responsive .dl-menu.dl-subview li.dl-subview,
.mobile-menu-layout-default .primary-navigation.responsive .dl-menu.dl-subview li.dl-subview .dl-submenu,
.mobile-menu-layout-default .primary-navigation.responsive .dl-menu.dl-subview li.dl-subviewopen,
.mobile-menu-layout-default .primary-navigation.responsive .dl-menu.dl-subview li.dl-subviewopen > .dl-submenu,
.mobile-menu-layout-default .primary-navigation.responsive .dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
	display: block;
}
/* Dynamically added submenu outside of the menu context */
.mobile-menu-layout-default .primary-navigation.responsive .dl-menu + .dl-submenu {
	position: absolute;
	width: 100% !important;
	left: 0;
	margin: 0;
	top: 100%;
	margin-top: -15px;
}
.mobile-menu-layout-default .primary-navigation.responsive .dl-menu + .dl-submenu:before {
	content: '';
	position: absolute;
	bottom: -3px;
	left: -3px;
	right: -3px;
	height: 3px;
}
/* Animation classes for moving out and in */
.dl-menu.dl-animate-out {
	-webkit-animation: MenuAnimOut 0.4s;
	animation: MenuAnimOut 0.4s;
}
@-webkit-keyframes MenuAnimOut {
	0% { }
	50% {
		-webkit-transform: translateZ(-250px) rotateY(30deg);
	}
	75% {
		-webkit-transform: translateZ(-372.5px) rotateY(15deg);
		opacity: .5;
	}
	100% {
		-webkit-transform: translateZ(-500px) rotateY(0deg);
		opacity: 0;
	}
}
@keyframes MenuAnimOut {
	0% { }
	50% {
		-webkit-transform: translateZ(-250px) rotateY(30deg);
		transform: translateZ(-250px) rotateY(30deg);
	}
	75% {
		-webkit-transform: translateZ(-372.5px) rotateY(15deg);
		transform: translateZ(-372.5px) rotateY(15deg);
		opacity: .5;
	}
	100% {
		-webkit-transform: translateZ(-500px) rotateY(0deg);
		transform: translateZ(-500px) rotateY(0deg);
		opacity: 0;
	}
}
.dl-menu.dl-animate-in {
	-webkit-animation: MenuAnimIn 0.3s;
	animation: MenuAnimIn 0.3s;
}
@-webkit-keyframes MenuAnimIn {
	0% {
		-webkit-transform: translateZ(-500px) rotateY(0deg);
		opacity: 0;
	}
	20% {
		-webkit-transform: translateZ(-250px) rotateY(30deg);
		opacity: 0.5;
	}
	100% {
		-webkit-transform: translateZ(0px) rotateY(0deg);
		opacity: 1;
	}
}
@keyframes MenuAnimIn {
	0% {
		-webkit-transform: translateZ(-500px) rotateY(0deg);
		transform: translateZ(-500px) rotateY(0deg);
		opacity: 0;
	}
	20% {
		-webkit-transform: translateZ(-250px) rotateY(30deg);
		transform: translateZ(-250px) rotateY(30deg);
		opacity: 0.5;
	}
	100% {
		-webkit-transform: translateZ(0px) rotateY(0deg);
		transform: translateZ(0px) rotateY(0deg);
		opacity: 1;
	}
}
.mobile-menu-layout-default .primary-navigation.responsive .dl-menu + .dl-submenu.dl-animate-in {
	-webkit-animation: SubMenuAnimIn 0.4s ease;
	animation: SubMenuAnimIn 0.4s ease;
}
@-webkit-keyframes SubMenuAnimIn {
	0% {
		-webkit-transform: translateX(50%);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0px);
		opacity: 1;
	}
}
@keyframes SubMenuAnimIn {
	0% {
		-webkit-transform: translateX(50%);
		transform: translateX(50%);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
		opacity: 1;
	}
}
.mobile-menu-layout-default .primary-navigation.responsive .dl-menu + .dl-submenu.dl-animate-out {
	-webkit-animation: SubMenuAnimOut 0.4s ease;
	animation: SubMenuAnimOut 0.4s ease;
}
@-webkit-keyframes SubMenuAnimOut {
	0% {
		-webkit-transform: translateX(0%);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(50%);
		opacity: 0;
	}
}
@keyframes SubMenuAnimOut {
	0% {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(50%);
		transform: translateX(50%);
		opacity: 0;
	}
}
/* No JS Fallback */
.no-js .primary-navigation .dl-menu {
	position: relative;
	opacity: 1;
	-webkit-transform: none;
	transform: none;
}
.no-js .primary-navigation li .dl-submenu {
	display: block;
}
.no-js .primary-navigation li.dl-back {
	display: none;
}
.no-js .primary-navigation li > a:not(:only-child):after {
	content: '';
}

#primary-navigation .hamburger-toggle > span,
#primary-navigation .overlay-toggle > span,
#primary-navigation.overlay-active.close .overlay-toggle > span,
#perspective-menu-buttons.primary-navigation .perspective-toggle span,
#page.vertical-header .vertical-toggle > span,
#thegem-perspective #primary-navigation .perspective-menu-close:before,
#thegem-perspective #primary-navigation .perspective-menu-close:after {
	background-color: #3c3950;
}
.hamburger-minicart > a,
.hamburger-minicart:hover > a {
	color: #3c3950;
}
#site-header:not(.shrink) .header-colors-light #primary-navigation .hamburger-toggle > span,
#site-header:not(.shrink) .header-colors-light #primary-navigation:not(.overlay-active) .overlay-toggle > span,
#site-header:not(.shrink) .header-colors-light #primary-navigation.overlay-active.close .overlay-toggle > span,
#site-header:not(.shrink) .header-colors-light #perspective-menu-buttons.primary-navigation .perspective-toggle span {
	background-color: #ffffff;
}
#site-header:not(.shrink) .header-colors-light .hamburger-minicart > a,
#site-header:not(.shrink) .header-colors-light .hamburger-minicart:hover > a {
	color: #ffffff;
}

/* FOOTER NAV */

#footer-nav {
	padding: 40px 0;
	border-top: 1px solid #313646;
}
#footer-navigation {
	margin-bottom: 0;
}
#footer-menu {
	display: inline-block;
	margin: 0 -21px;
}
#footer-menu li {
	display: inline-block;
	padding: 0 21px;
	position: relative;
	line-height: 1;
}
#footer-menu li + li:before {
	content: '';
	width: 0;
	height: 100%;
	position: absolute;
	left: -1px;
	top: 0;
	font-size: 0;
	border-left: 1px solid #232234;
	border-right: 1px solid #333146;
}
#footer-menu li a {
	font-size: 92.86%;
	display: block;
}

#footer-socials {
	text-align: right;
}
#footer-socials .socials {
	text-align: right;
	margin: 0 -7px;
	display: inline-block;
	vertical-align: middle;
}
.footer-socials-item {
	display: inline-block;
	vertical-align: top;
	padding: 0 7px;
}
.footer-socials-item a {
	display: block;
	position: relative;
	font-size: 0;
	width: 20px;
	line-height: inherit;
}
.footer-socials-item a:after {
	position: absolute;
	font-family: 'thegem-socials';
	display: block;
	font-size: 16px;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	text-align: center;
	transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-webkit-transition: all 0.2s linear;
}
.footer-socials-item.twitter a:after {
	content: '\E603';
}
.footer-socials-item.facebook a:after {
	content: '\E601';
}
.footer-socials-item.linkedin a:after {
	content: '\E604';
}
.footer-socials-item.googleplus a:after {
	content: '\E602';
}
.footer-socials-item.stumbleupon a:after {
	content: '\E606';
}
.footer-socials-item.rss a:after {
	content: '\E608';
}
@media (max-width: 991px) {
	#footer-nav #footer-socials {
		text-align: center;
	}
	#footer-nav .footer-site-info {
		text-align: center;
	}
	#footer-navigation #footer-menu li {
		margin: 5px 0;
	}
}

/* FOOTER WIDGET AREA */

#colophon {
	position: relative;
	padding: 110px 0 45px;
	background-position: 50% 0;
	background-size: cover;
	background-repeat: no-repeat;
}

.footer-widget-area {
	margin-bottom: 1.421em;
}

/* FOOTER HTML */

.footer-site-info {
	font-size: 92.86%;
}

.footer-site-info a:hover {
	text-decoration: underline;
}

/* BLOCKS */

.block-content {
	padding: 135px 0 100px;
}
.block-content:last-of-type {
	padding-bottom: 110px;
}
.no-bottom-margin:last-of-type {
	padding-bottom: 0;
}
.page-title + .no-top-margin,
#page-title + .no-top-margin,
.gem-slideshow + .no-top-margin,
.no-top-margin:first-child {
	padding-top: 0;
}
.block-content + .block-content {
	padding-top: 0;
}


/* SHORTCODES */

/* Fullwidth */
.fullwidth-block {
	position: relative;
}
.fullwidth-top-marker,
.fullwidth-bottom-marker {
	position: absolute;
	width: 140px;
	height: 70px;
	left: 50%;
	margin-left: -70px;
	z-index: 10;
}
.fullwidth-top-marker.marker-direction-inside {
	top: 0;
}
.fullwidth-top-marker.marker-direction-outside {
	top: -69px;
}
.fullwidth-bottom-marker.marker-direction-inside {
	bottom: 0;
}
.fullwidth-bottom-marker.marker-direction-outside {
	bottom: -69px;
}


/* Styled Image, Video */

.gem-youtube .gem-wrapbox-inner,
.gem-youtube .shadow-wrap,
.gem-youtube .gem-wrapbox-element,
.gem-vimeo .gem-wrapbox-inner,
.gem-vimeo .shadow-wrap,
.gem-vimeo .gem-wrapbox-element,
.gem-video .gem-wrapbox-inner,
.gem-video .shadow-wrap,
.gem-video .gem-wrapbox-element {
	width: 100%;
	height: 100%;
}
.gem-wrapbox-inner.gem-ratio-style .gem-wrapbox-element,
.gem-wrapbox-inner.gem-ratio-style .shadow-wrap,
.gem-wrapbox-inner.gem-ratio-style .preloader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.gem-image-centered-box {
	margin-bottom: 1.421em;
}
.gem-image a,
.gem-textbox-picture a{
	position: relative;
	display: inline-block;
	vertical-align: top;
	overflow: hidden;
}

.gem-image a:before,
.gem-textbox-picture a:before{
	content: '';
	position: absolute;
	top: -100%;
	left: 0;
	right: 0;
	bottom: 0;
	-webkit-transition: opacity 0.5s, background-color 0.5s;
	transition: opacity 0.5s, background-color 0.5s;
	opacity: 0;
	z-index: 5;
}
.gem-image a:hover:before,
.gem-textbox-picture a:hover:before {
	opacity: 1;
	-webkit-transition: opacity 0.5s, background-color 0.5s;
	transition: opacity 0.5s, background-color 0.5s;
}
.gem-image a:after,
.gem-textbox-picture a:after{
	content: '\E60F';
	font-family: 'thegem-icons';
	font-weight: normal;
	font-style: normal;
	font-size: 40px;
	height: 40px;
	line-height: 40px;
	width: 40px;
	text-align: center;
	position: absolute;
	top: -20px;
	left: 50%;
	transition: opacity 0.3s, top 0.3s;
	-webkit-transition: opacity 0.3s, top 0.3s;
	margin-left: -20px;
	margin-top: -20px;
	z-index: 5;
	opacity: 0;
}
.gem-image a:hover:after,
.gem-textbox-picture a:hover:after{
	top: 50%;
	opacity: 1;
	transition: opacity 0.5s, top 0.3s;
	-webkit-transition: opacity 0.5s, top 0.3s;
}

.gem-map-with-text .wpb_gmaps_widget .wpb_wrapper {
	padding: 0;
}
.gem-gmaps {
	display: inline-block;
	max-width: 100%;
}
.gem-gmaps .gem-gmaps-hide {
	overflow: hidden;
	width: 100%;
}
.gem-gmaps iframe {
	border: 0 none;
	vertical-align: top;
	margin-top: -54px;
	width: 100%;
}
.gem-gmaps .preloader + iframe {
	margin-top: -240px !important;
}
.gem-gmaps .preloader + iframe + .map-locker {
	opacity: 0;
}
.gem-gmaps.gem-wrapbox-style-11 {
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	overflow: hidden;
}
.gem-gmaps.gem-wrapbox-style-11 .gem-gmaps-hide {
	-webkit-mask-image: url('https://cms.concrad.com/wp-content/themes/thegem/css/circle.svg');
}
iframe + .map-locker {
	position: relative;
	float: right;
	margin-top: -150px;
	margin-right: 13px;
	height: 50px;
	width: 50px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	background: #ffffff url('https://cms.concrad.com/wp-content/themes/thegem/images/map-locker.png') no-repeat 0 50%;
}
iframe + .map-locker.disabled {
	background-position: 100% 50%;
}

.wpb_gmaps_widget .wpb_map_wraper.hide_title  iframe {
	margin-top: -46px;
}
.wpb_gmaps_widget  .wpb_map_wraper.hide_title {
	overflow: hidden;
}

.gem-gmaps.gem-wrapbox-style-11 .gem-wrapbox-inner:after {
	display: none;
}
.gem-gmaps.gem-wrapbox-style-11 .gem-wrapbox-inner  {
	padding: 12px;
	border: 2px solid;
}
.gem-gmaps.gem-wrapbox-style-8  .gem-wrapbox-inner {
	border-radius: 34px;
	overflow: hidden;
}

/* Text Box */
.gem-textbox {
	margin-bottom: 1.421em;
	position: relative;
}
.gem-textbox-title,
.gem-textbox-content {
	padding: 30px;
}
.gem-textbox-content * {
	position: relative;
	z-index: 1;
}
.gem-textbox-content  .gem-textbox-background-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
}
.gem-textbox-title .gem-textbox-title-icon + .gem-textbox-title-text {
	margin-top: 30px;
}
.gem-textbox-picture img {
	display: inline-block;
	vertical-align: top;
}
.gem-textbox-inner {
	overflow: hidden;
}
.gem-textbox-top,
.gem-textbox-bottom {
	font-size: 0;
	line-height: 0;
	text-align: center;
}
.gem-textbox-top svg,
.gem-textbox-bottom svg {
	vertical-align: top;
}
.gem-textbox-after-image {
	border-top: 0 none !important;
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
}
.gem-textbox-before-image {
	border-bottom: 0 none !important;
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

/* Team */
.gem-team {
	margin-bottom: 1.421em;
}
.team-person {
	padding: 50px 30px;
	position: relative;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}
.team-person-link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.team-person a:not(.team-person-link) {
	position: relative;
	z-index: 2;
}
.team-person-image {
	display: inline-block;
	width: 240px;
	max-width: 100%;
	vertical-align: top;
	margin-bottom: 45px;
}
.team-person-image a,
.team-person-image span {
	display: inline-block;
	vertical-align: top;
	overflow: hidden;
	max-width: 100%;
	position: relative;
}
.team-person-image a:before,
.team-person-image span:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	opacity: 0;
}
.team-person-image a:hover:before,
.team-person:hover .team-person-image span:before {
	opacity: 1;
}
.team-person-image a,
.gem-team .team-person-image a:before,
.team-person-image span,
.gem-team .team-person-image span:before,
.team-person-image img {
	border-radius: 50%;
}
.team-person-info {
	margin: 0 0 20px;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}
.team-person-name {
	margin: 0 0 5px;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}
.team-person-position {
	margin: 0 0 28px;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}
.team-person .team-person-phone {
	color: inherit;
	margin: 0 0 7px;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}
.team-person .team-person-phone a {
	color: inherit;
}
.team-person .team-person-description {
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}
.team-person-socials .socials-item-icon {
	font-size: 32px;
}
.team-person-socials {
	margin: 0 -10px;
}
.team-person-socials .socials-item {
	margin: 20px 10px 0;
}
.team-person-email a:before {
	font-family: 'thegem-icons';
	font-weight: normal;
	content: '\E607';
	font-size: 16px;
	margin-right: 10px;
	color: inherit;
	vertical-align: top;
}
.gem-team-style-1 .team-person {
	border: 1px solid transparent;
}
.gem-team-style-2 .team-person {
	padding: 50px 50px;
	border-radius: 0 50px 50px 50px;
}
.gem-team-style-2 .team-person-box-columns .team-person-box-left {
	float: left;
	margin-right: -270px;
}
.gem-team-style-2 .team-person-box-columns .team-person-box-left + .team-person-box-right {
	float: left;
	width: 100%;
	padding-left: 270px;
}
.gem-team-style-2 .team-person-image {
	position: relative;
}
.gem-team-style-2 .team-person-image a,
.gem-team-style-2 .team-person-image a:before,
.gem-team-style-2 .team-person-image span,
.gem-team-style-2 .team-person-image span:before,
.gem-team-style-2 .team-person-image img {
	border-radius: 0;
}
.gem-team-style-2 .team-person-name {
	margin-top: -10px;
}
.gem-team-style-2 .team-person-socials .socials-item-icon {
	font-size: 20px;
}
.gem-team-style-2 .team-person-socials {
	margin: 0 -6px;
}
.gem-team-style-2 .team-person-socials .socials-item {
	margin: 12px 6px 0;
}
@media (max-width: 1211px) {
	.gem-team-style-2 .col-sm-6 .team-person-box-columns .team-person-box-left {
		float: none;
		margin-right: 0;
	}
	.gem-team-style-2 .col-sm-6 .team-person-box-columns .team-person-box-left + .team-person-box-right {
		float: none;
		padding-left: 0;
	}
}
@media (min-width: 1212px) {
	.gem-team-style-2 .col-sm-6 .team-person-box-columns .team-person-box-left .team-person-image {
		margin-bottom: 0;
	}
}
@media (max-width: 639px) {
	.gem-team-style-2 .team-person-box-columns .team-person-box-left {
		float: none;
		margin-right: 0;
	}
	.gem-team-style-2 .team-person-box-columns .team-person-box-left + .team-person-box-right {
		float: none;
		padding-left: 0;
	}
}

.gem-team-style-3 .team-person {
	border-radius: 180px 180px 0 0;
}
.gem-team-style-3 .team-person-image {
	padding-top: 15px;
	padding-left: 35px;
	padding-right: 35px;
	width: 310px;
}
.gem-team-style-3 .team-person-info {
	margin: 0 0 30px;
}
.gem-team-style-3 .team-person-name {
	margin: 0;
}
.gem-team-style-3 .team-person-position {
	font-size: 0.875em;
}
.gem-team-style-3 .team-person-socials .socials-item-icon {
	font-size: 20px;
}
.gem-team-style-3 .team-person-socials {
	margin: 0 -6px;
}
.gem-team-style-3 .team-person-socials .socials-item {
	margin: 12px 6px 0;
}
.gem-team-style-3 .team-person-email a:before {
	font-size: 32px;
	margin-right: 0;
}

.gem-team-style-4 .team-person {
	border-bottom: 6px solid #a3e7f0;
	padding-bottom: 10px;
	padding-left: 40px;
}
.gem-team-style-4 .team-person-image {
	width: 128px;
	margin-bottom: 30px;
}
.gem-team-style-4 .team-person-info {
	margin: 0;
}
.gem-team-style-4 .team-person-name {
	margin: 0 0 20px;
}
.gem-team-style-4 .team-person-position {
	font-size: 0.875em;
	margin-bottom: 3px;
}
.gem-team-style-4 .team-person-phone {
	margin: 0 0 2px;
}
.gem-team-style-4 .team-person-socials .socials-item-icon {
	font-size: 20px;
}
.gem-team-style-4 .team-person-socials {
	float: left;
	margin-left: -32px;
	margin-right: 0;
	padding-left: 26px;
	width: 100%;
	min-width: 240px;
	margin-bottom: 40px;
}
.gem-team-style-4 .team-person-socials .socials-item {
	margin: 12px 6px 0;
}
.gem-team-style-4 .team-person-email {
	float: left;
	margin-bottom: 40px;
	margin-top: 5px;
}
.gem-team-style-4 .team-person.centered-box .team-person-socials {
	float: none;
	padding-left: 0;
	margin-left: 0;
}
.gem-team-style-4 .team-person.centered-box .team-person-email {
	float: none;
}
.gem-team-style-4 .team-person-email a:before {
	font-size: 32px;
	margin-right: 0;
}

.gem-team-style-5 .inline-column {
	padding-left: 0;
	padding-right: 0;
}
.gem-team-style-5 .team-person,
.gem-team-style-5 .team-person-hover {
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}
.gem-team-style-5 .team-person {
	padding: 3px 0;
}
.gem-team-style-5 .team-person-image a,
.gem-team-style-5 .team-person-image span {
	overflow: visible;
}
.gem-team-style-5 .team-person-image a:before,
.gem-team-style-5 .team-person-image span:before {
	opacity: 0;
	left: 6px;
	right: 6px;
	top: 6px;
	bottom: 6px;
	width: auto;
	height: auto;
	background-color: rgba(70, 72, 92, 0.7);
}
.gem-team-style-5 .team-person-image a:hover:before,
.gem-team-style-5 .team-person:hover .team-person-image span:before {
	opacity: 1;
}
.gem-team-style-5 .team-person-image {
	padding-left: 40px;
	padding-right: 40px;
	width: 320px;
}
.gem-team-style-5 .team-person-image .image-hover {
	display: inline-block;
	vertical-align: middle;
	border-radius: 50%;
	padding: 6px;
	border: 3px solid #dce1e4;
	margin: -9px;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	max-width: 100%;
}
.gem-team-style-5 .team-person:hover .image-hover {
	border-color: #00bcd4;
	background-color: #00bcd4;
}
.gem-team-style-5 .team-person-hover {
	padding: 47px 30px;
}
.gem-team-style-5 .team-person:hover {
	margin-top: -23px;
	margin-bottom: -23px;
}
.gem-team-style-5 .team-person:hover .team-person-hover {
	padding-top: 70px;
	padding-bottom: 70px;
}
.gem-team-style-5 .team-person-socials .socials-item-icon {
	font-size: 25px;
}
.gem-team-style-5 .team-person-socials .socials-item {
	margin: 12px 6px 0;
}

.gem-team-style-6 .team-person {
	border: 1px solid transparent;
	padding: 0;
}
.gem-team-style-6 .team-person-hover {
	padding: 43px 34px;
}
.gem-team-style-6 .team-person,
.gem-team-style-6 .team-person-hover {
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}
.gem-team-style-6 .team-person-box-left {
	float: left;
	margin-right: -100px;
}
.gem-team-style-6 .team-person-box-left + .team-person-box-right {
	float: left;
	width: 100%;
	padding-left: 100px;
}
.gem-team-style-6 .team-person-image {
	position: relative;
	width: 80px;
}
.gem-team-style-6 .team-person-socials .socials-item-icon {
	font-size: 20px;
}
.gem-team-style-6 .team-person-socials {
	margin: 0 -6px;
}
.gem-team-style-6 .team-person-socials .socials-item {
	margin: 12px 6px 0;
}
.gem-team-style-6 .team-person-position,
.gem-team-style-6 .team-person-email {
	font-size: 0.875em;
}
.gem-team-style-6 .team-person-position,
.gem-team-style-6 .team-person-phone,
.gem-team-style-6 .team-person-info {
	margin: 0;
}

/* Shapes for TTA
 * ========================== */
.wpb-js-composer vc_tta.vc_general .vc_tta-panel-heading .vc_tta-panel-title {
	line-height: 1.2;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-tabs .vc_tta-panel-body {
	box-sizing: border-box;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-square .vc_tta-panel-body {
	min-height: 0px;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-square .vc_tta-panel-body,
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-square .vc_tta-panel-heading {
	border-radius: 0px;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-square .vc_tta-tabs-container {
	margin: 0px;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-square .vc_tta-tab > a {
	border-radius: 0px;
}
.wpb-js-composer .vc_non_responsive .vc_tta.vc_general.vc_tta-shape-square.vc_tta-tabs .vc_tta-panels,
.wpb-js-composer .vc_tta-o-non-responsive .vc_tta.vc_general.vc_tta-shape-square.vc_tta-tabs .vc_tta-panels {
	border-radius: 0px;
}
@media (min-width: 768px) {
	.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-square.vc_tta-tabs .vc_tta-panels {
		border-radius: 0px;
	}
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-square.vc_tta-o-no-fill .vc_tta-panel-body {
	border-radius: 0;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-square.vc_tta-o-shape-group.vc_tta-o-no-fill .vc_tta-panel.vc_active + .vc_tta-panel:not(:first-child):not(:last-child) .vc_tta-panel-heading,
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-square.vc_tta-o-shape-group.vc_tta-o-no-fill .vc_tta-panel.vc_active + .vc_tta-panel:last-child:not(:first-child) .vc_tta-panel-heading {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-rounded .vc_tta-panel-body {
	min-height: 6px;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-rounded .vc_tta-panel-body,
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-rounded .vc_tta-panel-heading {
	border-radius: 3px;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-rounded .vc_tta-tabs-container {
	margin: 3px;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-rounded .vc_tta-tab > a {
	border-radius: 3px;
}
.wpb-js-composer .vc_non_responsive .vc_tta.vc_general.vc_tta-shape-rounded.vc_tta-tabs .vc_tta-panels,
.wpb-js-composer .vc_tta-o-non-responsive .vc_tta.vc_general.vc_tta-shape-rounded.vc_tta-tabs .vc_tta-panels {
	border-radius: 3px;
}
@media (min-width: 768px) {
	.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-rounded.vc_tta-tabs .vc_tta-panels {
		border-radius: 3px;
	}
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-rounded.vc_tta-o-no-fill .vc_tta-panel-body {
	border-radius: 0;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-rounded.vc_tta-o-shape-group.vc_tta-o-no-fill .vc_tta-panel.vc_active + .vc_tta-panel:not(:first-child):not(:last-child) .vc_tta-panel-heading,
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-rounded.vc_tta-o-shape-group.vc_tta-o-no-fill .vc_tta-panel.vc_active + .vc_tta-panel:last-child:not(:first-child) .vc_tta-panel-heading {
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-round .vc_tta-panel-body {
	min-height: 36px;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-round .vc_tta-panel-body,
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-round .vc_tta-panel-heading {
	border-radius: 18px;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-round .vc_tta-tabs-container {
	margin: 18px;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-round .vc_tta-tab > a {
	border-radius: 18px;
}
.wpb-js-composer .vc_non_responsive .vc_tta.vc_general.vc_tta-shape-round.vc_tta-tabs .vc_tta-panels,
.wpb-js-composer .vc_tta-o-non-responsive .vc_tta.vc_general.vc_tta-shape-round.vc_tta-tabs .vc_tta-panels {
	border-radius: 18px;
}
@media (min-width: 768px) {
	.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-round.vc_tta-tabs .vc_tta-panels {
		border-radius: 18px;
	}
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-round.vc_tta-o-no-fill .vc_tta-panel-body {
	border-radius: 0;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-round.vc_tta-o-shape-group.vc_tta-o-no-fill .vc_tta-panel.vc_active + .vc_tta-panel:not(:first-child):not(:last-child) .vc_tta-panel-heading,
.wpb-js-composer .vc_tta.vc_general.vc_tta-shape-round.vc_tta-o-shape-group.vc_tta-o-no-fill .vc_tta-panel.vc_active + .vc_tta-panel:last-child:not(:first-child) .vc_tta-panel-heading {
	border-top-left-radius: 18px;
	border-top-right-radius: 18px;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-pageable .vc_tta-panel-body {
	border: 0 none;
}
/* Adaptation for Shapes
 * ========================== */
.wpb-js-composer .vc_tta-shape-rounded:not(.vc_tta-o-no-fill) .vc_tta-panel.vc_active .vc_tta-panel-heading {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.wpb-js-composer .vc_tta-shape-rounded:not(.vc_tta-o-no-fill) .vc_tta-panel.vc_active .vc_tta-panel-body {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.wpb-js-composer .vc_non_responsive .vc_tta-shape-rounded.vc_tta-tabs .vc_tta-panel-body::before,
.wpb-js-composer .vc_tta-o-non-responsive .vc_tta-shape-rounded.vc_tta-tabs .vc_tta-panel-body::before,
.wpb-js-composer .vc_non_responsive .vc_tta-shape-rounded.vc_tta-tabs .vc_tta-panel-body::after,
.wpb-js-composer .vc_tta-o-non-responsive .vc_tta-shape-rounded.vc_tta-tabs .vc_tta-panel-body::after {
	box-sizing: border-box;
	content: '';
	display: none;
	position: absolute;
	width: 3px;
	height: 3px;
	border-radius: 3px;
	border-style: inherit;
	border-width: inherit;
}
@media (min-width: 768px) {
	.wpb-js-composer .vc_tta-shape-rounded.vc_tta-tabs .vc_tta-panel-body::before,
	.wpb-js-composer .vc_tta-shape-rounded.vc_tta-tabs .vc_tta-panel-body::after {
		box-sizing: border-box;
		content: '';
		display: none;
		position: absolute;
		width: 3px;
		height: 3px;
		border-radius: 3px;
		border-style: inherit;
		border-width: inherit;
	}
}
.wpb-js-composer .vc_tta-shape-rounded.vc_tta-o-shape-group:not(.vc_tta-o-no-fill) .vc_tta-panel:not(:first-child):not(:last-child) .vc_tta-panel-heading {
	border-radius: 0;
}
.wpb-js-composer .vc_tta-shape-rounded.vc_tta-o-shape-group:not(.vc_tta-o-no-fill) .vc_tta-panel:not(:first-child):not(:last-child) .vc_tta-panel-body {
	border-radius: 0;
}
.wpb-js-composer .vc_tta-shape-rounded.vc_tta-o-shape-group:not(.vc_tta-o-no-fill) .vc_tta-panel:first-child:not(:last-child) .vc_tta-panel-heading,
.wpb-js-composer .vc_tta-shape-rounded.vc_tta-o-shape-group:not(.vc_tta-o-no-fill) .vc_tta-panel:first-child:not(:last-child) .vc_tta-panel-body {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.wpb-js-composer .vc_tta-shape-rounded.vc_tta-o-shape-group:not(.vc_tta-o-no-fill) .vc_tta-panel:last-child:not(:first-child) .vc_tta-panel-heading,
.wpb-js-composer .vc_tta-shape-rounded.vc_tta-o-shape-group:not(.vc_tta-o-no-fill) .vc_tta-panel:last-child:not(:first-child) .vc_tta-panel-body {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.wpb-js-composer .vc_tta-shape-rounded.vc_tta-o-shape-group.vc_tta-o-no-fill .vc_tta-panel:not(:first-child):not(:last-child):not(.vc_active) .vc_tta-panel-heading,
.wpb-js-composer .vc_tta-shape-rounded.vc_tta-o-shape-group.vc_tta-o-no-fill .vc_tta-panel:not(:first-child):not(:last-child):not(.vc_active) .vc_tta-panel-body {
	border-radius: 0;
}
.wpb-js-composer .vc_tta-shape-rounded.vc_tta-o-shape-group.vc_tta-o-no-fill .vc_tta-panel:not(:first-child):not(:last-child).vc_active .vc_tta-panel-heading {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.wpb-js-composer .vc_tta-shape-rounded.vc_tta-o-shape-group.vc_tta-o-no-fill .vc_tta-panel:first-child:not(:last-child):not(.vc_active) .vc_tta-panel-heading,
.wpb-js-composer .vc_tta-shape-rounded.vc_tta-o-shape-group.vc_tta-o-no-fill .vc_tta-panel:first-child:not(:last-child):not(.vc_active) .vc_tta-panel-body {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.wpb-js-composer .vc_tta-shape-rounded.vc_tta-o-shape-group.vc_tta-o-no-fill .vc_tta-panel:last-child:not(:first-child) .vc_tta-panel-heading,
.wpb-js-composer .vc_tta-shape-rounded.vc_tta-o-shape-group.vc_tta-o-no-fill .vc_tta-panel:last-child:not(:first-child) .vc_tta-panel-body {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.wpb-js-composer .vc_tta-shape-round:not(.vc_tta-o-no-fill) .vc_tta-panel.vc_active .vc_tta-panel-heading {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.wpb-js-composer .vc_tta-shape-round:not(.vc_tta-o-no-fill) .vc_tta-panel.vc_active .vc_tta-panel-body {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.wpb-js-composer .vc_non_responsive .vc_tta-shape-round.vc_tta-tabs .vc_tta-panel-body::before,
.wpb-js-composer .vc_tta-o-non-responsive .vc_tta-shape-round.vc_tta-tabs .vc_tta-panel-body::before,
.wpb-js-composer .vc_non_responsive .vc_tta-shape-round.vc_tta-tabs .vc_tta-panel-body::after,
.wpb-js-composer .vc_tta-o-non-responsive .vc_tta-shape-round.vc_tta-tabs .vc_tta-panel-body::after {
	box-sizing: border-box;
	content: '';
	display: none;
	position: absolute;
	width: 18px;
	height: 18px;
	border-radius: 18px;
	border-style: inherit;
	border-width: inherit;
}
@media (min-width: 768px) {
	.wpb-js-composer .vc_tta-shape-round.vc_tta-tabs .vc_tta-panel-body::before,
	.wpb-js-composer .vc_tta-shape-round.vc_tta-tabs .vc_tta-panel-body::after {
		box-sizing: border-box;
		content: '';
		display: none;
		position: absolute;
		width: 18px;
		height: 18px;
		border-radius: 18px;
		border-style: inherit;
		border-width: inherit;
	}
	.wpb-js-composer .vc_tta.vc_tta-shape-round.vc_tta-tabs .vc_tta-panel-body::before,
	.wpb-js-composer .vc_tta.vc_tta-shape-round.vc_tta-tabs .vc_tta-panel-body::after {
		width: 18px;
		height: 18px;
		border-radius: 18px;
	}
}
.wpb-js-composer .vc_tta-shape-round.vc_tta-o-shape-group:not(.vc_tta-o-no-fill) .vc_tta-panel:not(:first-child):not(:last-child) .vc_tta-panel-heading {
	border-radius: 0;
}
.wpb-js-composer .vc_tta-shape-round.vc_tta-o-shape-group:not(.vc_tta-o-no-fill) .vc_tta-panel:not(:first-child):not(:last-child) .vc_tta-panel-body {
	border-radius: 0;
}
.wpb-js-composer .vc_tta-shape-round.vc_tta-o-shape-group:not(.vc_tta-o-no-fill) .vc_tta-panel:first-child:not(:last-child) .vc_tta-panel-heading,
.wpb-js-composer .vc_tta-shape-round.vc_tta-o-shape-group:not(.vc_tta-o-no-fill) .vc_tta-panel:first-child:not(:last-child) .vc_tta-panel-body {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.wpb-js-composer .vc_tta-shape-round.vc_tta-o-shape-group:not(.vc_tta-o-no-fill) .vc_tta-panel:last-child:not(:first-child) .vc_tta-panel-heading,
.wpb-js-composer .vc_tta-shape-round.vc_tta-o-shape-group:not(.vc_tta-o-no-fill) .vc_tta-panel:last-child:not(:first-child) .vc_tta-panel-body {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.wpb-js-composer .vc_tta-shape-round.vc_tta-o-shape-group.vc_tta-o-no-fill .vc_tta-panel:not(:first-child):not(:last-child):not(.vc_active) .vc_tta-panel-heading,
.wpb-js-composer .vc_tta-shape-round.vc_tta-o-shape-group.vc_tta-o-no-fill .vc_tta-panel:not(:first-child):not(:last-child):not(.vc_active) .vc_tta-panel-body {
	border-radius: 0;
}
.wpb-js-composer .vc_tta-shape-round.vc_tta-o-shape-group.vc_tta-o-no-fill .vc_tta-panel:not(:first-child):not(:last-child).vc_active .vc_tta-panel-heading {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.wpb-js-composer .vc_tta-shape-round.vc_tta-o-shape-group.vc_tta-o-no-fill .vc_tta-panel:first-child:not(:last-child):not(.vc_active) .vc_tta-panel-heading,
.wpb-js-composer .vc_tta-shape-round.vc_tta-o-shape-group.vc_tta-o-no-fill .vc_tta-panel:first-child:not(:last-child):not(.vc_active) .vc_tta-panel-body {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.wpb-js-composer .vc_tta-shape-round.vc_tta-o-shape-group.vc_tta-o-no-fill .vc_tta-panel:last-child:not(:first-child) .vc_tta-panel-heading,
.wpb-js-composer .vc_tta-shape-round.vc_tta-o-shape-group.vc_tta-o-no-fill .vc_tta-panel:last-child:not(:first-child) .vc_tta-panel-body {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.wpb-js-composer .vc_general.vc_tta-tabs.vc_tta-tabs-position-left .vc_tta-tabs-container,
.wpb-js-composer .vc_general.vc_tta-tabs.vc_tta-tabs-position-right .vc_tta-tabs-container {
	margin-top: 0;
}

.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-tabs-position-top .vc_tta-panel-body::before,
.wpb-js-composer .vc_tta.vc_non_responsive .vc_tta-tabs.vc_tta-tabs-position-top .vc_tta-panel-body::before,
.wpb-js-composer .vc_tta.vc_tta-o-non-responsive .vc_tta-tabs.vc_tta-tabs-position-top .vc_tta-panel-body::before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-right-width: 0;
  border-bottom-width: 0;
}
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-tabs-position-top .vc_tta-panel-body::after,
.wpb-js-composer .vc_tta.vc_non_responsive .vc_tta-tabs.vc_tta-tabs-position-top .vc_tta-panel-body::after,
.wpb-js-composer .vc_tta.vc_tta-o-non-responsive .vc_tta-tabs.vc_tta-tabs-position-top .vc_tta-panel-body::after {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-left-width: 0;
  border-bottom-width: 0;
}
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-tabs-position-bottom .vc_tta-panel-body::before,
.wpb-js-composer .vc_tta.vc_non_responsive .vc_tta-tabs.vc_tta-tabs-position-bottom .vc_tta-panel-body::before,
.wpb-js-composer .vc_tta.vc_tta-o-non-responsive .vc_tta-tabs.vc_tta-tabs-position-bottom .vc_tta-panel-body::before {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-right-width: 0;
  border-top-width: 0;
}
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-tabs-position-bottom .vc_tta-panel-body::after,
.wpb-js-composer .vc_tta.vc_non_responsive .vc_tta-tabs.vc_tta-tabs-position-bottom .vc_tta-panel-body::after,
.wpb-js-composer .vc_tta.vc_tta-o-non-responsive .vc_tta-tabs.vc_tta-tabs-position-bottom .vc_tta-panel-body::after {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0;
  border-top-width: 0;
}
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-tabs-position-left .vc_tta-panel-body::before,
.wpb-js-composer .vc_tta.vc_non_responsive .vc_tta-tabs.vc_tta-tabs-position-left .vc_tta-panel-body::before,
.wpb-js-composer .vc_tta.vc_tta-o-non-responsive .vc_tta-tabs.vc_tta-tabs-position-left .vc_tta-panel-body::before {
  right: auto;
  bottom: auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-right-width: 0;
  border-bottom-width: 0;
}
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-tabs-position-left .vc_tta-panel-body::after,
.wpb-js-composer .vc_tta.vc_non_responsive .vc_tta-tabs.vc_tta-tabs-position-left .vc_tta-panel-body::after,
.wpb-js-composer .vc_tta.vc_tta-o-non-responsive .vc_tta-tabs.vc_tta-tabs-position-left .vc_tta-panel-body::after {
  right: auto;
  top: auto;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;
  border-top-width: 0;
}
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-tabs-position-right .vc_tta-panel-body::before,
.wpb-js-composer .vc_tta.vc_non_responsive .vc_tta-tabs.vc_tta-tabs-position-right .vc_tta-panel-body::before,
.wpb-js-composer .vc_tta.vc_tta-o-non-responsive .vc_tta-tabs.vc_tta-tabs-position-right .vc_tta-panel-body::before {
  left: auto;
  bottom: auto;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-left-width: 0;
  border-bottom-width: 0;
}
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-tabs-position-right .vc_tta-panel-body::after,
.wpb-js-composer .vc_tta.vc_non_responsive .vc_tta-tabs.vc_tta-tabs-position-right .vc_tta-panel-body::after,
.wpb-js-composer .vc_tta.vc_tta-o-non-responsive .vc_tta-tabs.vc_tta-tabs-position-right .vc_tta-panel-body::after {
  left: auto;
  top: auto;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0;
  border-top-width: 0;
}

/* TABS */
.wpb-js-composer .vc_tta.vc_general.vc_tta-tabs .vc_tta-panel-body {
	overflow: visible;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-tabs .vc_tta-title-text:not(:empty):not(:first-child),
.wpb-js-composer .vc_tta.vc_general.vc_tta-tabs .vc_tta-title-text:not(:empty) ~ *,
.wpb-js-composer .vc_tta.vc_general.vc_tta-accordion .vc_tta-title-text:not(:empty):not(:first-child),
.wpb-js-composer .vc_tta.vc_general.vc_tta-accordion .vc_tta-title-text:not(:empty) ~ * {
	margin-left: 10px;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-tabs .vc_tta-title-text:not(:empty) ~ .vc_tta-controls-icon,
.wpb-js-composer .vc_tta.vc_general.vc_tta-accordion .vc_tta-title-text:not(:empty) ~ .vc_tta-controls-icon {
	margin-left: 0;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-icon.gem-print-icon {
	display: inline-block;
	vertical-align: middle;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-icon.gem-print-icon.gem-icon-pack-elegant {
	font-size: 16px;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-icon.gem-print-icon.gem-icon-pack-material {
	font-size: 24px;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-icon.gem-print-icon.gem-icon-pack-fontawesome {
	font-size: 14px;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-icon.gem-print-icon.gem-icon-pack-userpack {
	font-size: 16px;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-title-text {
	display: inline-block;
	vertical-align: middle;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-tab {
	vertical-align: bottom;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-tabs-position-bottom .vc_tta-tab {
	vertical-align: top;
}
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel .vc_tta-panel-title {
	font-family: 'Montserrat UltraLight';
	font-weight: normal;
	text-transform: uppercase;
	font-size: 14px;
}
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel.vc_active .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel.vc_active .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-title
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel.vc_tta-style-modern .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-modern .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_dashed .vc_tta-panel .vc_tta-panel-title {
	font-family: 'Montserrat';
	font-weight: bold;
}
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_general .vc_tta-tab > a {
	padding: 12px 25px;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-tabs .vc_tta-panel-body {
	padding: 50px;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-color-thegem .vc_tta-panel-heading .vc_tta-panel-title > a {
	padding-top: 16px;
	padding-bottom: 16px;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel-heading .vc_tta-panel-title > a {
	padding-top: 17px;
	padding-bottom: 17px;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-panel-heading .vc_tta-panel-title > a {
	padding-top: 16px;
	padding-bottom: 16px;
}
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-tab a {
	font-family: 'Montserrat UltraLight';
	font-size: 14px;
	text-transform: uppercase;
	font-weight: normal;
}
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-tab.vc_active > a,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-outline .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-modern .vc_tta-tab a {
	font-family: 'Montserrat';
	font-weight: bold;
}
/* Widget */
.wpb-js-composer .widget .vc_tta.vc_tta-tabs.vc_tta-color-thegem .vc_tta-tab a,
.wpb-js-composer .widget .vc_tta.vc_tta-tabs.vc_tta-color-thegem .vc_tta-tab.vc_active > a,
.wpb-js-composer .widget .vc_tta.vc_tta-tabs.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-tab a,
.wpb-js-composer .widget .vc_tta.vc_tta-tabs.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-tab a {
	font-family: inherit;
	font-weight: inherit;
	text-transform: none;
}
.wpb-js-composer .widget .vc_tta.vc_tta-tabs.vc_tta-color-thegem.vc_general .vc_tta-tab > a {
	padding: 2px 23px;
}
.wpb-js-composer .widget .vc_tta.vc_general.vc_tta-color-thegem.vc_tta-tabs .vc_tta-panel-body {
	padding: 25px 40px;
}
.wpb-js-composer .widget .vc_tta.vc_general.vc_tta-color-thegem .vc_tta-panel-heading .vc_tta-panel-title > a {
	padding-top: 13px;
	padding-bottom: 13px;
}
.wpb-js-composer .widget .vc_tta.vc_general.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel-heading .vc_tta-panel-title > a {
	padding-top: 12px;
	padding-bottom: 12px;
}
.wpb-js-composer .widget .vc_tta.vc_general.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-panel-heading .vc_tta-panel-title > a {
	padding-top: 11px;
	padding-bottom: 11px;
}

/* Classic */
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel.vc_active .vc_tta-panel-heading {
	margin-bottom: -1px;
}
.wpb-js-composer .widget .vc_tta.vc_tta-tabs.vc_tta-color-thegem.vc_general.vc_tta-style-classic:not([class*="vc_tta-gap"]) .vc_tta-tab.vc_active > a {
	padding: 4px 23px;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-heading {
	border-color: #b6c6c9;
	background-color: #f4f6f7;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-heading:hover,
.vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-heading:focus {
	background-color: #f4f6f7;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-title > a {
	color: #5f727f;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel.vc_active .vc_tta-panel-heading {
	border-color: #b6c6c9;
	background-color: #ffffff;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel.vc_active .vc_tta-panel-title > a {
	color: #5f727f;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-body {
	background-color: #ffffff;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-body,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-body::before,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-body::after {
	border-color: #b6c6c9;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-controls-icon::before,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-controls-icon::after {
	border-color: #b6c6c9;
	color: #b6c6c9;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::before,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::after {
	border-color: #b6c6c9;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-tab > a {
	border-color: #b6c6c9;
	background-color: #f4f6f7;
	color: #5f727f;
}
.wpb-js-composer .widget .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-tab > a {
	color: #00bcd4;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-tab > a:hover,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-tab > a:focus {
	background-color: #f4f6f7;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-tab.vc_active > a {
	border-color: #b6c6c9;
	background-color: #ffffff;
	color: #5f727f;
}
.wpb-js-composer .widget .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-tab.vc_active > a {
	color: #5f727f;
}
@media (min-width: 768px) {
	.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic.vc_tta-tabs .vc_tta-panels {
		background-color: #ffffff;
	}
	.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic.vc_tta-tabs .vc_tta-panels,
	.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic.vc_tta-tabs .vc_tta-panels::before,
	.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic.vc_tta-tabs .vc_tta-panels::after {
		border-color: #b6c6c9;
	}
	.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-classic.vc_tta-tabs .vc_tta-panels .vc_tta-panel-body {
		border-color: transparent;
		background-color: transparent;
	}
}
/* Modern */
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-panel.vc_active .vc_tta-panel-heading {
	margin-bottom: -1px;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-tabs:not([class*="vc_tta-gap"]):not(.vc_tta-o-no-fill).vc_tta-tabs-position-top.vc_tta-style-modern .vc_tta-tabs-container {
	margin-bottom: 0;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-tabs:not([class*="vc_tta-gap"]):not(.vc_tta-o-no-fill).vc_tta-tabs-position-bottom.vc_tta-style-modern .vc_tta-tabs-container {
	margin-top: 0;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-tabs:not([class*="vc_tta-gap"]):not(.vc_tta-o-no-fill).vc_tta-tabs-position-left.vc_tta-style-modern .vc_tta-tabs-container {
	margin-right: 0;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-tabs:not([class*="vc_tta-gap"]):not(.vc_tta-o-no-fill).vc_tta-tabs-position-right.vc_tta-style-modern .vc_tta-tabs-container {
	margin-left: 0;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-panel-body,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-panel-heading,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-tab > a {
	border-width: 0;
	background-image: none;
	filter: none;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-panel .vc_tta-panel-heading {
	border-color: transparent;
	background-color: #99a9b5;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-panel .vc_tta-panel-heading:hover,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-panel .vc_tta-panel-heading:focus {
	background-color: #99a9b5;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-panel .vc_tta-panel-title > a {
	color: #dfe5e8;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-panel.vc_active .vc_tta-panel-heading {
	border-color: transparent;
	background-color: #3c3950;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-panel.vc_active .vc_tta-panel-title > a {
	color: #ffffff;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-panel .vc_tta-panel-body {
	background-color: #3c3950;
	color: #99a9b5;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-panel .vc_tta-panel-body,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-panel .vc_tta-panel-body::before,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-panel .vc_tta-panel-body::after {
	border-color: transparent;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-controls-icon::before,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-controls-icon::after {
	border-color: #dfe5e8;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::before,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::after {
	border-color: #ffffff;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-tab > a {
	border-color: transparent;
	background-color: #99a9b5;
	color: #dfe5e8;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-tab > a:hover,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-tab > a:focus {
	background-color: #99a9b5;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern .vc_tta-tab.vc_active > a {
	border-color: transparent;
	background-color: #3c3950;
	color: #ffffff;
}
@media (min-width: 768px) {
	.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern.vc_tta-tabs .vc_tta-panels {
		background-color: #3c3950;
	}
	.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern.vc_tta-tabs .vc_tta-panels,
	.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern.vc_tta-tabs .vc_tta-panels::before,
	.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern.vc_tta-tabs .vc_tta-panels::after {
		border-color: transparent;
	}
	.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-modern.vc_tta-tabs .vc_tta-panels .vc_tta-panel-body {
		border-color: transparent;
		background-color: transparent;
	}
}
/* Flat */
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat .vc_tta-panel.vc_active .vc_tta-panel-heading {
	margin-bottom: -1px;
}
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-color-thegem.vc_tta-style-flat .vc_tta-tab > a {
	padding-top: 10px;
	padding-bottom: 10px;
}
.wpb-js-composer .widget .vc_tta.vc_tta-tabs.vc_tta-color-thegem.vc_tta-style-flat .vc_tta-tab > a {
	padding-top: 0;
	padding-bottom: 0;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat .vc_tta-panel .vc_tta-panel-heading {
	border-color: transparent;
	background-color: #dfe5e8;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat .vc_tta-panel .vc_tta-panel-heading:hover,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat .vc_tta-panel .vc_tta-panel-heading:focus {
	background-color: #dfe5e8;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat .vc_tta-panel .vc_tta-panel-title > a {
	color: #5f727f;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat .vc_tta-panel.vc_active .vc_tta-panel-heading {
	border-color: transparent;
	background-color: #f4f6f7;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat .vc_tta-panel.vc_active .vc_tta-panel-title > a {
	color: #5f727f;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat .vc_tta-panel .vc_tta-panel-body {
	background-color: #f4f6f7;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat .vc_tta-panel .vc_tta-panel-body,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat .vc_tta-panel .vc_tta-panel-body::before,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat .vc_tta-panel .vc_tta-panel-body::after {
	border-color: transparent;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat .vc_tta-controls-icon::before,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat .vc_tta-controls-icon::after {
	border-color: #5f727f;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::before,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::after {
	border-color: #5f727f;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat .vc_tta-tab > a {
	border-color: transparent;
	background-color: #dfe5e8;
	color: #5f727f;
}
.wpb-js-composer .widget .vc_tta.vc_tta-color-thegem.vc_tta-style-flat .vc_tta-tab > a {
	color: #99a9b5;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat .vc_tta-tab > a:hover,
.vc_tta.vc_tta-color-thegem.vc_tta-style-flat .vc_tta-tab > a:focus {
	background-color: #dfe5e8;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat .vc_tta-tab.vc_active > a {
	border-color: transparent;
	background-color: #f4f6f7;
	color: #5f727f;
}
@media (min-width: 768px) {
	.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat.vc_tta-tabs .vc_tta-panels {
		background-color: #f4f6f7;
	}
	.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat.vc_tta-tabs .vc_tta-panels,
	.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat.vc_tta-tabs .vc_tta-panels::before,
	.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat.vc_tta-tabs .vc_tta-panels::after {
		border-color: transparent;
	}
	.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-flat.vc_tta-tabs .vc_tta-panels .vc_tta-panel-body {
		border-color: transparent;
		background-color: transparent;
	}
}
/* Outline */
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-panel.vc_active .vc_tta-panel-heading {
	margin-bottom: -2px;
}
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-tab > a {
	padding-top: 11px;
	padding-bottom: 11px;
}
.wpb-js-composer .widget .vc_tta.vc_tta-tabs.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-tab > a {
	padding-top: 1px;
	padding-bottom: 1px;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-heading {
	border-color: #99a9b5;
	background-color: #99a9b5;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-heading:hover,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-heading:focus {
	background-color: #99a9b5;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-title > a {
	color: #ffffff;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-panel.vc_active .vc_tta-panel-heading {
	border-color: #99a9b5;
	background-color: #ffffff;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-panel.vc_active .vc_tta-panel-title > a {
	color: #99a9b5;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-body {
	background-color: #ffffff;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-body,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-body::before,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-body::after {
	border-color: #99a9b5;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-controls-icon::before,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-controls-icon::after {
	border-color: #ffffff;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::before,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::after {
	border-color: #99a9b5;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-tab > a {
	border-color: #99a9b5;
	background-color: #99a9b5;
	color: #ffffff;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-tab > a:hover,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-tab > a:focus {
	background-color: #99a9b5;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline .vc_tta-tab.vc_active > a {
	border-color: #99a9b5;
	background-color: #ffffff;
	color: #99a9b5;
}
@media (min-width: 768px) {
	.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline.vc_tta-tabs .vc_tta-panels {
		background-color: #ffffff;
	}
	.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline.vc_tta-tabs .vc_tta-panels,
	.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline.vc_tta-tabs .vc_tta-panels::before,
	.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline.vc_tta-tabs .vc_tta-panels::after {
		border-color: #99a9b5;
	}
	.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-outline.vc_tta-tabs .vc_tta-panels .vc_tta-panel-body {
		border-color: transparent;
		background-color: transparent;
	}
}
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-o-no-fill.vc_tta-color-thegem .vc_tta-panels,
.wpb-js-composer .vc_tta.vc_tta-o-no-fill.vc_tta-color-thegem .vc_tta-panels .vc_tta-panel-body {
		border-color: transparent;
		background-color: transparent;
}

/* Gallery */
.gem-gallery {
	margin-bottom: 1.421em;
}
.gem-gallery-preview-carousel-wrap,
.gem-gallery-thumbs-carousel-wrap {
	position: relative;
}
.gem-gallery.no-thumbs .gem-gallery-thumbs-carousel-wrap {
	display: none;
}
.gem-gallery-preview-pagination {
	padding-top: 25px;
}
.gem-gallery .gem-gallery-item {
	float: left;
	position: relative;
	text-align: center;
}
.gem-gallery .gem-gallery-item a {
	display: inline-block;
	vertical-align: top;
}
.gem-gallery .gem-gallery-preview-carousel-wrap {
	overflow: hidden;
}
.gem-gallery .gem-gallery-thumbs-carousel-wrap {
	padding: 0 80px;
}
.gem-gallery .gem-gallery-thumbs-navigation .gem-prev,
.gem-gallery .gem-gallery-thumbs-navigation .gem-next {
	width: 80px;
}
.gem-gallery .gem-gallery-thumbs-navigation .gem-prev:after,
.gem-gallery .gem-gallery-thumbs-navigation .gem-next:after {
	width: 80px;
	height: 80px;
	line-height: 80px;
	margin-top: -40px;
	margin-left: -40px;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.gem-gallery .gem-gallery-thumbs-navigation .gem-prev:not(:hover):after,
.gem-gallery .gem-gallery-thumbs-navigation .gem-next:not(:hover):after {
	background-color: #dfe5e8;
}
.gem-gallery .gem-gallery-thumbs-navigation .gem-prev:after {
	content: '\E637';
}
.gem-gallery .gem-gallery-thumbs-navigation .gem-next:after {
	content: '\E638';
}
.gem-gallery .gem-gallery-thumbs-carousel-wrap .gem-gallery-item {
	padding: 18px 0 0;
	margin: 0 9px;
}
.gem-gallery .gem-gallery-thumbs-carousel-wrap .gem-gallery-item a {
	position: relative;
}
.gem-gallery .gem-gallery-thumbs-carousel-wrap .gem-gallery-item a:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.gem-gallery .gem-gallery-thumbs-carousel-wrap .gem-gallery-item.active a:before,
.gem-gallery .gem-gallery-thumbs-carousel-wrap .gem-gallery-item a:hover:before {
	opacity: 0.7;
}
.gem-gallery .gem-gallery-thumbs-carousel-wrap .gem-gallery-item a img {
	width: 80px;
	height: 80px;
}
.gem-gallery .gem-gallery-thumbs-carousel-wrap .gem-gallery-item.active a {
	position: relative;
	overflow: visible;
}
.gem-gallery .gem-gallery-thumbs-carousel-wrap .gem-gallery-item.active a img {
	opacity: 1;
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
}
.gem-gallery .gem-gallery-preview-carousel-wrap .gem-gallery-item svg {
	display: none;
}
.gem-gallery .gem-gallery-thumbs-carousel-wrap .gem-gallery-item svg {
	position: absolute;
	height: 10px;
	top: -10px;
	left: 50%;
	margin-left: -10px;
	width: 20px;
	opacity: 0;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.gem-gallery .gem-gallery-thumbs-carousel-wrap .gem-gallery-item.active svg {
	opacity: 1;
}
.gem-gallery .gem-gallery-thumbs-carousel-wrap .gem-prev,
.gem-gallery .gem-gallery-thumbs-carousel-wrap .gem-next {
	top: 18px;
}
.gem-gallery-thumbs-carousel-wrap .gem-gallery-caption {
	display: none;
}
.gem-gallery .gem-gallery-preview-carousel-wrap .gem-gallery-item a {
	display: block;
	position: relative;
	overflow: hidden;
	text-align: center;
}
.gem-gallery .gem-gallery-preview-carousel-wrap .gem-gallery-item img {
	display: inline-block;
	height: auto;
}
.gem-gallery .gem-gallery-preview-carousel-wrap .gem-gallery-item a:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: all 0.3s linear 0.4s;
	-moz-transition: all 0.3s linear 0.4s;
	-webkit-transition: all 0.3s linear 0.4s;
	opacity: 0;
	z-index: 5;
}
.gem-gallery .gem-gallery-preview-carousel-wrap:hover .gem-gallery-item a:before {
	opacity: 1;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
}
.gem-gallery .gem-gallery-preview-carousel-wrap .gem-gallery-item a img {
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
}
.gem-gallery .gem-gallery-preview-carousel-wrap:hover .gem-gallery-item a img {
	transition: all 0.3s linear 0.2s;
	-moz-transition: all 0.3s linear 0.2s;
	-webkit-transition: all 0.3s linear 0.2s;
}
.gem-gallery .gem-gallery-preview-carousel-wrap:hover .gem-prev,
.gem-gallery .gem-gallery-preview-carousel-wrap:hover .gem-next {
	z-index: 5;
}
.gem-gallery .gem-gallery-preview-carousel-wrap .gem-prev:after,
.gem-gallery .gem-gallery-preview-carousel-wrap .gem-next:after {
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	opacity: 0;
}
.gem-gallery .gem-gallery-preview-carousel-wrap .gem-prev:after {
	transform: translateX(-100%);
	-o-transform: translateX(-100%);
	-ms-transform: translateX(-100%);
	-moz-transform: translateX(-100%);
	-webkit-transform: translateX(-100%);
}
.gem-gallery .gem-gallery-preview-carousel-wrap .gem-next:after {
	transform: translateX(100%);
	-o-transform: translateX(100%);
	-ms-transform: translateX(100%);
	-moz-transform: translateX(100%);
	-webkit-transform: translateX(100%);
}
.gem-gallery .gem-gallery-preview-carousel-wrap:hover .gem-prev:after,
.gem-gallery .gem-gallery-preview-carousel-wrap:hover .gem-next:after {
	transform: none;
	-o-transform: none;
	-ms-transform: none;
	-moz-transform: none;
	-webkit-transform: none;
	opacity: 1;
}

@media (max-width: 756px) {
	.gem-gallery .gem-gallery-thumbs-carousel-wrap {
		padding: 0 25px;
	}
	.gem-gallery .gem-gallery-thumbs-navigation .gem-prev,
	.gem-gallery .gem-gallery-thumbs-navigation .gem-next {
		width: 25px;
	}
	.gem-gallery .gem-gallery-thumbs-navigation .gem-prev:not(:hover):after,
	.gem-gallery .gem-gallery-thumbs-navigation .gem-next:not(:hover):after {
		background: transparent;
	}
	.gem-gallery .gem-gallery-thumbs-navigation .gem-prev:after {
		content: '\E636';
	}
	.gem-gallery .gem-gallery-thumbs-navigation .gem-next:after {
		content: '\E634';
	}
	.gem-gallery .gem-gallery-thumbs-carousel-wrap .gem-gallery-item {
		margin: 0 7px;
	}
}

body .fancybox-title-over-wrap {
	width: 100%;
}

.gem-simple-gallery .gem-gallery-items-carousel-wrap {
	position: relative;
}
.gem-simple-gallery .gem-gallery-item {
	float: left;
	position: relative;
	text-align: center;
	margin: 0 21px;
	overflow: hidden;
	max-width: 100%;
}
.gem-simple-gallery.responsive .gem-gallery-item {
	margin: 0;
}
.widget .gem-simple-gallery .gem-gallery-item {
	width: 261px;
}
.gem-simple-gallery .gem-gallery-item a {
	display: inline-block;
	vertical-align: top;
	max-width: 100%;
}
.gem-simple-gallery .gem-gallery-items-carousel-wrap {
	overflow: hidden;
}
.gem-simple-gallery .gem-gallery-thumbs-carousel-wrap .gem-gallery-item a {
	position: relative;
}
.gem-simple-gallery .gem-gallery-item a:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.gem-simple-gallery .gem-gallery-item a:hover:before {
	opacity: 0.7;
}
.gem-simple-gallery .gem-gallery-caption {
	display: none;
}
.gem-simple-gallery .gem-gallery-item img {
	display: inline-block;
	height: auto;
}
.gem-simple-gallery .gem-gallery-item a:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: all 0.3s linear 0.4s;
	-moz-transition: all 0.3s linear 0.4s;
	-webkit-transition: all 0.3s linear 0.4s;
	opacity: 0;
	z-index: 5;
}
.gem-simple-gallery .gem-gallery-item a:hover:before {
	opacity: 1;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
}
.gem-simple-gallery .gem-gallery-item a img {
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
}
.gem-simple-gallery .gem-gallery-item a:hover img {
	transition: all 0.3s linear 0.2s;
	-moz-transition: all 0.3s linear 0.2s;
	-webkit-transition: all 0.3s linear 0.2s;
}
.gem-simple-gallery .gem-gallery-item a:after {
	content: '\E60F';
	font-family: 'thegem-icons';
	font-weight: normal;
	font-style: normal;
	font-size: 63px;
	height: 63px;
	line-height: 63px;
	width: 63px;
	text-align: center;
	position: absolute;
	top: 0%;
	left: 50%;
	transition: all 0.2s ease 0.1s;
	-moz-transition: all 0.2s ease 0.1s;
	-webkit-transition: all 0.2s ease 0.1s;
	margin-left: -32px;
	margin-top: -32px;
	z-index: 5;
	opacity: 0;
}
.gem-simple-gallery .gem-gallery-item a:hover:after {
	transition: all 0.2s ease 0.1s;
	-moz-transition: all 0.2s ease 0.1s;
	-webkit-transition: all 0.2s ease 0.1s;
	top: 50%;
	opacity: 1;
}
.gem-simple-gallery .gem-prev,
.gem-simple-gallery .gem-next {
	z-index: 5;
}


/* Icons with title */

.gem-icon-with-title {
	margin-bottom: 1.421em;
}
.gem-icon-with-title > .gem-icon-with-title-icon {
	display: table-cell;
	padding-right: 10px;
	vertical-align: middle;
}
.gem-icon-with-title-icon-size-medium > .gem-icon-with-title-icon {
	padding-right: 20px;
}
.gem-icon-with-title-icon-size-big > .gem-icon-with-title-icon {
	padding-right: 30px;
}
.gem-iconed-title {
	display: table-cell;
	vertical-align: middle;
	text-transform: uppercase;
	width: 99%;
}
.gem-iconed-title > * {
	margin: 0;
}

/* Icons with text */

.gem-icon-with-text {
	margin-bottom: 1.421em;
}
.gem-icon-with-text > .gem-icon-with-text-icon {
	display: table-cell;
	padding-right: 10px;
	vertical-align: top;
}
.gem-icon-with-text-icon-size-medium > .gem-icon-with-text-icon {
	padding-right: 20px;
}
.gem-icon-with-text-icon-size-big > .gem-icon-with-text-icon {
	padding-right: 30px;
}
.gem-icon-with-text.gem-icon-with-text-flow > .gem-icon-with-text-icon {
	display: block;
	float: left;
	margin: 0 10px 0 0;
	padding-right: 0;
}
.gem-icon-with-text-icon-size-medium.gem-icon-with-text-flow > .gem-icon-with-text-icon {
	margin: 0 20px 0 0;
}
.gem-icon-with-text-icon-size-big.gem-icon-with-text-flow > .gem-icon-with-text-icon {
	margin: 0 30px 0 0;
}
.gem-icon-with-text > .gem-icon-with-text-content {
	display: table-cell;
	width: 99%;
	vertical-align: top;
}
.gem-icon-with-text.gem-icon-with-text-flow > .gem-icon-with-text-content {
	display: block;
	width: auto;
}
.gem-icon-with-text.centered-box > .gem-icon-with-title {
	display: block;
}
.gem-icon-with-text.centered-box > .gem-icon-with-text-icon,
.gem-icon-with-text.centered-box > .gem-icon-with-title > .gem-icon-with-title-icon {
	display: block;
	text-align: center;
	padding: 0 0 10px;
}
.gem-icon-with-text.centered-box > .gem-icon-with-title > .gem-iconed-title {
	display: block;
}
.gem-icon-with-text-icon-size-medium.centered-box > .gem-icon-with-text-icon,
.gem-icon-with-text-icon-size-medium.centered-box > .gem-icon-with-title > .gem-icon-with-title-icon {
	display: block;
	text-align: center;
	padding: 0 0 20px;
}
.gem-icon-with-text-icon-size-big.centered-box > .gem-icon-with-text-icon,
.gem-icon-with-text-icon-size-big.centered-box > .gem-icon-with-title > .gem-icon-with-title-icon {
	display: block;
	text-align: center;
	padding: 0 0 30px;
}
.gem-icon-with-text.gem-icon-with-text-flow.centered-box > .gem-icon-with-text-icon {
	float: none;
	margin: 0;
}
.gem-icon-with-text.centered-box > .gem-icon-with-text-content {
	display: block;
	width: auto;
}
.gem-icon-with-text > .gem-icon-with-title,
.gem-icon-with-text > .gem-icon-with-title + .gem-icon-with-text-content {
	display: table-row;
}
.gem-icon-with-text > .gem-icon-with-title + .gem-icon-with-text-content > .gem-icon-with-text-empty,
.gem-icon-with-text > .gem-icon-with-title + .gem-icon-with-text-content > .gem-icon-with-text-text {
	display: table-cell;
}
@media (max-width: 768px) {
	.gem-icon-with-text:not(.disable-mobile-centered) > .gem-icon-with-text-icon {
		display: block;
		text-align: center;
		padding: 0 0 10px;
		padding-right: 0 !important;
	}
	.gem-icon-with-text-icon-size-medium:not(.disable-mobile-centered) > .gem-icon-with-text-icon {
		display: block;
		text-align: center;
		padding: 0 0 20px;
	}
	.gem-icon-with-text-icon-size-big:not(.disable-mobile-centered) > .gem-icon-with-text-icon {
		display: block;
		text-align: center;
		padding: 0 0 30px;
	}
	.gem-icon-with-text.gem-icon-with-text-flow:not(.disable-mobile-centered) > .gem-icon-with-text-icon {
		float: none;
		margin: 0;
	}
	.gem-icon-with-text:not(.disable-mobile-centered) > .gem-icon-with-text-content,
	.gem-icon-with-text.centered-box > .gem-icon-with-title + .gem-icon-with-text-content {
		display: block;
		width: auto;
		text-align: center;
	}
	.gem-icon-with-text:not(.disable-mobile-centered) > .gem-icon-with-title + .gem-icon-with-text-content {
		text-align: left;
	}
}

/* Alert box */
.gem-alert-box:not(.centered-box) {
	display: table;
	width: 100%;
}
.gem-textbox-content .gem-alert-box {
	padding: 20px;
}
.gem-alert-box:not(.centered-box) .gem-alert-box-picture,
.gem-alert-box:not(.centered-box) .gem-alert-box-content,
.gem-alert-box:not(.centered-box) .gem-alert-box-buttons {
	display: table-cell;
	vertical-align: middle;
	width: 1%;
}
.gem-alert-box:not(.centered-box) .gem-alert-box-content {
	width: 99%;
	padding: 0 42px;
}
.gem-alert-box:not(.centered-box) .gem-alert-box-buttons .gem-button {
	margin: 15px 0;
}
.gem-alert-box-image {
	overflow: hidden;
	display: inline-block;
	vertical-align: top;
}
.gem-alert-box-image.image-size-small,
.gem-alert-box-image.image-size-small img,
.gem-alert-box-image.image-size-medium,
.gem-alert-box-image.image-size-medium img {
	border-radius: 3px;
}
.gem-alert-box-image.image-size-large,
.gem-alert-box-image.image-size-large img {
	border-radius: 6px;
}
.gem-alert-box-image.image-size-xlarge,
.gem-alert-box-image.image-size-xlarge img {
	border-radius: 9px;
}
.gem-alert-box-image.image-shape-circle,
.gem-alert-box-image.image-shape-circle img {
	border-radius: 50%;
}
.gem-alert-box-image.image-size-small {
	width: 50px;
}
.gem-alert-box-image.image-size-small.image-shape-circle,
.gem-alert-box-image.image-size-small.image-shape-circle img {
	height: 50px;
}
.gem-alert-box-image.image-size-medium {
	width: 80px;
}
.gem-alert-box-image.image-size-medium.image-shape-circle,
.gem-alert-box-image.image-size-medium.image-shape-circle img {
	height: 80px;
}
.gem-alert-box-image.image-size-large {
	width: 160px;
}
.gem-alert-box-image.image-size-large.image-shape-circle,
.gem-alert-box-image.image-size-large.image-shape-circle img {
	height: 160px;
}
.gem-alert-box-image.image-size-xlarge {
	width: 240px;
}
.gem-alert-box-image.image-size-xlarge.image-shape-circle,
.gem-alert-box-image.image-size-xlarge.image-shape-circle img {
	height: 240px;
}
.gem-alert-box.centered-box .gem-alert-box-content {
	padding: 40px 0 20px;
}
.gem-alert-box.centered-box .gem-alert-box-buttons .gem-button {
	margin: 20px 21px 0;
}

@media (max-width: 768px) {
	.gem-alert-box:not(.centered-box) {
		display: block;
		text-align: center;
	}
	.gem-alert-box:not(.centered-box) .gem-alert-box-picture,
	.gem-alert-box:not(.centered-box) .gem-alert-box-content,
	.gem-alert-box:not(.centered-box) .gem-alert-box-buttons {
		display: block;
		width: 100%;
	}
	.gem-alert-box:not(.centered-box) .gem-alert-box-content {
		width: 100%;
		padding: 40px 0 20px;
	}
	.gem-alert-box:not(.centered-box) .gem-alert-box-buttons .gem-button-container {
		display: inline-block;
	}
	.gem-alert-box:not(.centered-box) .gem-alert-box-buttons .gem-button {
		margin: 20px 21px 0;
	}
}

/* Button with separator */

.gem-button-separator-holder,
.gem-button-separator-button {
	display: table-cell;
	vertical-align: middle;
}
.gem-button-separator-holder {
	width: 50%;
}
.gem-button-separator-holder svg {
	display: block;
}
.gem-button-sep-button .gem-button {
	margin: 20px 0;
	vertical-align: middle;
}
.gem-button-separator-line {
	width: 100%;
}
.gem-button-separator-type-single .gem-button-separator-line {
	border-top: 2px solid;
	border-bottom: 2px solid;
	height: 0;
}
.gem-button-separator-type-square .gem-button-separator-button {
	padding: 0 20px;
}
.gem-button-separator-type-soft-double .gem-button-separator-line {
	border-top: 1px solid;
	border-bottom: 1px solid;
	height: 7px;
}
.gem-button-separator-type-square .gem-button-separator-button {
	padding: 0 40px;
}
.gem-button-separator-type-strong-double .gem-button-separator-line {
	border-top: 4px solid;
	border-bottom: 4px solid;
	height: 17px;
}
.gem-button-separator-type-load-more .gem-button-separator-button {
	padding: 0 20px;
}
.gem-button-separator-type-load-more .gem-button-separator-line {
	border-top: 1px solid;
	border-bottom: 1px solid;
	height: 4px;
}

/* Divider */

.clearboth {
	clear: both;
	height: 0;
	font-size: 0;
	line-height: 0;
}
.clear {
	height: 0;
	font-size: 0;
	line-height: 0;
	clear: both;
}
.gem-divider {
	position: relative;
	border-top: 1px solid transparent;
	height: 0;
	font-size: 0;
	line-height: 0;
}
.gem-divider-style-1 {
	height: 1px;
	border: 0 none;
}
.gem-divider-style-2 {
	border-top: 3px solid transparent;
}
.gem-divider-style-3 {
	border-top: 7px solid transparent;
}
.gem-divider-style-4 {
	height: 8px;
	border: 0 none;
}
.gem-divider-style-5 {
	height: 6px;
	border: 0 none;
}
.gem-divider-style-6 {
	border-top: 0;
	height: 10px;
	background: url('https://cms.concrad.com/wp-content/themes/thegem/images/divider-zigzag.png') repeat-x 50% 0;
}
.gem-divider-style-7 {
	border-top: 0;
	height: 8px;
	background: url('https://cms.concrad.com/wp-content/themes/thegem/images/divider-wave.png') repeat-x 50% 0;
}

/* Dropcap */


.gem-dropcap .gem-dropcap-letter {
	float: left;
	position: relative;
	font-size: 127%;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	overflow: hidden;
	margin-right: 21px;
	text-align: center;
	width: 80px;
	height: 80px;
	line-height: 80px;
	margin-top: 7px;

}
.gem-dropcap.gem-dropcap-style-medium .gem-dropcap-letter{
	width: 50px;
	height: 50px;
	line-height: 48px;
}

.gem-dropcap.gem-dropcap-shape-square .gem-dropcap-letter {
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
}

/* Accordion */
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon.vc_tta-controls-icon-chevron::before,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon[data-vc-tta-controls-icon="chevron"]::before {
	-webkit-transform: rotate(-45deg) translate(-25%, -25%);
	-ms-transform: rotate(-45deg) translate(-25%, -25%);
	transform: rotate(-45deg) translate(-25%, -25%);
}
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_active .vc_tta-controls-icon.vc_tta-controls-icon-chevron::before,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_active .vc_tta-controls-icon[data-vc-tta-controls-icon="chevron"]::before {
	-webkit-transform: rotate(45deg) translate(-25%, -25%);
	-ms-transform: rotate(45deg) translate(-25%, -25%);
	transform: rotate(45deg) translate(-25%, -25%);
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-accordion .vc_tta-controls-icon-position-left.vc_tta-panel-title > a,
.wpb-js-composer .vc_tta.vc_general.vc_tta-accordion .vc_tta-controls-icon-position-right.vc_tta-panel-title > a,
.wpb-js-composer .vc_tta.vc_general.vc_tta-accordion [data-vc-tta-controls-icon-position="left"].vc_tta-panel-title > a,
.wpb-js-composer .vc_tta.vc_general.vc_tta-accordion [data-vc-tta-controls-icon-position="right"].vc_tta-panel-title > a {
	padding-left: 50px;
	padding-right: 50px;
}
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_solid .vc_tta-controls-icon-position-left.vc_tta-panel-title > a,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_dashed .vc_tta-controls-icon-position-left.vc_tta-panel-title > a,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_solid [data-vc-tta-controls-icon-position="left"].vc_tta-panel-title > a,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_dashed [data-vc-tta-controls-icon-position="left"].vc_tta-panel-title > a {
	padding-left: 30px;
	padding-right: 0;
}
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_solid .vc_tta-controls-icon-position-right.vc_tta-panel-title > a,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_dashed .vc_tta-controls-icon-position-right.vc_tta-panel-title > a,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_solid [data-vc-tta-controls-icon-position="right"].vc_tta-panel-title > a,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_dashed [data-vc-tta-controls-icon-position="right"].vc_tta-panel-title > a {
	padding-left: 0;
	padding-right: 30px;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-accordion .vc_tta-panel-body {
	padding: 50px;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-accordion.vc_tta-style-simple_solid .vc_tta-panel-body,
.wpb-js-composer .vc_tta.vc_general.vc_tta-accordion.vc_tta-style-simple_dashed .vc_tta-panel-body {
	padding: 30px 0 50px;
}
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_solid .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_dashed .vc_tta-panel .vc_tta-panel-title {
	font-size: 16px;
}
.wpb-js-composer .widget .vc_tta.vc_tta-accordion.vc_tta-style-simple_solid .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .widget .vc_tta.vc_tta-accordion.vc_tta-style-simple_dashed .vc_tta-panel .vc_tta-panel-title {
	font-size: 14px;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-accordion.vc_tta-style-simple_solid .vc_tta-panel + .vc_tta-panel {
	border-top: 1px solid #b6c6c9;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-simple_solid .vc_tta-controls-icon::before,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-simple_solid .vc_tta-controls-icon::after {
	border-color: #b6c6c9;
	color: #b6c6c9;
}
.wpb-js-composer .vc_tta.vc_general.vc_tta-accordion.vc_tta-style-simple_dashed .vc_tta-panel + .vc_tta-panel {
	border-top: 1px dashed #3c3950;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-simple_dashed .vc_tta-controls-icon::before,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-simple_dashed .vc_tta-controls-icon::after {
	border-color: #00bcd4;
	color: #00bcd4;
}
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-simple_dashed .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::before,
.wpb-js-composer .vc_tta.vc_tta-color-thegem.vc_tta-style-simple_dashed .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::after {
	border-color: #3c3950;
	color: #3c3950;
}
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon.vc_tta-controls-icon-solid_squared,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon.vc_tta-controls-icon-solid_rounded,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon.vc_tta-controls-icon-outlined_rounded,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon[data-vc-tta-controls-icon="solid_squared"],
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon[data-vc-tta-controls-icon="solid_rounded"],
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon[data-vc-tta-controls-icon="outlined_rounded"] {
	width: 24px;
	height: 24px;
	line-height: 24px;
	font-size: 24px;
	text-align: center;
	font-style: normal;
}
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-left .vc_tta-controls-icon.vc_tta-controls-icon-solid_rounded,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-left .vc_tta-controls-icon.vc_tta-controls-icon-outlined_rounded,
.wpb-js-composer .vc_tta.vc_tta-accordion [data-vc-tta-controls-icon-position="left"] .vc_tta-controls-icon[data-vc-tta-controls-icon="solid_rounded"],
.wpb-js-composer .vc_tta.vc_tta-accordion [data-vc-tta-controls-icon-position="left"] .vc_tta-controls-icon[data-vc-tta-controls-icon="outlined_rounded"] {
	left: 17px;
}
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-left .vc_tta-controls-icon.vc_tta-controls-icon-solid_squared,
.wpb-js-composer .vc_tta.vc_tta-accordion [data-vc-tta-controls-icon-position="left"] .vc_tta-controls-icon[data-vc-tta-controls-icon="solid_squared"] {
	left: 20px;
}
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-left .vc_tta-controls-icon.vc_tta-controls-icon-chevron,
.wpb-js-composer .vc_tta.vc_tta-accordion [data-vc-tta-controls-icon-position="left"] .vc_tta-controls-icon[data-vc-tta-controls-icon="chevron"] {
	left: 23px;
}
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-right .vc_tta-controls-icon.vc_tta-controls-icon-solid_rounded,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-right .vc_tta-controls-icon.vc_tta-controls-icon-outlined_rounded,
.wpb-js-composer .vc_tta.vc_tta-accordion [data-vc-tta-controls-icon-position="right"] .vc_tta-controls-icon[data-vc-tta-controls-icon="solid_rounded"],
.wpb-js-composer .vc_tta.vc_tta-accordion [data-vc-tta-controls-icon-position="right"] .vc_tta-controls-icon[data-vc-tta-controls-icon="solid_rounded"] {
	right: 13px;
}
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-right .vc_tta-controls-icon.vc_tta-controls-icon-solid_squared,
.wpb-js-composer .vc_tta.vc_tta-accordion [data-vc-tta-controls-icon-position="right"] .vc_tta-controls-icon[data-vc-tta-controls-icon="solid_squared"] {
	right: 20px;
}
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-right .vc_tta-controls-icon.vc_tta-controls-icon-chevron,
.wpb-js-composer .vc_tta.vc_tta-accordion [data-vc-tta-controls-icon-position="right"] .vc_tta-controls-icon[data-vc-tta-controls-icon="chevron"] {
	right: 23px;
}
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon.vc_tta-controls-icon-solid_squared::before,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon.vc_tta-controls-icon-solid_rounded::before,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon.vc_tta-controls-icon-outlined_rounded::before,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon[data-vc-tta-controls-icon="solid_squared"]::before,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon[data-vc-tta-controls-icon="solid_rounded"]::before,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon[data-vc-tta-controls-icon="outlined_rounded"]::before {
	font-family: 'thegem-icons';
	font-weight: normal;
}
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon.vc_tta-controls-icon-solid_squared::before,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon[data-vc-tta-controls-icon="solid_squared"]::before {
	content: '\E62F';
}
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel.vc_active .vc_tta-controls-icon.vc_tta-controls-icon-solid_squared::before,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel.vc_active .vc_tta-controls-icon[data-vc-tta-controls-icon="solid_squared"]::before {
	content: '\E62E';
}
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon.vc_tta-controls-icon-solid_rounded::before,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon[data-vc-tta-controls-icon="solid_rounded"]::before {
	content: '\E60A';
}
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel.vc_active .vc_tta-controls-icon.vc_tta-controls-icon-solid_rounded::before,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel.vc_active .vc_tta-controls-icon[data-vc-tta-controls-icon="solid_rounded"]::before {
	content: '\E609';
}
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon.vc_tta-controls-icon-outlined_rounded::before,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon[data-vc-tta-controls-icon="outlined_rounded"]::before {
	content: '\E62D';
}
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel.vc_active .vc_tta-controls-icon.vc_tta-controls-icon-outlined_rounded::before,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel.vc_active .vc_tta-controls-icon[data-vc-tta-controls-icon="outlined_rounded"]::before {
	content: '\E62C';
}
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_solid .vc_tta-controls-icon-position-left .vc_tta-controls-icon,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_dashed .vc_tta-controls-icon-position-left .vc_tta-controls-icon {
	left: 0;
}
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_solid .vc_tta-controls-icon-position-right .vc_tta-controls-icon,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_dashed .vc_tta-controls-icon-position-right .vc_tta-controls-icon,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_solid [data-vc-tta-controls-icon-position="right"] .vc_tta-controls-icon,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_dashed [data-vc-tta-controls-icon-position="right"] .vc_tta-controls-icon {
	right: 0;
}
/* widget */
.wpb-js-composer .widget .vc_tta.vc_general.vc_tta-accordion .vc_tta-controls-icon-position-left.vc_tta-panel-title > a,
.wpb-js-composer .widget .vc_tta.vc_general.vc_tta-accordion .vc_tta-controls-icon-position-right.vc_tta-panel-title > a {
	padding-left: 35px;
	padding-right: 35px;
}
.wpb-js-composer .widget .vc_tta.vc_tta-accordion.vc_tta-style-simple_solid .vc_tta-controls-icon-position-left.vc_tta-panel-title > a,
.wpb-js-composer .widget .vc_tta.vc_tta-accordion.vc_tta-style-simple_dashed .vc_tta-controls-icon-position-left.vc_tta-panel-title > a {
	padding-left: 30px;
	padding-right: 0;
}
.wpb-js-composer .widget .vc_tta.vc_tta-accordion.vc_tta-style-simple_solid .vc_tta-controls-icon-position-right.vc_tta-panel-title > a,
.wpb-js-composer .widget .vc_tta.vc_tta-accordion.vc_tta-style-simple_dashed .vc_tta-controls-icon-position-right.vc_tta-panel-title > a {
	padding-left: 0;
	padding-right: 30px;
}
.wpb-js-composer .widget .vc_tta.vc_general.vc_tta-accordion .vc_tta-panel-body {
	padding: 35px;
}
.wpb-js-composer .widget .vc_tta.vc_general.vc_tta-accordion.vc_tta-style-simple_solid .vc_tta-panel-body,
.wpb-js-composer .widget .vc_tta.vc_general.vc_tta-accordion.vc_tta-style-simple_dashed .vc_tta-panel-body {
	padding: 20px 0 35px;
}
.wpb-js-composer .widget .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-left .vc_tta-controls-icon.vc_tta-controls-icon-solid_squared,
.wpb-js-composer .widget .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-left .vc_tta-controls-icon.vc_tta-controls-icon-solid_rounded,
.wpb-js-composer .widget .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-left .vc_tta-controls-icon.vc_tta-controls-icon-outlined_rounded {
	left: 7px;
}
.wpb-js-composer .widget .widget .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-right .vc_tta-controls-icon.vc_tta-controls-icon-solid_squared,
.wpb-js-composer .widget .widget .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-right .vc_tta-controls-icon.vc_tta-controls-icon-solid_rounded,
.wpb-js-composer .widget .widget .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-right.vc_tta-controls-icon.vc_tta-controls-icon-outlined_rounded {
	right: 7px;
}
.wpb-js-composer .widget .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-left .vc_tta-controls-icon.vc_tta-controls-icon-chevron {
	left: 15px;
}
.wpb-js-composer .widget .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-left .vc_tta-controls-icon.vc_tta-controls-icon-chevron {
	left: 15px;
}

/* News */
.gem-news {
	margin-bottom: 1.421em;
}
.gem-news .gem-news-item {
	float: left;
	width: 360px;
	padding: 0 15px;
	position: relative;
}
.gem-news-item-left {
	display: table-cell;
	padding-top: 7px;
}
.gem-news-item-right {
	display: table-cell;
	vertical-align: middle;
	padding-top: 4px;
	width: 100%;
	padding-left: 12px;
}
.gem-news-item-image {
	width: 72px;
	height: 72px;
}
.gem-news-item-excerpt {
	margin-top: 2px;
}
.gem-news-item-date {

	margin-top: 3px;
}
.gem-news-zilla-likes {
	position: absolute;
	right: 30px;
	bottom: 0px;
}
.gem-news .post-meta-likes {
	margin-right: 5px;
}
.gem-news_title-excerpt > p {
	max-height: 5em;
	overflow: hidden;
}
.gem-news_title-excerpt {
	max-height: 4.6em;
	overflow: hidden;
}
.gem-news-item-meta {
	position: relative;
}
.gem-news-item-right-conteiner {
	max-height: 3.1em;
	overflow: hidden;
}

/* Clients */
.gem-clients {
	margin-bottom: 1.421em;
}

.gem-clients-type-carousel-grid .gem-clients-slide {
	float: left;
	width: 100%;
}
.disable-grayscale.gem-clients-type-carousel-grid .gem-client-item a  {
	background-color: transparent;
}
.gem-clients-type-carousel-grid .gem-client-item {
	float: left;
	padding: 0 5px 10px 5px;
}
.gem-clients-slide-inner.clearfix {
	margin-left: -5px;
	margin-right: -5px;
}

.gem-clients-type-carousel-grid .gem-client-item a {
	display: block;
	width: 100%;
	text-align: center;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
}
.disable-grayscale.gem-clients-type-carousel-grid .gem-client-item a img {
	opacity: 1;
	-webkit-filter: none;
	-moz-filter: none;
	-ms-filter: none;
	-o-filter: none;
	filter: none;
}
.gem-clients-type-carousel-grid .gem-client-item a img {
	display: inline-block;
	opacity: 0.5;
}
.gem-clients-type-carousel-grid .gem-client-item a:hover img {
	opacity: 1;
}

/* Diagrams */

.diagram-item {
	margin-bottom: 1.421em;
}
.skill-element + .skill-element {
	margin: 8px 0 0 0;
}
.skill-title {
	font-size: 89.5%;
	line-height: 1.421;
	margin: 0 0 5px 0;
}

.skill-amount {
	float: right;
	font-size: 126.3%;
	margin-top: -11px;
}
.skill-line {
	font-size: 0;
	line-height: 1;
	height: 10px;
	margin-right: 55px;
}
.skill-line div {
	display: inline-block;
	font-size: 0;
	line-height: 1;
	height: 10px;
}
.skill-line div.animation {
	-moz-transition: width 1.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	-ms-transition: width 1.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	-o-transition: width 1.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	-webkit-transition: width 1.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: width 1.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.diagram-summary {
	font-size: 89.5%;
	line-height: 1.471;
	margin: 0 0 0.766667em 0;
}
.widget .diagram-summary {
	font-size: 100%;
}
.diagram-summary-text {
	margin: 0 0 0.766667em 0;
}
.diagram-circle {
	text-align: center;
}
.diagram-circle {
	position: relative;
}
.diagram-circle .box-wrapper {
	max-width: 550px;
	position: relative;
	text-align: left;
}
.tab_wrapper .diagram-circle .box-wrapper {
	margin: 0 auto;
}
.diagram-circle .box {
	position: relative;
}
.diagram-circle .skills {
	display: none;
}
.diagram-circle .text {
	font-size: 89.5%;
	line-height: 1.471;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
}
.widget .diagram-circle .text {
	font-size: 100%;
}
.diagram-circle .text div {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
	line-height: 1.2;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
.diagram-circle .text div span {
	display: block;
	text-align: center;
	padding: 0 0 0 5px;
	margin: 0;
}
.widget .diagram-circle .text div,
.widget .diagram-circle .text div span.title {
	font-size: inherit;
}
.diagram-legend {
	display: inline-block;
	position: absolute;
	left: 0;
	top: 50%;
	z-index: 1;
	text-align: left;
}
.diagram-legend .legend-element {
	margin: 0 0 26px 0;
}
.diagram-legend .legend-element .color {
	display: block;
	float: left;
	width: 16px;
	height: 16px;
}
.sidebar .diagram-legend .legend-element .color {
	width: 12px;
	height: 12px;
}
.diagram-legend .legend-element .title {
	display: block;
	line-height: 1;
	margin: 0 0 0 46px;
	margin-left: 30px;
}
.widget .diagram-legend .legend-element .title {
	margin-left: 23px;
	margin-top: -2px;
}

/* Map */
.gem-map-with-text {
	position: relative;
	margin-bottom: 1.421em;
}
.gem-map-with-text.rounded-corners {
	-webkit-mask-image: url('https://cms.concrad.com/wp-content/themes/thegem/css/rounded.svg');
}
.gem-map-with-text-content {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	padding: 20px 15px;
}
.gem-map-with-text-map {
	overflow: hidden;
}
.gem-map-with-text-map .wpb_gmaps_widget {
	margin-top: -46px;
	margin-bottom: 0;
}
@media (max-width: 992px) {
	.gem-map-with-text-content {
		position: relative;
		top: auto;
		left: auto;
		width: auto;
	}
}
.wpb_map_wraper iframe {
	vertical-align: top;
}

/* Counter */
.gem-counter-box {
	margin-bottom: 1.421em;
}
.gem-counter {
	position: relative;
	text-align: center;
}
@media (max-width: 549px) {
	.gem-counter {
		width: 100%;
	}
}
.gem-counter-inner {
	position: relative;
	padding: 40px 30px 30px;
}
.gem-counter .gem-counter-number .gem-counter-odometer,
.gem-counter-suffix {
	display: inline-block;
	vertical-align: middle;
	white-space: nowrap;
}
.gem-counter-icon {
	margin-bottom: 30px;
}
.gem-counter-number {
	margin-bottom: 0;
}
.gem-counter-icon-circle-1 {
	display: inline-block;
	vertical-align: top;
	border-radius: 50%;
	border-width: 4px;
	border-style: solid;
	padding: 9px;
}
.gem-counter-icon-circle-2 {
	width: 146px;
	height: 146px;
	line-height: 146px;
	border-radius: 50%;
	text-align: center;
	border-width: 2px;
	border-style: solid;
}
.gem-counter-style-vertical .gem-counter,
.gem-counter-style-vertical .gem-counter-inner {
	position: relative;
}
.gem-counter-style-vertical .gem-counter {
	margin-top: 50px;
}
.gem-counter-style-vertical .gem-counter-connector {
	position: absolute;
	width: 3px;
	top: -50px;
	left: 50%;
	margin-left: -1px;
}
.gem-counter-style-vertical .gem-counters-list:first-child .gem-counter:first-child {
	margin-top: 0;
}
.gem-counter-style-vertical .gem-counters-list:first-child .gem-counter:first-child .gem-counter-connector {
	display: none;
}
.gem-counter-style-vertical .gem-counter.gem-counter-size-small {
	padding-top: 25px;
}
.gem-counter-style-vertical .gem-counter.gem-counter-size-small .gem-counter-inner {
	padding-top: 60px;
}
.gem-counter-style-vertical .gem-counter.gem-counter-size-small .gem-counter-connector {
	height: 75px;
}
.gem-counter-style-vertical .gem-counter.gem-counter-size-medium {
	padding-top: 40px;
}
.gem-counter-style-vertical .gem-counter.gem-counter-size-medium .gem-counter-inner {
	padding-top: 75px;
}
.gem-counter-style-vertical .gem-counter.gem-counter-size-medium .gem-counter-connector {
	height: 90px;
}
.gem-counter-style-vertical .gem-counter.gem-counter-size-large {
	padding-top: 80px;
}
.gem-counter-style-vertical .gem-counter.gem-counter-size-large .gem-counter-inner {
	padding-top: 115px;
}
.gem-counter-style-vertical .gem-counter.gem-counter-size-large .gem-counter-connector {
	height: 130px;
}
.gem-counter-style-vertical .gem-counter.gem-counter-size-xlarge {
	padding-top: 120px;
}
.gem-counter-style-vertical .gem-counter.gem-counter-size-xlarge .gem-counter-inner {
	padding-top: 170px;
}
.gem-counter-style-vertical .gem-counter.gem-counter-size-xlarge .gem-counter-connector {
	height: 185px;
}
.gem-counter-style-vertical .gem-counter-icon {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
.gem-counter-team .gem-team .inline-column {
	margin-bottom: 0;
}
.gem-counter-team .team-person .team-person-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	text-align: center;
}
.gem-counter-team .team-person .team-person-image img {
	display: inline-block;
}
.gem-counter-team.image-size-small {
	padding-top: 25px;
}
.gem-counter-team.image-size-small .team-person {
	padding-top: 60px;
}
.gem-counter-team.image-size-small .team-person .team-person-image img {
	width: 50px;
	height: 50px;
}
.gem-counter-team.image-size-medium {
	padding-top: 40px;
}
.gem-counter-team.image-size-medium .team-person {
	padding-top: 60px;
}
.gem-counter-team.image-size-medium .team-person .team-person-image img {
	width: 80px;
	height: 80px;
}
.gem-counter-team.image-size-large {
	padding-top: 80px;
}
.gem-counter-team.image-size-large .team-person {
	padding-top: 115px;
}
.gem-counter-team.image-size-large .team-person .team-person-image img {
	width: 160px;
	height: 160px;
}
.gem-counter-team.image-size-xlarge {
	padding-top: 120px;
}
.gem-counter-team.image-size-xlarge .team-person {
	padding-top: 170px;
}
.gem-counter-team.image-size-xlarge .team-person .team-person-image img {
	width: 240px;
	height: 240px;
}
.gem-counter-link {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.gem-counter .gem-icon {
	overflow: visible;
}
.gem-counter .gem-counter-animation {
	position: absolute;
	left: -1px;
	right: -1px;
	top: -1px;
	bottom: -1px;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.gem-icon-shape-circle .gem-counter-animation {
	border-radius: 50%;
}
.gem-counter-effect-background-reverse .gem-counter-animation {
	transform: scale(0);
	-o-transform: scale(0);
	-ms-transform: scale(0);
	-moz-transform: scale(0);
	-webkit-transform: scale(0);
}
.gem-counter-effect-background-reverse.hover .gem-counter-animation {
	transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	-moz-transform: scale(1);
	-webkit-transform: scale(1);
}
.gem-counter-effect-border-reverse .gem-counter-animation {
	transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	-moz-transform: scale(1);
	-webkit-transform: scale(1);
}
.gem-counter-effect-border-reverse.hover .gem-counter-animation {
	transform: scale(0);
	-o-transform: scale(0);
	-ms-transform: scale(0);
	-moz-transform: scale(0);
	-webkit-transform: scale(0);
}
.gem-counter-bottom {
	position: absolute;
	bottom: -10px;
	height: 10px;
	left: 0;
	width: 100%;
}
.gem-counter-bottom-left,
.gem-counter-bottom-right,
.gem-counter-bottom svg {
	position: absolute;
	top: 0;
	height: 100%;
}
.gem-counter-bottom-left {
	left: 0;
	right: 50%;
	margin-right: 10px;
}
.gem-counter-bottom-right {
	right: 0;
	left: 50%;
	margin-left: 10px;
}
.gem-counter-bottom svg {
	left: 50%;
	margin-left: -10px;
}
.gem-counter .gem-counter-inner,
.gem-counter .gem-icon,
.gem-counter .gem-icon-inner,
.gem-counter .gem-icon-half-1,
.gem-counter .gem-icon-half-2,
.gem-counter .gem-counter-number,
.gem-counter .gem-counter-text,
.gem-counter-icon-circle-1,
.gem-counter-icon-circle-2,
.gem-counter-bottom-left,
.gem-counter-bottom-right,
.gem-counter-bottom svg {
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}

/* Separator text */
.vc_separator .vc_sep_text {
	line-height: 1em;
	display: table-cell;
	white-space: pre;
}
.vc_separator.vc_separator_align_center .vc_sep_text {
	padding: 0 0.8em;
}
.vc_separator.vc_separator_align_left .vc_sep_text {
	padding: 0 0.8em 0 0;
}
.vc_separator.vc_separator_align_right .vc_sep_text {
	padding: 0 0 0 0.8em;
	margin: 0 !important;
}
.vc_separator.vc_sep_double .vc_sep_line {
	height: 4px !important;
}
@media (max-width: 768px) {
	.vc_separator .vc_sep_text {
		white-space: normal;
		text-align: center;
	}
}
.vc_separator.vc_sep_color_blue .vc_sep_text {
	color: #5472d2;
}
.vc_separator.vc_sep_color_turquoise .vc_sep_text {
	color: #00c1cf;
}
.vc_separator.vc_sep_color_pink .vc_sep_text {
	color: #fe6c61;
}
.vc_separator.vc_sep_color_violet .vc_sep_text {
	color: #8d6dc4;
}
.vc_separator.vc_sep_color_peacoc .vc_sep_text {
	color: #4cadc9;
}
.vc_separator.vc_sep_color_chino .vc_sep_text {
	color: #cec2ab;
}
.vc_separator.vc_sep_color_mulled_wine .vc_sep_text {
	color: #50485b;
}
.vc_separator.vc_sep_color_vista_blue .vc_sep_text {
	color: #75d69c;
}
.vc_separator.vc_sep_color_black .vc_sep_text {
	color: #2a2a2a;
}
.vc_separator.vc_sep_color_grey .vc_sep_text {
	color: #ebebeb;
}
.vc_separator.vc_sep_color_orange .vc_sep_text {
	color: #f7be68;
}
.vc_separator.vc_sep_color_sky .vc_sep_text {
	color: #5aa1e3;
}
.vc_separator.vc_sep_color_green .vc_sep_text {
	color: #6dab3c;
}
.vc_separator.vc_sep_color_juicy_pink .vc_sep_text {
	color: #f4524d;
}
.vc_separator.vc_sep_color_sandy_brown .vc_sep_text {
	color: #f79468;
}
.vc_separator.vc_sep_color_purple .vc_sep_text {
	color: #b97ebb;
}
.vc_separator.vc_sep_color_white .vc_sep_text {
	color: #ffffff;
}
.vc_separator.vc_sep_color_grace .vc_sep_text {
	color: #aed13b;
}

/* COMMENTS */
.comment {
	position: relative;
}
.comment .comment {
	margin-left: 45px;
}
.comment .comment:before {
	content: '';
	position: absolute;
	top: 0;
	height: 100%;
	border-left: 1px solid #dfe5e8;
	left: -45px;
}
.comment-inner {
	position: relative;
	margin-bottom: 40px;
}
.comment .comment >.comment-inner:before {
	content: '';
	position: absolute;
	top: 50%;
	border-top: 1px solid #dfe5e8;
	left: -45px;
	width: 45px;
}
.comments-title,
.comment-reply-title {
	text-transform: uppercase;
	margin-top: 0;
}
.comments-title:before {
	content: '\E63D';
	font-family: 'thegem-icons';
	font-weight: normal;
	font-size: 48px;
	margin-right: 30px;
	vertical-align: top;
	color: #d8e5e7;
}
.comment-reply-title:before {
content: '\E63E';
	font-family: 'thegem-icons';
	font-weight: normal;
	font-size: 48px;
	margin-right: 25px;
	vertical-align: top;
	color: #ff758f;
}
.comments-area {
	margin-top: 100px;
}
.comment-list {
	margin-bottom: 110px;
}
.comment-author a {
	color: inherit;
}
.comment-author .avatar {
	float: left;
	width: 40px;
	height: 40px;
	margin-right: 20px;
	margin-top: 15px;
	margin-bottom: 15px;
}
.comment.depth-1 > .comment-inner .comment-author .avatar {
	width: 70px;
	height: 70px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	margin-top: 0;
	margin-bottom: 0;
}
.comment-author .fn {
	margin: 10px 0 0;
}
.comment-meta {
	font-size: 89.5%;
	line-height: 1.421;
	display: inline-block;
}
.comment-text,
.comment-awaiting-moderation {
	padding-top: 20px;
}
.comment-inner {
	padding: 33px 40px 45px;
}
.comment-header {
	display: table;
	width: 100%;
}
.comment-header .comment-author,
.comment-header .reply {
	display: table-cell;
}
.comment-header .reply {
	text-align: right;
}
.comment-header .reply .gem-button {
	margin-right: 0;
}
.comment-form-fields {
	margin-bottom: 42px;
}
.comment-form label {
	display: block;
	margin-bottom: 15px;
}
.comment-form .comment-form-fields input[type="text"],
.comment-form .comment-form-fields input[type="email"] {
	width: 100%;
	line-height: 48px;
	height: 50px;
	padding: 0 50px 0 20px;
}
.comment-form .comment-author-input,
.comment-form .comment-email-input,
.comment-form .comment-url-input {
	position: relative;
}
.comment-form .comment-author-input:after,
.comment-form .comment-email-input:after,
.comment-form .comment-url-input:after {
	font-family: 'thegem-icons';
	font-weight: normal;
	position: absolute;
	top: 0;
	right: 21px;
	font-size: 24px;
	width: 50px;
	height: 100%;
	line-height: 50px;
	text-align: center;
}
.comment-form .comment-author-input:after {
	content: '\E63B';
}
.comment-form .comment-email-input:after {
	content: '\E643';
}
.comment-form .comment-url-input:after {
	content: '\E623';
}
.comment-form #comment {
	width: 100%;
	padding: 15px 20px;
}

.comment-form .comment-form-cookies-consent {
	display: flex;
	margin-top: 36px;
	align-items: flex-start;
}

.comment-form .comment-form-cookies-consent label {
	margin: 0;
	line-height: 26px;
}

.comment-form .comment-form-cookies-consent .checkbox-sign {
	flex: 0 0 auto;
	margin-right: 10px;
	width: 26px;
	height: 26px;
	border: 1px solid #dfe5e8;
}

.comment-form .comment-form-cookies-consent .checkbox-sign.checked:before {
	font-size: 21px;
	margin-left: -10px;
}

/* BLOG POST */


.blog-post-image img {
	display: inline-block;
}

/* RELATED POSTS */
.post-related-posts h2 {
	padding-right: 100px;
}
.post-related-posts h2:before {
	content: '\E63C';
	font-family: 'thegem-icons';
	font-weight: normal;
	font-size: 48px;
	margin-right: 25px;
	vertical-align: top;
	color: #72cfce;
}

/* NIVO SLIDER */

.gem-nivoslider .nivo-directionNav a {
	top: 0;
}
.gem-mini-pagination.nivo-controlNav {
	bottom: 0;
	position: absolute;
	width: 100%;
	z-index: 10;
}
.gem-nivoslider .nivo-caption {
	padding: 0;
	overflow: visible;
	opacity: 1;
	position: relative;
	width: 1212px;
	margin: 0 auto;
}
.gem-nivoslider .caption-left,
.gem-nivoslider .caption-right {
	position: absolute;
	bottom: 50px;
	max-width: 70%;
}
.gem-nivoslider .caption-left {
	left: 20px;
}
.gem-nivoslider .caption-right {
	right: 20px;
}
@media only screen and (max-width:1439px) {
	.gem-nivoslider .nivo-caption {
		width: auto;
	}
	.gem-nivoslider .caption-left {
		left: 155px;
	}
	.gem-nivoslider .caption-right {
		right: 155px;
	}
}
@media only screen and (max-width:799px) {
	.gem-nivoslider .caption-left {
		left: 80px;
	}
	.gem-nivoslider .caption-right {
		right: 80px;
	}
	.gem-nivoslider .nivo-directionNav {
		display: none;
	}
	.gem-nivoslider .gem-mini-pagination.nivo-controlNav {
		display: none;
	}
}
.gem-nivoslider .caption-left .gem-nivoslider-title,
.gem-nivoslider .caption-left .gem-nivoslider-description {
	float: left;
	text-align: left;
}
.gem-nivoslider .caption-right .gem-nivoslider-title,
.gem-nivoslider .caption-right .gem-nivoslider-description {
	float: right;
	text-align: right;
}
.gem-nivoslider .nivo-caption .gem-nivoslider-title {
	background-color: rgba(56, 65, 84, 0.8);
	color: #ffffff;
	padding: 10px 15px;
}
.gem-nivoslider .nivo-caption .gem-nivoslider-description {
	background-color: rgba(0, 0, 0, 0.7);
	color: #ffffff;
	padding: 0 10px;
}

/* SCROLL TOP BUTTON */

.scroll-top-button {
	position: fixed;
	right: 40px;
	bottom: 35px;
	z-index: 1000;
	opacity: 0;
	visibility: hidden;
	line-height: 0;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
}
.scroll-top-button.visible {
	opacity: 0.5;
	visibility: visible;
}
.scroll-top-button:before {
	font-family: 'thegem-icons';
	content: '\E633';
	font-size: 24px;
	font-weight: normal;
	display: inline-block;
	vertical-align: top;
	width: 50px;
	height: 50px;
	text-align: center;
	line-height: 50px;
	background-color: #06060f;
	color: #99a9b5;
}
@media (max-width: 768px) {
	.scroll-top-button {
		right: 0;
	}
}

#page .wpb_start_animation.wpb_left-to-right,
#page .wpb_start_animation.wpb_right-to-left,
#page .wpb_start_animation.wpb_top-to-bottom,
#page .wpb_start_animation.wpb_bottom-to-top,
#page .wpb_start_animation.wpb_appear {
	-webkit-animation-duration: 1.4s;
	-moz-animation-duration: 1.4s;
	-o-animation-duration: 1.4s;
	animation-duration: 1.4s;
}

.wpb_text_column.wpb_animate_when_almost_visible.wpb_fade {
	opacity: 1;
}

body .wpb_text_column.wpb_animate_when_almost_visible.wpb_fade {
	opacity: 0;
}



body .lazy-loading .wpb_text_column.wpb_animate_when_almost_visible.wpb_fade {
	opacity: 1;
}

/* WPML */

#primary-menu.no-responsive > li > ul.submenu-languages {
	list-style: none;
	padding: 0;
	width: auto;
}
#primary-navigation.no-responsive .menu-item-language a {
	text-align: center;
}
.menu-item.menu-item-language img.iclflag {
	position: relative;
	margin-right: 0;
	width: 19px !important;
	height: 13px !important;
}

/* MENU ICONS */
#primary-menu.no-responsive > li.menu-item-language > a {
	padding-right: 8px !important;
}
#primary-menu.no-responsive > li.menu-item-language > a:before {
	display: none;
}
#primary-menu.no-responsive > li.menu-item-search > a {
	padding-left: 10px !important;
	padding-right: 3px !important;
}
#primary-menu.no-responsive > li.menu-item-cart > a {
	padding-left: 7px !important;
}
#page.vertical-header #primary-menu.no-responsive > li.menu-item-cart > a {
	padding-left: 0px !important;
}
.header-style-3 #primary-menu.no-responsive > li.menu-item-language > a,
.header-style-4 #primary-menu.no-responsive > li.menu-item-language > a {
	padding-right: 9px !important;
}
.header-style-3 #primary-menu.no-responsive > li.menu-item-search > a,
.header-style-4 #primary-menu.no-responsive > li.menu-item-search > a {
	padding-left: 7px !important;
	padding-right: 3px !important;
}
.header-style-3 #primary-menu.no-responsive > li.menu-item-cart > a,
.header-style-4 #primary-menu.no-responsive > li.menu-item-cart > a,
.header-style-3 .hamburger-minicart > a,
.header-style-4 .hamburger-minicart > a {
	padding-left: 2px !important;
}
.header-layout-perspective .hamburger-minicart > a,
.header-style-3.header-layout-overlay .hamburger-minicart > a,
.header-style-4.header-layout-overlay .hamburger-minicart > a {
	padding: 0 !important;
}


/* STICKY */

.gem-sticky-block > * {
	left: auto;
	right: auto;
}

.gem-sticky-block .sticky-fixed {
	position: fixed;
}

/* WP REQUIRED */

.wp-caption {
	margin-bottom: 1.6em;
	max-width: 100%;
}
.wp-caption-text {
	font-size: 89.5%;
	padding: 0.5em 0;
}
.sticky {}
.gallery-caption {
	font-size: 89.5%;
	padding: 0.5em 0;
}
.bypostauthor {}
.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
}
.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
}
.aligncenter {
	clear: both;
	display: block;
	margin-right: auto;
	margin-left: auto;
}
blockquote.alignleft,
.wp-caption.alignleft,
img.alignleft {
	margin: 0 1.6em 1.6em 0;
}
blockquote.alignright,
.wp-caption.alignright,
img.alignright {
	margin: 0 0 1.6em 1.6em;
}
blockquote.aligncenter,
.wp-caption.aligncenter,
img.aligncenter {
	clear: both;
	margin-top: 0;
	margin-bottom: 1.6em;
}
.wp-caption.alignleft,
.wp-caption.alignright,
.wp-caption.aligncenter {
	margin-bottom: 1.2em;
}

body .blog {
	margin-bottom: 20px;
	padding-bottom: 30px;
}

*:not(.gem-table):not(.cart_totals) > table:not(.shop_table):not(.group_table):not(.variations) td,
*:not(.gem-table):not(.cart_totals) > table:not(.shop_table):not(.group_table):not(.variations) th {
	padding: 5px 10px;
}

.page-links a {
	display: inline-block;
	position: relative;
}
.page-links span:not(.page-links-title) {
	display: inline-block;
	width: 1.421em;
	text-align: center;
}

/* WP Galleries */

.gallery {
	margin-bottom: 1.6em;
}
.gallery .gallery-item {
	display: inline-block;
	padding: 1.79104477%;
	text-align: center;
	vertical-align: top;
	width: 100%;
}
.gallery-columns-2 .gallery-item {
	max-width: 50%;
}
.gallery-columns-3 .gallery-item {
	max-width: 33.33%;
}
.gallery-columns-4 .gallery-item {
	max-width: 25%;
}
.gallery-columns-5 .gallery-item {
	max-width: 20%;
}
.gallery-columns-6 .gallery-item {
	max-width: 16.66%;
}
.gallery-columns-7 .gallery-item {
	max-width: 14.28%;
}
.gallery-columns-8 .gallery-item {
	max-width: 12.5%;
}
.gallery-columns-9 .gallery-item {
	max-width: 11.11%;
}
.gallery-icon img {
	margin: 0 auto;
}
.gallery-caption {
	display: block;
	padding: 0.5em 0;
}
.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
	display: none;
}

.gem-slideshow > .rs-p-wp-fix + * {
	height: 400px;
}

/* SEARCH */

.block-content .search-form-block .searchform #s {
	width: 100%;
}
.gem-search-form {
	margin: 50px 0;
}
.gem-search-form form {
	position: relative;
}
.gem-search-form .search-field {
	width: 100%;
	height: 50px;
	line-height: 50px;
	padding: 0 50px 0 25px;
	border-radius: 25px;
	border: 0 none;
	box-sizing: border-box;
	outline: 0 none;
}
.gem-search-form .search-submit {
	position: absolute;
	top: 0;
	height: 50px;
	line-height: 50px;
	width: 50px;
	text-align: center;
	right: 0;
	font-family: 'thegem-icons';
	font-weight: normal;
	font-size: 24px;
	background: transparent;
	border: 0 none;
}
.gem-search-form .search-submit:after {
	content: '\E612';

}

/* VIDEO BACKGROUND */

.gem-video-background {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.gem-video-background ~ * {
	position: relative;
	z-index: 1;
}
.gem-video-background-inner,
.gem-video-background-overlay {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.gem-video-background-inner iframe,
.gem-video-background-inner video {
	width: 100%;
	height: 100%;
}

/* LIKES */

.zilla-likes {
	margin-bottom: 0 !important;
	color: inherit;
	display: inline-block;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.zilla-likes:hover {
	color: #3c3950;
}
.zilla-likes:before {
	content: '\E641';
	display: inline-block;
	font-family: "thegem-icons";
	font-size: 16px;
	font-weight: normal;
	line-height: inherit;
	margin-right: 6px;
	vertical-align: top;
}
.zilla-likes .zilla-likes-count {
	display: inline-block;
	vertical-align: top;
}

/* POST-FORMAT */
#fancybox-video {
	width: 80%;
	height: 80%;
	overflow: hidden;
}
#fancybox-video .mejs-container,
#fancybox-video .mejs-container video {
	width: 100% !important;
	height: 100% !important;
}
.video-block {
	position: relative;
	background-color: #e3ebe8;
}
.video-block > iframe,
.video-block > video,
.video-block > .preloader,
.video-block > .mejs-video {
	position: absolute;
	width: 100% !important;
	height: 100% !important;
	left: 0;
	top: 0;
}
.fancybox-inner .video-block {
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.fancybox-inner .video-block .mejs-video {
	width: 100% !important;
	height: 100% !important;
}
.audio-block audio {
	width: 100%;
}
.blog-style-timeline article.format-quote .post-featured-content {
	margin-bottom: 15px;
}
.post-featured-content blockquote {
	background: #212331;
	color: #ffffff;
	text-align: left;
	position: relative;
	padding: 40px 60px 35px;
}
.blog-style-4x .post-featured-content blockquote,
.blog-style-justified-4x .post-featured-content blockquote {
	padding-left: 30px;
	padding-right: 30px;
}
.post-featured-content blockquote:after {
	display: none;
}
.post-featured-content blockquote .quote-author {
	float: left;
	color: #ffffff;
	font-size: 13px;
}
.post-featured-content blockquote .quote-link {
	float: right;
	color: #ffffff;
}
.post-featured-content blockquote .quote-link a{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}
.post-featured-content blockquote .quote-link:after {
	font-family: 'thegem-icons';
	font-weight: normal;
	content: '\E632';
	font-size: 40px;
}

.fullwidth-block.fullwidth-block-parallax-fixed .fullwidth-block-background {
	background-attachment: fixed;
}

.fullwidth-block.fullwidth-block-parallax-horizontal .fullwidth-block-background {
	background-position: center center;
}

.fullwidth-block .fullwidth-block-background,
.page-title-parallax-background-wrap .page-title-parallax-background {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.fullwidth-block .fullwidth-block-parallax-overlay {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2;
}

.fullwidth-block .fullwidth-block-inner {
	position: relative;
	z-index: 3;
}

.fullwidth-block.fullwidth-block-parallax-vertical,
.page-title-parallax-background-wrap {
	position: relative;
	overflow: hidden;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.fullwidth-block.fullwidth-block-parallax-vertical .fullwidth-block-background,
.page-title-parallax-background-wrap .page-title-parallax-background {
	background-repeat: no-repeat;
	background-size: cover;
	will-change: transform;
}

.page-title-parallax-background-wrap .page-title-title,
.page-title-parallax-background-wrap .container  {
	position: relative;
	z-index: 10;
}

.portfolio .item-animations-not-inited,
.gallery-set .item-animations-not-inited,
.blog:not(.home) .item-animations-not-inited {
	visibility: hidden !important;
	/*opacity: 0 !important;

	-webkit-transition: none !important;
	-moz-transition: none !important;
	-o-transition: none !important;
	transition: none !important;

	transform: none !important;*/
}

.item-animation-flip .item-animations-inited {
	-webkit-transform-origin: 0% 0%;
	-moz-transform-origin: 0% 0%;
	transform-origin: 0% 0%;

	-webkit-transform-style: preserve-3d;
}

.item-animation-flip .portfolio-set,
.item-animation-flip .gallery-set,
.blog.item-animation-flip,
.item-animation-scale .portfolio-set,
.item-animation-scale .gallery-set,
.blog.item-animation-scale,
.item-animation-fall-perspective .portfolio-set,
.item-animation-fall-perspective .gallery-set,
.blog.item-animation-fall-perspective {
	webkit-perspective: 1300px;
	-moz-perspective: 1300px;
	perspective: 1300px;
}

/* HALF WIDTH MAP */

@media (min-width: 1212px) {
	body:not(.compose-mode) #page .half-width-map {
		position: absolute;
		top: 0;
		height: 100%;
		left: 50%;
		right: 0;
		width: auto;
	}
	body:not(.compose-mode) #page .half-width-map *:not(.map-locker):not(.gem-map-with-text-content) {
		height: 100%;
	}
	body:not(.compose-mode) #page .half-width-map .vc_column-inner {
		padding: 0;
	}
	body:not(.compose-mode) #page .half-width-map .gem-map-with-text-map .wpb_gmaps_widget {
		margin-top: 0;
		top: -46px;
		position: absolute;
		height: auto;
		bottom: 0;
		width: 100%;
	}
	body:not(.compose-mode) #page .half-width-map .wpb_gmaps_widget.vc_map_responsive .wpb_map_wraper {
		padding: 0;
	}
	body:not(.compose-mode) #page .half-width-map iframe + .map-locker {
		position: absolute;
		right: 0;
		bottom: 15px;
	}
}

/* CUSTOM SHOP MAILCHIMP FORM */
.mailchimp-form-1 .yikes-easy-mc-email,
.mailchimp-form-3 .yikes-easy-mc-email{
	background: #fff !important;
	padding-left: 20px !important;
	border: 1px solid #dfe5e8 !important;
}
.mailchimp-form-1 .yikes-easy-mc-submit-button,
.mailchimp-form-3 .yikes-easy-mc-submit-button{
	border: none;
	color: #fff;
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
}
.mailchimp-form-1 .yikes-easy-mc-submit-button{
	background: #9ccc65;
}
.mailchimp-form-3 .yikes-easy-mc-submit-button{
	background: #2c2e3d;
}

/* PAGE SCROLLER */
.scroller-block {
	height: 100vh;
}

.page-scroller #main {
	-o-transition: 1s ease;
	-webkit-transition: 1s ease;
	transition: 1s ease;
}
.page-scroller #main.page-scroller-no-animate {
	-o-transition: 0s ease;
	-webkit-transition: 0s ease;
	transition: 0s ease;
}

.page-scroller .scroller-block {
	height: 100vh;
	margin-top: 0;
	margin-bottom: 0;
	overflow: hidden;
}
.page-scroller-nav-pane {
	position: fixed;
	right: 50px;
	top: 50%;
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 10;
}
.page-scroller-nav-item {
	display: block;
	height: 10px;
	width: 10px;
	border: 2px solid #ffffff;
	border-radius: 50%;
	margin: 10px 0;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.page-scroller-nav-item.active {
	background-color: #ffffff;
}

@media (min-width: 768px) {
	body.page-scroller:not(.compose-mode),
	body.page-scroller:not(.compose-mode) #page {
		height: 100vh;
		position: relative;
	}
	body.page-scroller:not(.page-scroller-mobile) #page #site-header.fixed.shrink {
		background-color: transparent;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}

@media (max-width: 767px) {
	body.page-scroller.page-scroller-mobile:not(.compose-mode),
	body.page-scroller.page-scroller-mobile:not(.compose-mode) #page {
		height: 100vh;
		position: relative;
	}
	body.page-scroller:not(.page-scroller-mobile) {
		overflow: visible !important;
	}
	.page-scroller:not(.page-scroller-mobile) #main {
		-webkit-transform: none !important;
		transform: none !important;
		-o-transition: 0s ease;
		-webkit-transition: 0s ease;
		transition: 0s ease;
	}
	.page-scroller:not(.page-scroller-mobile) .scroller-block {
		height: auto;
	}
	.page-scroller:not(.page-scroller-mobile) .page-scroller-nav-pane {
		display: none;
	}
	.page-scroller-nav-pane {
		right: 15px;
	}
}

/* SEPARATOR WITH TEXT RESPONSIVE */
@media (max-width: 500px){

	.vc_sep_holder {
		display: none;
		text-align: left;
	}
	body .vc_separator.vc_separator_align_center h4 {
		padding: 0;
	}
}
.vc_separator.vc_separator_align_center > * {
	padding: 0 .7em;
}
/* SEPARATOR WITH TEXT RESPONSIVE */


/* NEWSLETTER FORM */
.yikes-easy-mc-form .yikes-easy-mc-submit-button.gem-button {
	padding: 0 10px;
	margin-left: 0;
	margin-right: 0;
}
form.yikes-easy-mc-form input[type="email"],
form.yikes-easy-mc-form input[type="number"],
form.yikes-easy-mc-form input[type="text"],
form.yikes-easy-mc-form input[type="url"],
form.yikes-easy-mc-form select,
form.yikes-easy-mc-form textarea {
	border-width: 1px;
	padding: 0 10px;
	height: 40px;
}

form.yikes-easy-mc-form input[type="email"]:focus,
form.yikes-easy-mc-form input[type="number"]:focus,
form.yikes-easy-mc-form input[type="text"]:focus,
form.yikes-easy-mc-form input[type="url"]:focus,
form.yikes-easy-mc-form select:focus,
form.yikes-easy-mc-form textarea:focus {
	outline: 0 none;
}

.yikes-easy-mc-form .yikes-easy-mc-form input[type="email"],
.yikes-easy-mc-form .yikes-easy-mc-form input[type="number"],
.yikes-easy-mc-form .yikes-easy-mc-form input[type="text"],
.yikes-easy-mc-form .yikes-easy-mc-form input[type="url"],
.yikes-easy-mc-form .yikes-easy-mc-form select {
	border-width: 1px;
}

form.mc4wp-form input[type='submit'] {
	position: relative;
	display: inline-block;
	text-align: center;
	text-transform: uppercase;
	white-space: nowrap;
	border: 0 none;
	vertical-align: middle;
	height: 40px;
	line-height: 40px;
	padding: 0 17px;
	font-size: 14px;
	margin: 0;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	border-radius: 3px;
}

form.mc4wp-form input[type="text"],
form.mc4wp-form input[type="email"],
form.mc4wp-form input[type="tel"],
form.mc4wp-form input[type="number"],
form.mc4wp-form input[type="url"],
form.mc4wp-form input[type="date"],
form.mc4wp-form textarea {
	line-height: inherit;
}
form.mc4wp-form input[type="text"],
form.mc4wp-form input[type="email"],
form.mc4wp-form input[type="tel"],
form.mc4wp-form input[type="number"],
form.mc4wp-form input[type="url"],
form.mc4wp-form input[type="date"] {
	height: 40px;
}
#mc4wp_email {
	height: 41px;
}

.agency-sparta-style.yikes-easy-mc-form label.label-inline{ width: 69%; padding-right: 5%; }
.agency-sparta-style.yikes-easy-mc-form label.label-inline > input{ background: #f0f3f2; height: 45px; border-radius: 3px; border: none; padding-left: 20px; }
.agency-sparta-style.yikes-easy-mc-form label.label-inline > input:focus{ outline: none !important; }
.yikes-mailchimp-container .agency-sparta-style .submit-button-inline-label{ width: 17%; min-width: 235px; }
.yikes-mailchimp-container .agency-sparta-style .submit-button-inline-label > span { display: none; }
.yikes-mailchimp-container .agency-sparta-style .submit-button-inline-label .yikes-easy-mc-submit-button{ background: #212331; color: #fff; height: 45px; border: none; border-radius: 3px; font-family: 'Montserrat';  font-weight: 700;  text-transform: uppercase; padding-left: 50px; position: relative; }
.yikes-mailchimp-container .agency-sparta-style .yikes-easy-mc-submit-button:before{ content: "\E626"; font-family: "thegem-icons"; font-size: 24px; font-weight: normal;  color: #fff; position: absolute; left: 50%; margin-left: -69px; text-align: center; }
@media(max-width: 1200px){
	body .agency-sparta-style.yikes-easy-mc-form label.label-inline{ width: 100%; padding-right: 0; }
	body .yikes-mailchimp-container .agency-sparta-style .submit-button-inline-label{ width: 100%; }
}

.splash-shop-3-style.yikes-easy-mc-form label.label-inline{ width: 350px; padding-right: 20px;}
.splash-shop-3-style.yikes-easy-mc-form label.label-inline > input{ background: #f0f3f2; height: 45px; border: none; padding-left: 20px; }
.yikes-mailchimp-container .splash-shop-3-style.yikes-easy-mc-form label.label-inline > input:focus{ outline: none !important; }
.yikes-mailchimp-container .splash-shop-3-style .submit-button-inline-label{width: 155px; min-width: 155px; height: 45px; }
.yikes-mailchimp-container .splash-shop-3-style .submit-button-inline-label > span { display: none; }
.yikes-mailchimp-container .splash-shop-3-style .submit-button-inline-label .yikes-easy-mc-submit-button{ background: #d7b398; color: #fff; border: none; font-family: 'Montserrat';  font-weight: 700;  text-transform: uppercase; padding-right: 30px; padding-left: 30px; position: relative; border-radius: 0; height: 45px;}
.yikes-mailchimp-container .splash-shop-3-style button { transition: all 0.3s; -webkit-transition: all 0.3s;}
.yikes-mailchimp-container .splash-shop-3-style button:hover { background: #212121 !important; color: #fff !important; transition: all 0.3s; -webkit-transition: all 0.3s;}
@media(max-width: 1200px){
	body .splash-shop-3-style.yikes-easy-mc-form label.label-inline{ width: 100%; padding-right: 0; }
	body .yikes-mailchimp-container .splash-shop-3-style .submit-button-inline-label{ width: 100%; }
}

.splash-shop-4-style.yikes-easy-mc-form label.label-inline{ width: 350px; padding-right: 20px;}
.splash-shop-4-style.yikes-easy-mc-form label.label-inline > input{ color: #fff; background: transparent; height: 45px; border: none; padding-left: 0; border-bottom: 1px solid #575c6c;}
.yikes-mailchimp-container .splash-shop-4-style.yikes-easy-mc-form label.label-inline > input:focus{ outline: none !important; }
.yikes-mailchimp-container .splash-shop-4-style .submit-button-inline-label{width: 155px; min-width: 155px; height: 45px; }
.yikes-mailchimp-container .splash-shop-4-style .submit-button-inline-label > span { display: none; }
.yikes-mailchimp-container .splash-shop-4-style .submit-button-inline-label .yikes-easy-mc-submit-button{ background: #f44236; color: #fff; border: none; font-family: 'Montserrat';  font-weight: 700;  text-transform: uppercase; padding-right: 30px; padding-left: 30px; position: relative; border-radius: 0; height: 45px; }
.yikes-mailchimp-container .splash-shop-4-style button { transition: all 0.3s; -webkit-transition: all 0.3s;}
.yikes-mailchimp-container .splash-shop-4-style button:hover { background: #212121; color: #fff; transition: all 0.3s; -webkit-transition: all 0.3s;}
@media(max-width: 1200px){
	body .splash-shop-4-style.yikes-easy-mc-form label.label-inline{ width: 100%; padding-right: 0; }
	body .yikes-mailchimp-container .splash-shop-4-style .submit-button-inline-label{ width: 100%; }
}

.wpcf7 .contact-form-style-5 .wpcf7-form-control-wrap{
	width: 100%;
}
.wpcf7 .contact-form-style-5 input,
.wpcf7 .contact-form-style-5 textarea{
	width: 100%;
}

.yikes-easy-mc-form.app-sparta-style {
	position: relative;
}
.yikes-easy-mc-form.app-sparta-style.yikes-easy-mc-form   .yikes-easy-mc-submit-button {
	position: absolute;
	top: 11px;
	width: 164px;
	right: 11px;
	margin-top: 2px;
	padding: 13px;
	background-color: #008aff;
	border: medium none;
	border-radius: 10px;
	font-family: Montserrat;
	font-size: 16px;
	font-weight: 700;
	line-height: 26px;
	color: #ffffff;
	text-transform: uppercase;
}
.yikes-easy-mc-form.app-sparta-style.yikes-easy-mc-form .yikes-easy-mc-submit-button:hover {
	background-color: #00BCD4;
}
.yikes-easy-mc-form.app-sparta-style.yikes-easy-mc-form  .yikes-easy-mc-email {
	background: transparent;
	border: 1px solid #4f4f5b;
	border-radius: 12px;
	overflow: hidden;
	padding: 25px;
	margin-bottom: 0;
	color: #fff;
}

.scrolldown {
	position: absolute;
	left: 50%;
	margin-left: -2vw;
	z-index:9;
	bottom: 2vh;
	background-color: rgba(22,195,241,0.8);
	padding: 20px 30px !important;
	border-radius: 50%;
	text-align: center;
}

.scrolldown img{
	-webkit-animation: scrollmove 1s infinite ease;
	-moz-animation: scrollmove 1s infinite ease;
	animation: scrollmove 1s infinite ease;
	width: 1.5vw;
	margin-top: 7px !important;
}
.scrolldown .mouse-2 {
	-webkit-animation: scrollmove 1s infinite ease;
	-moz-animation: scrollmove 1s infinite ease;
	animation: scrollmove 1s infinite ease;
	width: 1.5vw;
	background-image: url("https://cms.concrad.com/wp-content/themes/thegem/images/mouse_icon.png");
	display: block;
	font-size: 0;
	height: 43px;
	background-repeat: no-repeat;
}


@-moz-keyframes scrollmove {
	0% {
		-moz-transform: translateY(0px);
		-webkit-transform: translateY(0px);
		-o-transform: translateY(0px);
		-ms-transform: translateY(0px);
		transform: translateY(0px);
	}
	50% {
		-moz-transform: translateY(-10px);
		-webkit-transform: translateY(-10px);
		-o-transform: translateY(-10px);
		-ms-transform: translateY(-10px);
		transform: translateY(-10px);
	}
	100% {
		-moz-transform: translateY(0px);
		-webkit-transform: translateY(0px);
		-o-transform: translateY(0px);
		-ms-transform: translateY(0px);
		transform: translateY(0px);
	}
}
@-webkit-keyframes scrollmove {
	0% {
		-moz-transform: translateY(0px);
		-webkit-transform: translateY(0px);
		-o-transform: translateY(0px);
		-ms-transform: translateY(0px);
		transform: translateY(0px);
	}
	50% {
		-moz-transform: translateY(-10px);
		-webkit-transform: translateY(-10px);
		-o-transform: translateY(-10px);
		-ms-transform: translateY(-10px);
		transform: translateY(-10px);
	}
	100% {
		-moz-transform: translateY(0px);
		-webkit-transform: translateY(0px);
		-o-transform: translateY(0px);
		-ms-transform: translateY(0px);
		transform: translateY(0px);
	}
}
@keyframes scrollmove {
	0% {
		-moz-transform: translateY(0px);
		-webkit-transform: translateY(0px);
		-o-transform: translateY(0px);
		-ms-transform: translateY(0px);
		transform: translateY(0px);
	}
	50% {
		-moz-transform: translateY(-10px);
		-webkit-transform: translateY(-10px);
		-o-transform: translateY(-10px);
		-ms-transform: translateY(-10px);
		transform: translateY(-10px);
	}
	100% {
		-moz-transform: translateY(0px);
		-webkit-transform: translateY(0px);
		-o-transform: translateY(0px);
		-ms-transform: translateY(0px);
		transform: translateY(0px);
	}
}


body div.wpcf7-response-output {
	margin: 2em 0 1em;

}

.thegem__footer-widget-class__thegem {
	width: 100%;
	padding: 0 21px;
}
@media (min-width: 768px) {
	.thegem__footer-widget-class__thegem {
		width: 50%;
	}
}
@media (min-width: 982px) {
	.thegem__footer-widget-class__thegem {
		width: 25%;
	}
}

img[class*='wp-image-'] {
	max-width: 100%;
	height: auto;
}


/* SEACRH FORM NOTHING FOUND */

body.search .page-title-block {
	padding-top: 45px;
	padding-bottom: 20px;
}
body.search .page-title-block:before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 3px;
	background-color: #4dd0e1;
}
body.search .page-title-block .page-title-icon {
	margin-top: -10px;
}
body.search .page-title-block .page-title-title {
	padding-bottom: 60px;
}
body.search .page-title-block .page-title-icon + .page-title-title {
	padding-bottom: 0;
}
body.search .page-title-block .highlight {
	color: #e7ff89;
}

.page-content.content-none .searchform > table td {
	border: 0 none !important;
	padding: 0 !important;
}
.page-content.content-none .searchform > table td:first-child {
	width: 99%;
}
.page-content.content-none .searchform input {
	width: 100%;
	height: 50px;
	line-height: 48px;
	border-radius: 3px;
}

.page-content.content-none .searchform .searchform-submit .gem-button {
	margin-left: 30px;
	margin-right: 0;
}

.parallax-footer-inited {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: -1;
}
#page.layout-boxed .parallax-footer-inited {
	width: 1260px;
	max-width: 100%;
	left: auto;
}
/*@media (max-width: 768px) {
	body #site-header-wrapper.sticky-header-on-mobile {
		width: 100%;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 1000;
	}
}*/

.disable-hover {
	pointer-events: none !important;
}

.diagram-line-timer-element {
	font-size: 0;
	line-height: 1;
	height: 0;
	-webkit-opacity: 0;
	-moz-opacity: 0;
	filter: alpha(opacity=0);
	opacity: 0;
	-moz-transition: opacity 0.15s;
	-ms-transition: opacity 0.15s;
	-o-transition: opacity 0.15s;
	-webkit-transition: opacity 0.15s;
	transition: opacity 0.15s;
}

.diagram-line-timer-element.start-timer {
	-webkit-opacity: 1;
	-moz-opacity: 1;
	filter: alpha(opacity=100);
	opacity: 1;
}


/* Slide horizontal mobile menu */
.mobile-menu-layout-slide-horizontal #primary-navigation.responsive .mobile-menu-slide-wrapper,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive .mobile-menu-slide-wrapper {
	position: fixed;
	overflow-y: auto;
	padding-top: 75px;
	z-index: 1003;
	-webkit-overflow-scrolling: touch;
	opacity: 0;
}
.mobile-menu-layout-slide-horizontal #primary-navigation.responsive .mobile-menu-slide-wrapper #primary-menu,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive .mobile-menu-slide-wrapper #primary-menu {
	padding-bottom: 30px;
}

.mobile-menu-slide-wrapper .mobile-menu-slide-close {
	display: none;
}

#primary-navigation.responsive .mobile-menu-slide-wrapper .mobile-menu-slide-close {
	display: block;
	border: none;
	width: 36px;
	height: 30px;
	text-indent: 100%;
	font-size: 0;
	line-height: 0;
	overflow: hidden;
	position: absolute;
	right: 19px;
	top: 20px;
	cursor: pointer;
	outline: none;
	background: transparent;
	padding: 0;
}

#primary-navigation.responsive .mobile-menu-slide-wrapper .mobile-menu-slide-close:before,
#primary-navigation.responsive .mobile-menu-slide-wrapper .mobile-menu-slide-close:after {
	display: block;
	content: '';
	position: absolute;
	height: 4px;
	width: 100%;
	left: 0;
}

#primary-navigation.responsive .mobile-menu-slide-wrapper .mobile-menu-slide-close:before {
	transform: rotate(45deg);
	top: 13px;
}

#primary-navigation.responsive .mobile-menu-slide-wrapper .mobile-menu-slide-close:after {
	top: 13px;
	transform: rotate(-45deg);
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive .mobile-menu-slide-wrapper.left {
	left: 0;
	top: 0;
	width: 350px;
	height: 100vh;
	max-width: 100vw;
	-o-transform: translateX(-100%);
	-ms-transform: translateX(-100%);
	-moz-transform: translateX(-100%);
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
}

.mobile-menu-layout-slide-vertical #primary-navigation.responsive .mobile-menu-slide-wrapper.top {
	left: 0;
	right: 0;
	top: -100vh;
	height: 100vh;
}

body.admin-bar .mobile-menu-layout-slide-horizontal #primary-navigation.responsive .mobile-menu-slide-wrapper.left,
body.admin-bar .mobile-menu-layout-slide-vertical #primary-navigation.responsive .mobile-menu-slide-wrapper.top.opened {
	top: 46px;
	height: calc(100vh - 46px);
}

body.admin-bar .fixed.mobile-menu-layout-slide-horizontal #primary-navigation.responsive .mobile-menu-slide-wrapper.left,
body.admin-bar .fixed.mobile-menu-layout-slide-vertical #primary-navigation.responsive .mobile-menu-slide-wrapper.top.opened {
	top: 0;
	height: 100vh;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive .mobile-menu-slide-wrapper.left.animation {
	-webkit-transition: transform 0.2s ease-out;
	-moz-transition: transform 0.2s ease-out;
	-o-transition: transform 0.2s ease-out;
	transition: transform 0.2s ease-out;
	opacity: 1;
}

.mobile-menu-layout-slide-vertical #primary-navigation.responsive .mobile-menu-slide-wrapper.top.animation {
	-webkit-transition: top 0.2s ease-out;
	-moz-transition: top 0.2s ease-out;
	-o-transition: top 0.2s ease-out;
	transition: top 0.2s ease-out;
	opacity: 1;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive .mobile-menu-slide-wrapper.left.opened {
	-o-transform: translateX(0);
	-ms-transform: translateX(0);
	-moz-transform: translateX(0);
	-webkit-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
}

.mobile-menu-layout-slide-vertical #primary-navigation.responsive .mobile-menu-slide-wrapper.top.opened {
	top: 0;
	opacity: 1;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li a,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li a {
	display: block;
	padding: 13px 60px 15px 30px;
	position: relative;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li {
	position: relative;
	width: auto !important;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li > ul,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li > ul {
	display: none;
	padding: 0 !important;
	background-image: none !important
}

.mobile-menu-layout-slide-horizontal .primary-navigation.responsive li.menu-item-has-children > .menu-item-parent-toggle,
.mobile-menu-layout-slide-vertical .primary-navigation.responsive li.menu-item-has-children > .menu-item-parent-toggle {
	display: block;
	position: absolute;
	right: 15px;
	top: 0;
	width: 40px;
	height: 49px;
	cursor: pointer;
}

.mobile-menu-layout-slide-horizontal .primary-navigation.responsive li.menu-item-has-children > .menu-item-parent-toggle:after,
.mobile-menu-layout-slide-vertical .primary-navigation.responsive li.menu-item-has-children > .menu-item-parent-toggle:after {
	display: block;
	text-align: center;
	font-weight: normal;
	font-family: 'thegem-icons';
	content: '\E634';
	font-size: 24px;
	line-height: 49px;
}

.mobile-menu-layout-slide-horizontal .primary-navigation.responsive li.menu-item-has-children.opened > .menu-item-parent-toggle:after,
.mobile-menu-layout-slide-vertical .primary-navigation.responsive li.menu-item-has-children.opened > .menu-item-parent-toggle:after {
	content: '\E635';
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu > li > ul > li > a,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu > li > ul > li > a {
	padding-left: 50px;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu > li > ul > li > ul > li > a,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu > li > ul > li > ul > li > a {
	padding-left: 70px;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu > li > ul > li > ul > li > ul > li > a,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu > li > ul > li > ul > li > ul > li > a {
	padding-left: 90px;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li {
	border-top: 1px solid transparent;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu > li:first-child,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu > li:first-child {
	border: 0;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li.menu-item-widgets,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li.menu-item-widgets {
	display: none;
	border: 0;
	position: static;
	height: 0;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li.menu-item-widgets.mobile-only,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li.menu-item-widgets.mobile-only {
	display: inline-block;
	height: auto;
}

body .mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li.menu-item-cart ~ li.menu-item-widgets.mobile-only,
body:not(.mobile-cart-position-top) .mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li.menu-item-cart ~ li.menu-item-widgets.mobile-only,
body .mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li.menu-item-language ~ li.menu-item-widgets.mobile-only,
body:not(.mobile-cart-position-top) .mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li.menu-item-language ~ li.menu-item-widgets.mobile-only {
	float: right;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li.menu-item-widgets .menu-item-socials,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li.menu-item-widgets .menu-item-socials {
	padding: 16px 30px 15px;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li.menu-item-widgets .menu-item-socials .socials,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li.menu-item-widgets .menu-item-socials .socials {
	margin: 0 -5px;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li.menu-item-widgets .menu-item-socials a,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li.menu-item-widgets .menu-item-socials a {
	display: inline-block;
	padding: 0;
	vertical-align: bottom;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li.menu-item-widgets .menu-item-socials a i,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li.menu-item-widgets .menu-item-socials a i {
	vertical-align: middle;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li.menu-item-search .minisearch,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li.menu-item-search .minisearch {
	position: absolute;
	top: 0;
	left: 0;
	right: 55px;
	background: transparent;
	width: auto;
	padding-top: 15px;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li.menu-item-search > a,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li.menu-item-search > a {
	display: none;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li.menu-item-search .minisearch .sf-submit-icon:before,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li.menu-item-search .minisearch .sf-submit-icon:before {
	color: #99a9b5;
}
.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li.menu-item-search .minisearch input[type="text"]::placeholder,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li.menu-item-search .minisearch input[type="text"]::placeholder {
	color: #99a9b5;
}
.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li.menu-item-search .minisearch input[type="text"]::-webkit-input-placeholder,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li.menu-item-search .minisearch input[type="text"]::-webkit-input-placeholder {
	color: #99a9b5;
}

body .mobile-menu-layout-slide-horizontal:before {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000000;
	z-index: 10;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s ease-out 0s, visibility 0s linear 0.3s;
}
body .mobile-menu-layout-slide-horizontal.menu-slide-opened:before {
	opacity: 0.7;
	visibility: visible;
	transition: opacity 0.3s ease-in 0s, visibility 0s linear 0s;
}

.header-layout-overlay #primary-navigation #primary-menu.no-responsive > li.menu-item-language,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-language,
.header-layout-overlay #primary-navigation #primary-menu.no-responsive > li.menu-item-cart,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-cart,
.header-layout-overlay #primary-navigation #primary-menu.no-responsive > li.menu-item-search,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search {
	margin-top: 40px;
}
.header-layout-overlay #primary-navigation #primary-menu.no-responsive > li.menu-item-language,
.header-layout-overlay #primary-navigation #primary-menu.no-responsive > li.menu-item-cart,
.header-layout-overlay #primary-navigation #primary-menu.no-responsive > li.menu-item-search {
	width: 70px;
}
.header-layout-overlay #primary-navigation #primary-menu.no-responsive > li.menu-item-language > a,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-language > a {
	padding: 0 !important;
}

.header-layout-overlay #primary-navigation #primary-menu.no-responsive > li.menu-item-cart > a:before,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-cart > a:before,
.header-layout-overlay #primary-navigation #primary-menu.no-responsive > li.menu-item-search > a:before,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > a:before {
	font-size: 40px;
}

.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > a,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > a {
	display: block;
}

.header-layout-overlay #primary-navigation #primary-menu.no-responsive > li.menu-item-search > a:before,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > a:before {
	font-size: 40px;
}

.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch {
	position: absolute;
	opacity: 0;
	visibility: hidden;
	top: 50%;
	padding: 40px;
	background: transparent;
	transform: translateY(-50%);
	border: 0 none;
	transition: opacity 0.3s ease-out, visibility 0s linear 0.3s;
}
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch {
	width: 40%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}
.header-layout-overlay #primary-menu.no-responsive > li,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.3s ease-in, visibility 0s linear 0s;
}
.header-layout-overlay #primary-menu.no-responsive.overlay-search-form-show > li,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu.overlay-search-form-show > li {
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.3s ease-out, visibility 0s linear 0.3s;
}
.header-layout-overlay #primary-menu.no-responsive.overlay-search-form-show > li ul,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu.overlay-search-form-show > li ul,
.header-layout-overlay #primary-menu.no-responsive.overlay-search-form-show > li li,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu.overlay-search-form-show > li li {
	visibility: hidden;
	transition: visibility 0s linear 0s;
}

.header-layout-overlay #primary-menu.no-responsive.overlay-search-form-show > li.menu-item-search,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu.overlay-search-form-show > li.menu-item-search {
	visibility: visible;
	opacity: 1;
}
.header-layout-overlay #primary-navigation #primary-menu.no-responsive > li.menu-item-search > a,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > a {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.3s ease-in, visibility 0s linear 0s, color 0.3s ease;
}
.header-layout-overlay #primary-navigation #primary-menu.no-responsive.overlay-search-form-show > li.menu-item-search > a,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu.overlay-search-form-show > li.menu-item-search > a {
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.3s ease-out, visibility 0s linear 0.3s;
}
/*.header-layout-overlay #primary-menu.no-responsive.overlay-search-form-show > li.menu-item-search > .minisearch,*/
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu.overlay-search-form-show > li.menu-item-search > .minisearch {
	opacity: 1;
	visibility: visible;
	transition: opacity 0.3s ease-in, visibility 0s linear 0s;
}
/*.header-layout-overlay #primary-menu.no-responsive.overlay-search-form-show > li.menu-item-search > .minisearch {
	width: 40%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	transition: all 0s;
}*/
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch input[type="text"],
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch input[type="text"] {
	height: 50px;
	border: 3px solid transparent;
	line-height: 44px;
	background: transparent;
	border-radius: 25px;
	text-transform: uppercase;
	padding-left: 25px;
}

.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch .sf-submit-icon,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch .sf-submit-icon,
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch .sf-submit,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch .sf-submit {
	right: 46px;
	top: 43px;
	height: 44px;
}
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch .sf-submit-icon:before,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch .sf-submit-icon:before {
	font-size: 40px;
}


/* animate minisearch */
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch {
	transition: opacity .2s .7s, visibility .7s, bottom .5s .3s;
	top: auto;
	bottom: -20px;
	opacity: 0;
	visibility: hidden;
}

.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch > form {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch > form .sf-input {
	width: 50px;
	padding: 0 23px;
	transition: width .5s, font-size .3s, padding .3s;
}

.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch > form .sf-submit-icon,
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch > form .sf-submit {
	top: 4px;
	right: 6px;
}

.header-layout-overlay #primary-menu.no-responsive.overlay-search-form-show.animated-minisearch > li.menu-item-search > .minisearch {
	visibility: visible;
	opacity: 1;
	bottom: 200px;
	transition: opacity .5s, visibility .5s, bottom .3s .2s;
}

.header-layout-overlay #primary-menu.no-responsive.overlay-search-form-show.animated-minisearch > li.menu-item-search > .minisearch > form .sf-input {
	padding: 0 25px;
   	width: 400px;
   	transition: width .5s .5s, padding .3s .5s;
}


body .fancybox-caption-wrap {
	padding: 0;
	background: none;
}

body .fancybox-caption-wrap .fancybox-caption {
	border: 0;
}

.fancybox-slide.thegem-fancybox-video .fancybox-content {
	max-width: 80%;
	max-height: 80%;
	margin: 0;
}

.gem-instagram-gallery .overlay .links .caption {
	word-break: break-all;
}

.gem-instagram-gallery.portfolio.hover-default .portfolio-item .image .overlay .links a.icon {
	margin: 0 10px;
}

.vc_column-inner.sticky > .wpb_wrapper{
	position: fixed;
}


/* Give */
body:not(.vc_editor) .give-form-hidden {
	display: none;
}

body .give-modal .mfp-content {
	background: #edeef0;
	max-width: 763px;
	border-radius: 6px;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
}

.give-modal [id*=give-form] fieldset {
	max-width: 390px;
	margin: 0 auto;
}

.give-modal form.give-form .form-row {
	float: none;
	width: 100%;
}

.give-modal form.give-form .form-row .give-label {
	display: none;
}

.give-modal form[id*=give-form] .form-row input[type="text"],
.give-modal form[id*=give-form] .form-row input[type="email"] {
	height: 50px;
	background: #ffffff;
	border: 0;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}

.page-additional-menu .page-additional-menu-close {
	position: fixed;
	right: 37px;
	top: 37px;
	z-index: 999999;
	opacity: 0;
	transition: opacity 0.5s;
	visibility: hidden;
}

body.admin-bar .page-additional-menu .page-additional-menu-close {
	top: 67px;
}

.page-additional-menu .primary-navigation.overlay-active .page-additional-menu-close {
	visibility: visible;
	opacity: 1;
}

.page-additional-menu .primary-navigation.overlay-active.close .page-additional-menu-close {
	opacity: 0;
}

@media (max-width: 768px) {
	.page-additional-menu .page-additional-menu-close {
		right: 37px;
		top: 17px;
	}

	body.admin-bar .page-additional-menu .page-additional-menu-close {
		top: 67px;
	}
}

.portfolio .caption {
	display: block;
}

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
}

@media (max-width: 768px) {
	body #site-header .logo-position-menu_center:not(.header-layout-fullwidth_hamburger):not(.header-layout-overlay) > .site-title {
		display: table-cell;
	}
	.site-title .site-logo {
		max-width: 60vw;
	}
}
.gem-icon .gem-icon-style-gradient .back-angle {
	width: 100%;
	text-align: center;
}
.gem-map-with-text{
	margin-bottom: 0;
}

/* GUTENBERG */
.wp-block-cover-image.alignleft {
	margin-right: 1.5em;
}
.wp-block-cover-image.alignright {
	margin-left: 1.5em;
}
.wp-block-image figure {
	margin-top: 1em;
	margin-bottom: 1em;
}
.wp-block-columns {
	margin: 0 -21px;
}
.wp-block-column {
	padding: 0 21px;
}
.wp-block-audio audio {
	width: 100%;
}
blockquote.wp-block-quote {
	padding: 50px 20px 50px 50px;
}
blockquote.wp-block-quote cite {
	font: inherit;
	font-size: 0.8em;
}
blockquote.wp-block-quote.is-large,
blockquote.wp-block-quote.is-style-large {
	padding: 50px 20px 50px 50px;
	margin: 20px 0;
	text-transform: uppercase;
}
blockquote.wp-block-quote.is-large cite,
blockquote.wp-block-quote.is-style-large cite,
blockquote.wp-block-quote.is-large p,
blockquote.wp-block-quote.is-style-large p,
.wp-block-pullquote blockquote cite,
.wp-block-pullquote blockquote p,
.wp-block-pullquote.alignleft blockquote cite,
.wp-block-pullquote.alignleft blockquote p,
.wp-block-pullquote.alignright blockquote cite,
.wp-block-pullquote.alignright blockquote p {
	font: inherit;
}
blockquote.wp-block-quote.is-large cite,
blockquote.wp-block-quote.is-style-large cite,
.wp-block-pullquote blockquote cite,
.wp-block-pullquote.alignleft blockquote cite,
.wp-block-pullquote.alignright blockquote cite {
	font-size: 0.85em;
}
figure.wp-block-pullquote {
	border: 1px solid #c9d2d9;
	padding: 0;
	overflow: hidden;
}
.wp-block-pullquote blockquote {
	background: transparent;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 45px;
	padding-bottom: 50px;
}
figure.wp-block-pullquote blockquote {
	font-size: 24px;
	line-height: 37px;
	font-weight: 100;
}
.wp-block-pullquote blockquote:after {
	color: #e7ebee;
}
.wp-block-pullquote.alignleft blockquote:after,
.wp-block-pullquote.alignright blockquote:after {
	right: 20px;
}
body .wp-block-button .wp-block-button__link {
	position: relative;
	display: inline-block;
	text-align: center;
	text-transform: uppercase;
	white-space: nowrap;
	border: 0 none;
	vertical-align: middle;
	height: 40px;
	line-height: 40px;
	padding: 0 17px;
	font-size: 14px;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	border-radius: 3px;
}
body .vc-hoverbox-wrapper .vc-hoverbox {
	cursor:pointer;
}
body  .vc-hoverbox-block, .vc-hoverbox-block-inner * {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
#lazy-loading-point {
	font-size: 0;
	line-height: 1;
	height: 0;
	overflow: hidden;
}
.quickfinder-item-box {
	position: relative;
	padding: 50px;
}
.quickfinder-item.col-md-2 .quickfinder-item-box,
.quickfinder-item.col-md-3 .quickfinder-item-box {
	padding-left: 25px;
	padding-right: 25px;
}

/* CUSTOM TITLE */
.page-title-block.custom-page-title,
body.search .page-title-block.custom-page-title {
	padding: 0;
	text-align: left;
}

.custom-title-background.fullwidth-block-parallax-vertical .fullwidth-block-background {
	background-attachment: scroll;
}

.custom-title-background.fullwidth-block-parallax-fixed .fullwidth-block-background {
	background-attachment: fixed;
}

.custom-title-background.fullwidth-block-parallax-horizontal .fullwidth-block-background {
	background-position: center center;
}

.custom-title-background .fullwidth-block-background {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}
.custom-title-background .gem-video-background {
	z-index: 2;
}

.custom-title-background .custom-title-background-overlay {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2;
}

.custom-title-background .fullwidth-block-inner {
	position: relative;
	z-index: 3;
}

.custom-title-icon.custom-title-icon-alignment-center {
	text-align: center;
}
.custom-title-icon.custom-title-icon-alignment-left {
	float: left;
}
.custom-title-icon.custom-title-icon-alignment-right {
	float: right;
}
.page-title-block.custom-page-title .breadcrumbs-container {
	z-index: 3;
}
.page-title-block.custom-page-title .page-title-alignment-center {
	text-align: center;
}




body.thegem-effects-disabled #page .lazy-loading .wpb_text_column.wpb_animate_when_almost_visible.wpb_fade {
	opacity: 1;
}

body.thegem-effects-disabled #page .quickfinder-item-image .quickfinder-item-image-content.lazy-loading-item {
	transform: scale(1);
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
}
body.thegem-effects-disabled #page .lazy-loading,
body.thegem-effects-disabled #page .lazy-loading:not(.lazy-loading-not-hide) {
	visibility: visible;
}
body.thegem-effects-disabled #page .wpb_text_column.wpb_animate_when_almost_visible.wpb_fade {
	opacity: 1;
}

body.thegem-effects-disabled #page .wpb_start_animation.wpb_left-to-right,
body.thegem-effects-disabled #page .wpb_start_animation.wpb_right-to-left,
body.thegem-effects-disabled #page .wpb_start_animation.wpb_top-to-bottom,
body.thegem-effects-disabled #page .wpb_start_animation.wpb_bottom-to-top,
body.thegem-effects-disabled #page .wpb_start_animation.wpb_appear {
	-webkit-animation: none;
	-moz-animation: none;
	-o-animation: none;
	animation: none;
}

body.thegem-effects-disabled #page .wpb_animate_when_almost_visible.wpb_left-to-right,
body.thegem-effects-disabled #page .wpb_animate_when_almost_visible.wpb_right-to-left,
body.thegem-effects-disabled #page .wpb_animate_when_almost_visible.wpb_top-to-bottom,
body.thegem-effects-disabled #page .wpb_animate_when_almost_visible.wpb_bottom-to-top,
body.thegem-effects-disabled #page .wpb_animate_when_almost_visible.wpb_appear {
	opacity: 1;
}
